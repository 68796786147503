import React,  { FunctionComponent } from 'react';
import {
    Drawer,
    Grid,
    // InputAdornment, TextField
    Typography,
  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { EE_DASHBOARD } from 'utils/Constant';
import ScreenLoader from 'components/ScreenLoader';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { getAllListData } from 'services/CommonServices';
import moment from 'moment';

const EmployeeDetailPopOut: React.FC<{drawer:any, 
                                      userDetail: any, 
                                      details: any, 
                                      viewMoreDetails: any,
                                      moreDetails:any,
                                      loader1:boolean,
                                      setLoader1: any,
                                      setDrawer: any,
                                      setDetails: any,
                                      setMoreDetails: any}> = ({drawer, 
                userDetail, 
                details, 
                viewMoreDetails, 
                moreDetails, 
                loader1, 
                setLoader1, 
                setDrawer, 
                setDetails, 
                setMoreDetails}) =>
{
    const handleToggle = async (toogleBoolean: boolean) => {
        setDrawer(toogleBoolean);
        if (toogleBoolean) {
          const url = `${EE_DASHBOARD.ViewMore}/${userDetail.ClientId}/${userDetail.Id}/${viewMoreDetails.employeeId}`;
          setLoader1(true);
          await getAllListData(url)
            .then((res) => {
              setDetails(res?.data);
            })
            .finally(() => {
              setLoader1(false);
            });
          const UrlM = `${EE_DASHBOARD.ViewMoreMagic}/${userDetail.ClientId}/${userDetail.Id}/${viewMoreDetails.employeeId}`;
          await getAllListData(UrlM).then((res) => {
            setMoreDetails(res?.data);
          });
        }
      };

      const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
        const { x, y, payload } = props;
        return (
          <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor='end' fill='#979598' fontSize={12}>
              {moment(payload.value).format('MMM')}
            </text>
          </g>
        );
      };

      const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
          return (
            <Typography component={'div'} className='custom-tooltip'>
              <Typography component={'p'}>{`${moment(payload[0].payload.yearMonth).format(
                'MMMM YYYY',
              )}`}</Typography>
              <Typography component={'p'} className='view-green'>
                Team: {payload[0].payload?.teamScore}
              </Typography>
              {/* <Typography component={'p'} className='view-dep'>
                Department: {payload[0].payload?.departmentScore}
              </Typography> */}
              <Typography component={'p'} className='view-org'>
                Organization: {payload[0].payload?.organizationScore}
              </Typography>
              <Typography component={'p'} className='view-you'>
                Employee: {payload[0].payload?.individualScore}
              </Typography>
            </Typography>
          );
        }
        return null;
      };

      const HRmonthLegend = () => {
        return (
          <Typography component={'div'} className='d-flex-ja'>
            <Typography component={'div'} className='d-flex-a hr-text mr-10'>
              <Typography className='s-dot' sx={{ backgroundColor: '#C3D34F' }} />
              Team
            </Typography>
            {/* <Typography component={'div'} className='d-flex-a hr-text mr-10'>
              <Typography className='s-dot' sx={{ backgroundColor: '#3441D2' }} />
              Department
            </Typography> */}
            <Typography component={'div'} className='d-flex-a hr-text mr-10'>
              <Typography className='s-dot' sx={{ backgroundColor: '#C60C68' }} />
              Organization
            </Typography>
            <Typography component={'div'} className='d-flex-a hr-text'>
              <Typography className='s-dot' sx={{ backgroundColor: '#0DA728' }} />
              Employee
            </Typography>
          </Typography>
        );
      };

    return(
      <>
        <Drawer
            className='employee-drawer'
            anchor='right'
            open={drawer}
          >
            <Typography id="employeeDetailCloseBtn" className='drawer-close d-flex-ja' onClick={() => handleToggle(false)}>
              <CloseIcon />
            </Typography>
            {loader1 ? (
              <Typography
                component={'div'}
                textAlign={'center'}
                sx={{
                  paddingTop: '100px',
                }}>
                <ScreenLoader loading={loader1} />
              </Typography>
            ) : (
              <>
                {!details?.length ? (
                  <>
                    <Typography component={'div'} className='top-pad-box'>
                      <Grid container>
                        <Grid item md={9} sm={12} xs={12}>
                          <Typography component={'div'} className='d-flex-a'>
                            {/* {viewMoreDetails?.level !== 1 && ( */}
                            <Typography component={'div'}>
                              {viewMoreDetails?.image ? (
                                <img
                                  className='default-pic d-flex-ja'
                                  src={viewMoreDetails?.image}
                                />
                              ) : (
                                <Typography component={'div'} className='default-pic d-flex-ja'>
                                  {`${ details?.employeeInformations  &&
                                        details?.employeeInformations?.[0]?.name
                                      ?.split(' ')[0]
                                      ?.split('')[0]}${details?.employeeInformations?.[0]?.name
                                      ?.split(' ')[1]
                                      ?.split('')[0]}`}
                                </Typography>
                              )}
                            </Typography>
                            {/* )} */}
                            <Typography component={'div'} className='flex-1'>
                              {/* {viewMoreDetails?.level !== 1 ? ( */}
                              <Typography className='emp-name'>
                                { details?.employeeInformations &&
                                        details?.employeeInformations?.[0]?.name ?
                                        details?.employeeInformations?.[0]?.name : ''}
                              </Typography>
                              {/* // ) : ( */}
                              {/* <Typography className='emp-name'>Department</Typography> */}
                              {/* // )} */}
                              <Typography className='emp-dep'>
                                {details?.department ? details?.department : '-'}
                              </Typography>
                              {/* {viewMoreDetails?.level !== 1 && ( */}
                              <Typography className='emp-dep'>
                                Email:{' '}
                                <span>
                                  { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.email
                                    ? details?.employeeInformations?.[0]?.email
                                    : '-'}
                                </span>
                              </Typography>
                              {/* )} */}
                            </Typography>
                          </Typography>
                        </Grid>
                        {}
                      </Grid>
                    </Typography>
                    <Typography component={'div'} className='warn-pad-box'>
                      {}
                      {/* {viewMoreDetails?.level !== 1 && ( */}
                      <Grid container className='mt-14 pb-24 border-bottom'>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>Job Title</Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.jobTitle
                              ? details?.employeeInformations?.[0]?.jobTitle
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>Hire Date</Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.hireDate
                              ? moment(details?.employeeInformations?.[0]?.hireDate).format(
                                  'MM/DD/YYYY',
                                )
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>Salary</Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.salary
                              ? `$${details?.employeeInformations?.[0]?.salary.toLocaleString()}`
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>
                            Probability of Leaving in a year
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations[0]?.propabilityOfLeavingInAYear
                              ? details?.employeeInformations[0]?.propabilityOfLeavingInAYear
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>Department</Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.department
                              ? details?.employeeInformations?.[0]?.department
                              : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* // )} */}
                      <Typography className='emp-line' sx={{ paddingTop: '5px' }}>
                        Engagement Score
                      </Typography>
                      {}
                      <LineChart
                        className='linechart-emp'
                        width={500}
                        height={300}
                        data={details.satisfactionScores}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='yearMonth' tick={<CustomizedAxisTick />} />
                        <YAxis domain={[0,6]} />
                        <Tooltip content={CustomTooltip} />
                        <Legend verticalAlign='bottom' align='center' content={HRmonthLegend} />
                        <Line
                          type='monotone'
                          dataKey='teamScore'
                          stroke='#c3d34f'
                          strokeWidth='3'
                        />
                        {}
                        <Line
                          type='monotone'
                          dataKey='organizationScore'
                          stroke='#C60C68'
                          strokeWidth='3'
                        />
                        <Line
                          type='monotone'
                          dataKey='individualScore'
                          stroke='#0DA728'
                          strokeWidth='3'
                        />
                      </LineChart>
                      <Grid
                        container
                        className='mt-30'
                        sx={{ borderTop: '1px solid #e8e8e8', paddingTop: '24px' }}>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'div'} className='no-box d-flex-j flex-column'>
                            <Typography
                              className='total-title'
                              sx={{ letterSpacing: '-1px !important' }}>
                              Engagement Score
                            </Typography>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography className='total-num'>
                                {details?.averageSatisfactionScore
                                  ? details?.averageSatisfactionScore
                                  : 0}
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12} paddingLeft={2}>
                          <Typography component={'div'} className='no-box d-flex-j flex-column'>
                            <Typography className='total-title'>Comp Ratio</Typography>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography className='total-num'>
                                {moreDetails?.avgCompaRatio}%
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'div'} className='no-box d-flex-j flex-column'>
                            <Typography className='total-title'>Performance</Typography>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography className='total-num'>
                                {moreDetails?.avgPerformance}%
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'div'} className='no-box d-flex-j flex-column'>
                            <Typography className='total-title'>Tenure</Typography>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography className='total-num'>
                                {moreDetails?.averageTenure} years
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  </>
                ) : (
                  <Typography
                    component={'div'}
                    sx={{ height: '550px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                )}
              </>
            )}
          </Drawer>
          </>
    );
}

export default EmployeeDetailPopOut;