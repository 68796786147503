export const fileApiUrl =
  process.env.REACT_APP_FILE_URL || `${window.location.origin}/api/aws-files/`;

export const alertOptions: any = {
  autoClose: 3000,
  closeOnClick: true,
  draggable: true,
  hideProgressBar: false,
  pauseOnHover: true,
  position: 'top-right',
  progress: undefined,
};

import { useLayoutEffect, useState } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    height,
    width,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const drawSignatureInfo = {
  height: '75px',
  width: '350px',
};

export const mimeTypes = `.rtf, application/rtf, application/pdf, image/*, text/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow,
   application/vnd.openxmlformats-officedocument.presentationml.presentation`;

export const roundOfData = (num: any) => {
  return typeof num === 'number' ? Number(num.toFixed(2)) : '';
};

export const roundOfDataNum = (num: any) => {
  if (num % 1 !== 0) {
    return typeof num === 'number'
      ? new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(Number(num?.toFixed(2)))
      : 0;
  } else {
    return typeof num === 'number'
      ? `${new Intl.NumberFormat('en-US').format(Number(num?.toFixed(2)))}.00`
      : 0;
  }
  // return typeof num === 'number' ? new Intl.NumberFormat('en-US').format(Number((num).toFixed(0))) : '-';
};

export const EyeToolTipText = 'Encompass team to provide content';
export const EyeToolTipTextOverAll = {
  dashboard: {
    employeeEngagement:
      'Current organizational chart highlighting Needs Attention and Good Standing employees.',
    turnover:
      'Involuntary and Voluntary turnover counts month over month compared to the previous year.',
    openRequisitions: 'Your current open requisitions listing the top 10 departments.',
  },
  organizationalOverview: {
    currentEmployeePopulationTenure:
      'Tenure of your current employee population broken down into blocks.',
    averageHeadcountvsTurnoverRatio:
      'Rolling 13 month headcount averaging first of the month and last day of the month compared overlapped with your turnover ratio. Turnover ratio is defined as terms / average headcount for the month.',
    turnoverTerminatonReasons:
      'Year to Date termination reasons broken down by Voluntary and Involuntary term reasons.',
    hiresTermsbyMonth: 'Rolling 13 month breakdown of your hires and terms.',
    needsAttentionByClass: 'Displays the number of employees that need attention sorted from highest to lowest by ',
    onTheMoveByClass: 'Displays the number of employees that are on the move sorted from highest to lowest by ',
    inGoodStandingByClass: 'Displays the number of employees that are in good standing sorted from highest to lowest by ',
    surveyTopResponsesByClass: 'The top number of survey responses grouped by ',
    surveyBottomResponsesByClass: 'The lowest number of survey responses grouped by ',
    engagementSummary: 'Displays employee movement within the organization',
    engagementSummaryByMonths: 'Shows historical optimization summaries.',
    surveyNoResponsesByMonth: 'Rolling 13 months of survey data for all employees that have not submitted a survey response in at least 2 out of the last 3 surveys.'
  },
  recruiting: {
    openRequisitions: 'Requisitions opened in the month for a rolling 13-months.',
    candidateActivity: 'Year to Date overview of candidate activity broken down by stage.',
    averageTimetoFill: 'Rolling 13 months of your average time to fill.',
    candidatesbySource: 'Year to date breakdown of candidates by source (top 15 sources).',
  },
  performance: {
    performanceRating:
      "Breakdown of your employees' last performance rating based on a 5-point scale with 5 being the highest and 1 being the lowest.",
    salaryDistribution:
      'Current salary distribution outlining total employee count at annual rates.',
    performancetoCompaRatioHeatMap:
      "Current employees' last performance rating compared to their compa-ratio, highlighting Good Standing, Needs Attention and On the Move employees. Compa-ratio is defined as an employee's current annual rate compared to market pay bands. ",
    salaryByTenure: 
      "Current employees' currenty salary by number of years the employee has worked at the company.  All zero salaries are removed from the results"
  },
  DEMOGRAPHICS: {
    employeesbyEthnicity: 'Current employee population by ethnicity (top 10 categories).',
    employeebySex: 'Current employee population by sex.',
    hiresbyEthnicity:
      'Rolling 13 month hires by ethnicity broken down into top 10 categories and compared to applicants.',
    tenurebyEthnicity: 'Current employee population tenure by ethnicity (top 10 categories).',
  },
};

export const LastYearData = 'Last 12 Months ';
