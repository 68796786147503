import { Grid, Typography } from '@mui/material';
import React, { useEffect, useContext } from 'react';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PercentIcon from '@mui/icons-material/Percent';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import { getAllListData } from 'services/CommonServices';
import PageLoader from 'components/PageLoader';
import _ from 'lodash';
import Loader from 'components/Loader';
import{ FilterDataContext } from 'context/FilterDataContextProvider';

const statickParam: any = {
  sortBy: '',
  order: '',
};

const LearnDevlopment = () => {
  const [loader, setLoader] = React.useState<boolean>(true);
  const [loader1, setLoader1] = React.useState<boolean>(true);
  const [learingList, setLearingList] = React.useState<any>({});
  const [courses, setCourses] = React.useState<any>([]);
  const [param, setParam] = React.useState<{
    sortBy: string;
    order: 'asc' | 'desc';
  }>(statickParam);

  const {filterData} = useContext(FilterDataContext);

  const userDetail: any = useAppSelector((store: any) => {
    return store.userLogin.userInfo;
  });

  useEffect(() => {
    const endPoint = `${MASTER_ENDPOINT.LearingAndDevelopment}/${userDetail.ClientId}/${userDetail.Id}`;
    getAllListData(endPoint)
      .then((resp) => {
        if (resp && resp?.data) {
          setLearingList(resp?.data);
          setCourses(resp?.data?.courses);
        }
      })
      .finally(() => {
        setLoader(false);
        setLoader1(false);
      });
  }, [filterData]);

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const changeParam = (keyName: string) => {
    setLoader1(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        order: param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setCourses([
      ..._.orderBy(
        courses,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'number'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);

    setTimeout(() => setLoader1(false), 500);
  };

  const tableData = React.useMemo(() => {
    if (courses && courses?.length) {
      return courses.map((row: any) => (
        <TableRow key={row.id} sx={{ ' &:last-child th': { border: 0 } }}>
          <TableCell>
            <Typography className='company-name' sx={{ lineHeight: '20px !important' }}>
              {row?.learningPaths}
            </Typography>
          </TableCell>
          <TableCell>{row?.assignedPeople}</TableCell>
          <TableCell>{row?.completedPeople}</TableCell>
          <TableCell>{row?.completed}</TableCell>
          <TableCell>{row?.overDuePeople}</TableCell>
          <TableCell>{row?.overDue}</TableCell>
        </TableRow>
      ));
    }
    return (
      <TableRow>
        <TableCell colSpan={6} align='center'>
          <Typography component={'div'}>No Records Found</Typography>
        </TableCell>
      </TableRow>
    );
  }, [courses, loader1]);

  return (
    <Grid container>
      <PageLoader loading={loader} />
      <Grid item md={12} sm={12} xs={12} className='d-flex-j'>
        <Typography component={'div'} className='ld-box'>
          <Grid container columnSpacing={2.3}>
            <Grid item md={4} sm={12} xs={12}>
              <Typography component={'div'} className='ld-count-box'>
                <Typography component={'div'} className='d-flex-a mb-18'>
                  <Typography component={'div'}>
                    <Typography className='black-box d-flex-ja'>
                      <WatchLaterIcon className='clock' />
                    </Typography>
                  </Typography>
                  <Typography className='ld-c-title'>Overdue Courses</Typography>
                </Typography>
                <Typography className='ld-count'>
                  {learingList?.overdueCourses ? learingList?.overdueCourses : 0}
                </Typography>
                {learingList?.overdueCoursesThisMonthVariation &&
                  Number(learingList?.overdueCoursesThisMonthVariation) !== 0 ? (
                  Number(learingList?.overdueCoursesThisMonthVariation) >= 0 ? (
                    <Typography className='ld-green d-flex-a'>
                      <ArrowUpwardIcon /> {learingList?.overdueCoursesThisMonthVariation} this month
                    </Typography>
                  ) : (
                    <Typography className='ld-red d-flex-a'>
                      <ArrowDownwardIcon /> {learingList?.overdueCoursesThisMonthVariation} this month
                    </Typography>
                  )
                ) : (
                  <Typography className='ld-red d-flex-a'>
                      <ArrowDownwardIcon /> {learingList?.overdueCoursesThisMonthVariation} this month
                    </Typography>
                )}
              </Typography>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Typography component={'div'} className='ld-count-box'>
                <Typography component={'div'} className='d-flex-a mb-18'>
                  <Typography component={'div'}>
                    <Typography className='black-box d-flex-ja'>
                      <PercentIcon className='clock' />
                    </Typography>
                  </Typography>
                  <Typography className='ld-c-title'>Percentage Overdue </Typography>
                </Typography>
                <Typography className='ld-count'>
                  {learingList?.percentageOverdue ? learingList?.percentageOverdue : 0}%
                </Typography>
                {learingList?.percentageOverdueThisMonthVariation &&
                  Number(learingList?.percentageOverdueThisMonthVariation) !== 0 ? (
                  Number(learingList?.percentageOverdueThisMonthVariation) >= 0 ? (
                    <Typography className='ld-green d-flex-a'>
                      <ArrowUpwardIcon /> {learingList?.percentageOverdueThisMonthVariation}% this month
                    </Typography>
                  ) : (
                    <Typography className='ld-red d-flex-a'>
                      <ArrowDownwardIcon /> {learingList?.percentageOverdueThisMonthVariation}% this month
                    </Typography>
                  )
                ) : (
                  <Typography className='ld-red d-flex-a'>
                  <ArrowDownwardIcon /> {learingList?.percentageOverdueThisMonthVariation} this month
                </Typography>
                )}
              </Typography>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Typography component={'div'} className='ld-count-box'>
                <Typography component={'div'} className='d-flex-a mb-18'>
                  <Typography component={'div'}>
                    <Typography className='black-box d-flex-ja'>
                      <AssignmentTurnedInIcon className='clock' />
                    </Typography>
                  </Typography>
                  <Typography className='ld-c-title'>Total Assigned</Typography>
                </Typography>
                <Typography className='ld-count'>
                  {learingList?.totalAssigned ? learingList?.totalAssigned : 0}
                </Typography>
                {learingList?.totalAssignedThisMonthVariation &&
                  Number(learingList?.totalAssignedThisMonthVariation) !== 0 ? (
                  Number(learingList?.totalAssignedThisMonthVariation) >= 0 ? (
                    <Typography className='ld-green d-flex-a'>
                      <ArrowUpwardIcon /> {learingList?.totalAssignedThisMonthVariation} this month
                    </Typography>
                  ) : (
                    <Typography className='ld-red d-flex-a'>
                      <ArrowDownwardIcon /> {learingList?.totalAssignedThisMonthVariation} this month
                    </Typography>
                  )
                ) : (
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='zero_per'></Typography> {learingList?.totalAssignedThisMonthVariation} this month
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <TableContainer component={Paper} className='ld-table mt-40'>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography className='th-title'>Courses</Typography>
                      <Typography
                        className='arrow-box'
                        onClick={() => changeParam('learningPaths')}>
                        <ArrowDropDownIcon className='down' />
                        <ArrowDropUpIcon className='up' />
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography className='th-title'>Assigned People</Typography>
                      <Typography
                        className='arrow-box'
                        onClick={() => changeParam('assignedPeople')}>
                        <ArrowDropDownIcon className='down' />
                        <ArrowDropUpIcon className='up' />
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography className='th-title'>Completed People</Typography>
                      <Typography
                        className='arrow-box'
                        onClick={() => changeParam('completedPeople')}>
                        <ArrowDropDownIcon className='down' />
                        <ArrowDropUpIcon className='up' />
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography className='th-title'>Completed %</Typography>
                      <Typography className='arrow-box' onClick={() => changeParam('completed')}>
                        <ArrowDropDownIcon className='down' />
                        <ArrowDropUpIcon className='up' />
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography className='th-title'>Overdue People</Typography>
                      <Typography
                        className='arrow-box'
                        onClick={() => changeParam('OverduePeople')}>
                        <ArrowDropDownIcon className='down' />
                        <ArrowDropUpIcon className='up' />
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'div'} className='d-flex-a'>
                      <Typography className='th-title'>Overdue %</Typography>
                      <Typography className='arrow-box' onClick={() => changeParam('OverDue')}>
                        <ArrowDropDownIcon className='down' />
                        <ArrowDropUpIcon className='up' />
                      </Typography>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loader1 ? <Loader loading={loader1} colsSpan={6} /> : tableData}
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LearnDevlopment;
