import React from 'react';
import Login from 'pages/public/Login';
import VerifyOTP from 'pages/public/VerifyOTP';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PublicRoutes as PublicRoutesDirectives } from './routeConstans';
import CreatePassword from 'pages/public/CreatePassword';
import PasswordSucess from 'pages/public/PasswordSucess';
import ForgotPassword from 'pages/public/ForgotPassword';
import ForgotPasswordSuccess from 'pages/public/ForgotPasswordSuccess';
import ClientLogin from 'pages/public/ClientLogin';
const PublicRoutes = () => {
  const {
    ADMINLOGIN,
    ADMINRESET,
    CLIENTLOGIN,
    CLIENTCOMPANYLOGIN,
    VERIFYADMIN,
    VERIFYCLIENT,
    CLIENTRESET,
    ADMINPASSWORDSUCESS,
    CLIENTPASSWORDSUCESS,
    FORGOTPASSWORDADMIN,
    FORGOTPASSWORDCLIENT,
    FORGOTPASSWORDSUCCESSCLIENT,
    FORGOTPASSWORDSUCCESSADMIN,
  } = PublicRoutesDirectives;
  return (
    <Routes>
      <Route path='/' element={<Navigate to={CLIENTLOGIN} replace />} />
      <Route path={ADMINLOGIN} element={<Login from={'admin'} />} />
      <Route path={ADMINRESET} element={<VerifyOTP from={'admin'} />} />
      <Route path={VERIFYADMIN} element={<CreatePassword from={'admin'} />} />
      <Route path={CLIENTLOGIN} element={<Login from={'client'} />} />
      <Route path={CLIENTRESET} element={<VerifyOTP from={'client'} />} />
      <Route path={VERIFYCLIENT} element={<CreatePassword from={'client'} />} />
      <Route path={ADMINPASSWORDSUCESS} element={<PasswordSucess from={'admin'} />} />
      <Route path={CLIENTPASSWORDSUCESS} element={<PasswordSucess from={'client'} />} />
      <Route path={FORGOTPASSWORDADMIN} element={<ForgotPassword from={'admin'} />} />
      <Route path={FORGOTPASSWORDCLIENT} element={<ForgotPassword from={'client'} />} />
      <Route path={FORGOTPASSWORDSUCCESSADMIN} element={<ForgotPasswordSuccess from={'admin'} />} />
      <Route
        path={FORGOTPASSWORDSUCCESSCLIENT}
        element={<ForgotPasswordSuccess from={'client'} />}
      />
      <Route path={CLIENTCOMPANYLOGIN} element={<ClientLogin />} />
      <Route path='*' element={<Navigate to={CLIENTLOGIN} />} />
    </Routes>
  );
};

export default PublicRoutes;
