import React, { useEffect } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
// import FilterIcon from 'assets/png/Filter.svg';
import CloseIcon from 'assets/png/close.svg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getAllListData } from 'services/CommonServices';
import { ORGANIZTION_DASHBOARD, REPORT_ENDPOINT } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import moment from 'moment';
import PageLoader from 'components/PageLoader';
import { CSVLink } from 'react-csv';
import { roundOfData, roundOfDataNum } from 'utils/Config';
import * as R from 'ramda';
import _ from 'lodash';

interface PropsData {
  visible: boolean;
  handleClose: () => void;
  title: string;
  keyName: string;
  columnArray: any;
}

const statickParam: any = {
  sortBy: '',
  order: '',
};

const ReportModal = (props: PropsData) => {
  const { visible, handleClose, title, keyName, columnArray } = props;
  const [loader, setLoader] = React.useState<boolean>(false);
  const [yearAndMonthList, setYearAndMonthList] = React.useState<any>([]);
  const [list, setList] = React.useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [param, setParam] = React.useState<{
    sortBy: string;
    order: 'asc' | 'desc';
  }>(statickParam);
  const [downloadData, setDownloadData] = React.useState<any>([]);
  const { userInfo: userDetail, profileInfo }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });

  useEffect(() => {
    getData(keyName);
  }, [keyName]);

  const getData = (keyName: string) => {
    setLoader(true);
    let url = '';
    if (keyName === 'HeadCountMonthOverMonthDetails') {
      url = `${
        REPORT_ENDPOINT.GetAllHeadcountMOMwithDetails
      }/${userDetail?.ClientId}/${moment().format('YYYY-MM-DD')}/${userDetail?.Id}`;
    } else if (keyName === 'CostOfTurnOver') {
      url = `${REPORT_ENDPOINT.CostOfTurnOver}/${userDetail?.ClientId}/${moment().format(
        'YYYY-MM-DD',
      )}/${userDetail?.Id}`;
    } else if (keyName === 'HeadCountWDetails') {
      url = `${REPORT_ENDPOINT.GetCountWDetails}/${userDetail?.ClientId}/${moment().format(
        'YYYY-MM-DD',
      )}/${userDetail?.Id}`;
    } else if (keyName === 'OrganizationalChart') {
      url = `${REPORT_ENDPOINT.Organizationalchat}/${userDetail?.ClientId}/${moment().format(
        'YYYY-MM-DD',
      )}/${userDetail?.Id}`;
    } else if (keyName === 'HiresvsTermsw/Details') {
      url = `${ORGANIZTION_DASHBOARD.HiresAndTerms}/${userDetail?.ClientId}/${moment().format(
        'YYYY-MM-DD',
      )}/${userDetail?.Id}`;
    } else if (keyName === 'LastPreformance') {
      url = `${REPORT_ENDPOINT.GetAllLastPreformance}/${userDetail?.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'CoursesCompleted') {
      url = `${REPORT_ENDPOINT.GetCourseCompleted}/${userDetail?.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'CoursesOverdue') {
      url = `${REPORT_ENDPOINT.GetCourseOverDue}/${userDetail?.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'CoursesAssigned') {
      url = `${REPORT_ENDPOINT.GetCourseAssigned}/${userDetail?.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'TurnoverwDetails') {
      url = `${REPORT_ENDPOINT.GetTurnOverDetails}/${userDetail?.ClientId}/${moment().format(
        'YYYY-MM-DD',
      )}/${userDetail.Id}`;
    } else if (keyName === 'CompaRatio') {
      url = `${REPORT_ENDPOINT.GetCompaRation}/${userDetail?.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'NextPreformance') {
      url = `${REPORT_ENDPOINT.GetAllNextPreformance}/${userDetail?.ClientId}`;
    } else if (keyName === 'EmployeeEthnicity') {
      url = `${REPORT_ENDPOINT.GetAllEmployeesEthnicity}/${userDetail?.ClientId}`;
    } else if (keyName === 'EmployeeGender') {
      url = `${REPORT_ENDPOINT.GetAllEmployesGender}/${userDetail?.ClientId}`;
    } else if (keyName === 'PayEthnicity') {
      url = `${REPORT_ENDPOINT.GetAllPayEthnicity}/${userDetail.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'PayGender') {
      url = `${REPORT_ENDPOINT.GetAllPayGender}/${userDetail.ClientId}`;
    } else if (keyName === 'TenureRace') {
      url = `${REPORT_ENDPOINT.GetAllTenureRace}/${userDetail.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'EmployeeDemographics') {
      url = `${REPORT_ENDPOINT.GetDemographics}/${userDetail.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'OverAllSurveyResults') {
      url = `${REPORT_ENDPOINT.GetOverAllSurveyResult}/${userDetail.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'ScoresbyCategory') {
      url = `${REPORT_ENDPOINT.GetAllScoreByCategory}/${userDetail.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'TimetoFill') {
      url = `${REPORT_ENDPOINT.GetAllTimeToFill}/${userDetail.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'OpenRequisition') {
      url = `${REPORT_ENDPOINT.GetOpenRequisition}/${userDetail.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'hiredCandidates') {
      url = `${REPORT_ENDPOINT.GetHiredCandidates}/${userDetail.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'OverallBreakdown') {
      url = `${REPORT_ENDPOINT.GetOverAllBreakDown}/${userDetail.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'SurveySubmissionRate') {
      url = `${REPORT_ENDPOINT.GetSurveySubmission}/${userDetail.ClientId}/${userDetail.Id}`;
    } else if (keyName === 'offeredStart') {
      url = `${REPORT_ENDPOINT.GetOfferedStart}/${userDetail.ClientId}/${userDetail.Id}`;
    }
    getAllListData(url)
      .then((response: any) => {
        if (keyName === 'HeadCountMonthOverMonthDetails') {
          if (response?.data && response?.data.length) {
            let dateArray: any = [];
            response?.data.map((item: any) => {
              dateArray = dateArray.concat(item?.data ? item?.data : []);
            });
            const filterByYearMonth = dateArray.filter(
              (item: any, pos: any, self: any) =>
                self.findIndex((v: any) => v.yearMonth === item.yearMonth) === pos,
            );
            const diff = function (a: any, b: any) {
              return Number(moment(b.yearMonth)) - Number(moment(a.yearMonth));
            };
            response.data = response?.data.filter((item: any) => {
              if (item.classcode1 === 'NoDataForThisMonth') {
                return false;
              }
              return true;
            });
            setYearAndMonthList(R.sort(diff, filterByYearMonth));
          }
        } else if (keyName === 'HiresvsTermsw/Details') {
          const getHired: any = R.prop('totalHired');
          const sumHired: any = R.reduce(R.add, 0);
          const totalHired = sumHired(R.map(getHired, response?.data));
          const getTermination: any = R.prop('totalTermination');
          const sumTermination: any = R.reduce(R.add, 0);
          const totalTermination = sumTermination(R.map(getTermination, response?.data));

          if (response?.data && response?.data.length) {
            // _.orderBy(response?.data, [(item: any) => item['yearMonth']], 'desc');
            response.data = _.orderBy(response?.data, [(item: any) => item['yearMonth']], 'desc');
            response?.data.push({
              totalHired: totalHired,
              totalTermination: totalTermination,
              yearMonth: 'Annualized',
            });
          }
        }
        setList(response?.data);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const changeParam = (coloumName: string) => {
    setLoader(true);
    setParam((pre: any) => {
      setList((pre1: any) => [
        ..._.orderBy(
          pre1,
          [
            (item: any) =>
              item[coloumName]
                ? typeof item[coloumName] === 'number'
                  ? item[coloumName]
                  : item[coloumName]?.toLowerCase()
                : '',
          ],
          pre.sortBy === coloumName && pre.order === 'asc' ? 'desc' : 'asc',
        ),
      ]);
      return {
        ...pre,
        sortBy: coloumName,
        order: pre.sortBy === coloumName && pre.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setTimeout(() => setLoader(false), 500);
  };

  const tableData = React.useMemo(() => {
    if (list.length) {
      return list.map((row: any, index: number) => {
        return (
          <TableRow key={index}>
            {columnArray.map((item: any, index1: number) => {
              return (
                <TableCell key={index + index1}>
                  {item?.date
                    ? moment(row[item.value]).format(item.format) !== 'Invalid date'
                      ? moment(row[item.value]).format(item.format)
                      : row[item.value]
                    : item?.amount
                      ? roundOfData(Number(row[item.value]))
                      : item?.plus
                        ? Math.abs(Number(row[item.value]))
                        : item?.astric
                          ? '$' + roundOfDataNum(row[item.value])
                          : item?.percentage
                            ? `${row[item.value]}%`
                            : row[item.value]}
                </TableCell>
              );
            })}
          </TableRow>
        );
      });
    }

    return (
      <TableRow>
        <TableCell colSpan={columnArray.length} align='center'>
          <Typography component={'div'} className='d-flex-ja '>
            No Records Found
          </Typography>
        </TableCell>
      </TableRow>
    );
  }, [list]);

  const tableColumn = React.useMemo(() => {
    return columnArray.map((item: any, index: number) => {
      return (
        <TableCell key={index} sx={{ minWidth: item?.minWidth ? item?.minWidth : 'auto' }}>
          <Typography component={'div'} className='d-flex-a'>
            <Typography className='th-title'>{item.name}</Typography>
            <Typography className='arrow-box' onClick={() => changeParam(`${item.value}`)}>
              <ArrowDropDownIcon className='down' />
              <ArrowDropUpIcon className='up' />
            </Typography>
          </Typography>
        </TableCell>
      );
    });
  }, [columnArray]);

  const tableYearMonthColumn = React.useMemo(() => {
    return yearAndMonthList.map((item: any, index: number) => {
      return (
        <TableCell key={index} sx={{ minWidth: item?.minWidth ? item?.minWidth : 'auto' }}>
          <Typography component={'div'} className='d-flex-a'>
            <Typography className='th-title'>{moment(item.yearMonth).format('YYYY-MM')}</Typography>
          </Typography>
        </TableCell>
      );
    });
  }, [yearAndMonthList]);

  const tableDataHeadCount = React.useMemo(() => {
    if (list.length) {
      return list.map((row: any, index: number) => {
        return (
          <TableRow key={index}>
            <TableCell sx={{ minWidth: '180px' }}>{row?.classcode1}</TableCell>
            {yearAndMonthList.map((item: any, index1: number) => {
              if (row?.data && row?.data?.length) {
                const findItem = row?.data.find((item1: any) => item1.yearMonth === item.yearMonth);
                return <TableCell key={index1}>{findItem ? findItem?.headCount : ''}</TableCell>;
              } else {
                return <TableCell key={index1} />;
              }
            })}
          </TableRow>
        );
      });
    }
    return (
      <TableRow>
        <TableCell colSpan={columnArray.length} align='center'>
          <Typography component={'div'} className='d-flex-ja'>
            No Records Found
          </Typography>
        </TableCell>
      </TableRow>
    );
  }, [list, yearAndMonthList]);

  return (
    <Modal
      open={visible}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box component='div' className='report-modal'>
        <Typography component={'div'} className='d-flex-a flex-sb modal-h-pad'>
          <Typography className='modal-title'>{title}</Typography>
          <Typography component={'div'} className='d-flex-a'>
            <Typography className='down-box d-flex-ja mr-10'>
              <CSVLink
                filename={`${title}-${profileInfo?.clientName}-${moment().format(
                  'MM/DD/YYYY',
                )}.csv`}
                data={downloadData}
                onClick={async (event: any, done: (arg0: boolean) => void) => {
                  const overAllData: any = [];
                  let columnData: any = [];
                  if (keyName === 'HeadCountMonthOverMonthDetails') {
                    columnData = columnData.concat(['']);
                    await yearAndMonthList.map(async (row: any) => {
                      columnData = columnData.concat([moment(row.yearMonth).format('YYYY-MM')]);
                    });
                    overAllData.push(columnData);
                    await list.map(async (row: any) => {
                      let innerData: any = [];
                      innerData = innerData.concat([row?.classcode1]);
                      await yearAndMonthList.map((item: any) => {
                        if (row?.data && row?.data?.length) {
                          const findItem = row?.data.find(
                            (item1: any) => item1.yearMonth === item.yearMonth,
                          );
                          innerData = innerData.concat([findItem ? findItem?.headCount : '']);
                        } else {
                          innerData = innerData.concat(['']);
                        }
                      });
                      overAllData.push(innerData);
                    });
                  } else {
                    await columnArray.map((item: any) => {
                      columnData = columnData.concat([item.name]);
                    });
                    overAllData.push(columnData);
                    await list.map(async (row: any) => {
                      let innerData: any = [];
                      await columnArray.map((item: any) => {
                        innerData = innerData.concat([
                          item?.date
                            ? moment(row[item.value]).format(item.format) !== 'Invalid date'
                              ? moment(row[item.value]).format(item.format)
                              : row[item.value]
                            : item?.amount
                              ? roundOfData(Number(row[item.value]))
                              : item?.plus
                                ? Math.abs(Number(row[item.value]))
                                : item?.astric
                                  ? '$' + roundOfDataNum(row[item.value])
                                  : row[item.value],
                        ]);
                      });
                      overAllData.push(innerData);
                    });
                  }
                  setDownloadData(overAllData);
                  done(true);
                }}
                asyncOnClick={true}>
                <SaveAltIcon sx={{ fill: '#b1b4be' }} />
              </CSVLink>
            </Typography>
            <img src={CloseIcon} alt='close' className='close-icon' onClick={handleClose} />
          </Typography>
        </Typography>
        <TableContainer component={Paper} className='report-table'>
          <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
            <TableHead>
              <TableRow>
                {keyName === 'HeadCountMonthOverMonthDetails' ? (
                  <>
                    <TableCell sx={{ minWidth: '150px' }} />
                    {tableYearMonthColumn}
                  </>
                ) : (
                  tableColumn
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <PageLoader loading={loader} />
              ) : keyName === 'HeadCountMonthOverMonthDetails' ? (
                tableDataHeadCount
              ) : (
                tableData
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default ReportModal;
