import * as React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardActive from 'assets/png/NavDashboardActive.svg';
import DashboardNormal from 'assets/png/NavDashboardNormal.svg';
import NavNotificationActive from 'assets/png/NavNotificationActive.svg';
import NavNotificationNormal from 'assets/png/NavNotificationNormal.svg';
import NavLogout from 'assets/png/NavLogout.svg';
// import SearchIcon from 'assets/png/Search.svg';
import MenuLogo from 'assets/png/Expand.svg';
// import FilterIcon from 'assets/png/Filter.svg';
import Fulllogo from 'assets/png/LogoWhite.svg';
import Halflogo from 'assets/png/LogoIcon.png';
import { Avatar, Box, Button, Grid, Modal, Tooltip } from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { AdminPrivateRoutes as PrivateRoutesDirectives, PublicRoutes } from 'routes/routeConstans';
import { Link, To, useLocation, useNavigate } from 'react-router-dom';
import AddAndEdit from './admins/addAndEdit';
import { useDispatch } from 'react-redux';
import { logout } from 'store/actions/UserAction';
import InfoIcon from '@mui/icons-material/Info';
import { useEffect } from 'react';
import { addNewData, getAllListData, updateData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ADMIN_PROFILE } from 'store/types/UserTypes';
import PageLoader from 'components/PageLoader';
import AddSurveyQuestionPopup from 'components/AddSurveyQuestion';
import ClientEditorPopup from 'components/ClientEditorPopup';

const drawerWidth = 245;

const logoutStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  minHeight: '168px',
  width: '414px',
  borderRadius: ' 8px',
  boxShadow: ' 0 5px 14px 0 rgba(0,0,0,0.22)',
  padding: '28px 30px',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 450,
  maxWidth: 700,
  bgcolor: 'background.paper',
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
  padding: '30px',
};

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: '#344A5C',

  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'auto',
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: '#344A5C',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const loginimgopen = (): CSSObject => ({
  display: 'flex',
  paddingRight: 15,
  paddingLeft: 15,
});

const loginimgclose = (): CSSObject => ({
  display: 'none',
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
      '& .sidebaravatar': loginimgopen(),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      '& .sidebaravatar': loginimgclose(),
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  paddingLeft: '20px !important',
  height: 80,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  height: 80,
  justifyContent: 'center',
  background: '#FFF',
  boxShadow: 'unset !important',
  borderBottom: '1px solid #E8E8E8',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

type LayoutConfigsProps = {
  path: string;
  children: any;
  setSurveyQuestionsChanged: any;
  setClientsChanged: any;
};

const drawerStyle = {
  text: {
    fontSize: '16px !important',
    lineHeight: '24px !important',
  },
  icon: {
    height: 20,
    width: 20,
  },
  iconMenu: {
    height: 22,
    width: 22,
  },
  zastiIcon: {
    height: 26,
    width: 75,
  },
  listButton: {
    borderRadius: '4px',
    margin: '15px',
  },
  versionText: {
    position: 'absolute',
    right: 15,
    fontSize: '12px !important',
    lineHeight: '20px !important',
  },
  organizationLogo: {
    width: 68,
    height: 55,
  },
  nameLogo: {
    height: 32,
    width: 32,
    borderRadius: 16,
    backgroundColor: '#28A9E1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px !important',
    lineHeight: '24px !important',
    fontWeight: '500',
  },
  nametext: {
    fontSize: '14px !important',
    lineHeight: '20px !important',
    fontWeight: '500',
    color: '#111',
    marginRight: '10px',
  },
  profilePng: {
    height: 20,
    width: 24,
  },
};

const AdminPrivateLayout = (props: LayoutConfigsProps) => {
  const dispatch: any = useDispatch();
  const { COMPANY, ADMINS, EMPLOYEES, CLASSIFICATION, SURVEYQUESTIONS, SURVEYCATEGORIES } = PrivateRoutesDirectives;
  const theme = useTheme();
  const [logoutOpen, setLogoutOpen] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(true);
  const [adminopen, setAdminOpen] = React.useState(false);
  const [profileopen, setProfileOpen] = React.useState(false);
  const [profileList, setProfileList] = React.useState<any>({});
  const [uploadImage, setUploadImage] = React.useState<any>({});
  const [localImage, setLocalImage] = React.useState<any>('');
  const [loader, setLoader] = React.useState(false);
  const handleProfileOpen = () => setProfileOpen(true);
  const handleProfileClose = () => setProfileOpen(false);
  const handleOpen = () => setAdminOpen(true);
  const handleClose = () => setAdminOpen(false);
  const location = useLocation();
  const [addOpen, setAddOpen] = React.useState(false);
  const [clientEditorOpen, setClientEditorOpen] = React.useState(false);
  const [client, setClient] = React.useState({});
  const [clientConfiguration, setClientConfiguration] = React.useState({});

   const handleAddClick = (evt: any) => {
          evt.preventDefault();
          setAddOpen(true);
      };
    
    const handleAddClientClick = (evt: any) => {
      evt.preventDefault();
      setClient({
        'id': 0,            
        'companyName': '',
        'address': '',
        'city': '',
        'state': '',
        'zip': '',
        'active': true
    });
      setClientConfiguration( {
        'id': 0,
        'companyDisplayName': '',
        'surveyDay': 4,
        'weeksBetweenSurveys': 2,
        'numberOfDaysTillReminder': 4,
        'numberOfDaysTillManagerEmail': 6,
        'nextSurveyDate': new Date(),
        'numberOfSurveys': 3,
        'active': true
    });
      setClientEditorOpen(true);
  };


  const userDetail: any = useAppSelector((store: any) => {
    return store.userLogin.userInfo;
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const logoutFunction = () => {
    addNewData(
      '',
      `${MASTER_ENDPOINT.LogoutUser}?clientId=${
        userDetail.ClientId ? userDetail.ClientId : 0
      }&userId=${userDetail.UserId}`,
    )
      .then(() => {
        logout(dispatch);
        setTimeout(() => gotoNavigate(PublicRoutes.ADMINLOGIN), 0);
      })
      .catch(() => {});
  };
  const GetProfile = () => {
    getAllListData(`${MASTER_ENDPOINT.GetSuperAdminId}`)
      .then((res) => {
        dispatch({
          type: ADMIN_PROFILE,
          payload: res?.data?.[0],
        });
        setProfileList(res?.data?.[0]);
      })
      .catch(() => {});
  };

  const setSurveyQuestions = () =>  {
    props.setSurveyQuestionsChanged(true);
  }

  const setClients = () =>  {
    props.setClientsChanged(true);
  }

  useEffect(() => {
    if (userDetail?.Id) {
      GetProfile();
    }
  }, [userDetail?.Id]);

  const updatePhoto = (e: any) => {
    const imageFormatCheck = e?.target?.files[0]?.name.split('.');
    const formatCheck = ['tiff', 'jpeg', 'jpg', 'png'].includes(
      imageFormatCheck[imageFormatCheck?.length - 1].toLowerCase(),
    );
    const maxSizeInMB = 10;
    const fileSizeInMB = e?.target?.files[0].size / 1024 / 1024;
    if (fileSizeInMB > maxSizeInMB) {
      toast.error('File size should not exceed 10MB.');
    } else if (formatCheck) {
      setLoader(true);
      setUploadImage(e?.target?.files[0]);
      setLocalImage(URL.createObjectURL(e?.target?.files[0]));
      if (e?.target?.files[0]) {
        const formData2 = new FormData();
        if (uploadImage) {
          formData2.append('files', e?.target?.files[0]);
        }
        formData2.append('UserId', userDetail.UserId);
        if (userDetail.ClientId) {
          formData2.append('ClientId', userDetail.ClientId);
        } else {
          formData2.append('ClientId', `${0}`);
        }

        profileList?.imageId
          ? formData2.append('ImageId', profileList?.imageId)
          : formData2.append('ImageId', profileList?.null);
        formData2.append('CreatedBy', userDetail.Id);
        formData2.append('CreatedOn', moment().format());
        formData2.append('UpdatedBy', userDetail.Id);
        formData2.append('UpdatedOn', moment().format());

        updateData('', formData2, `${MASTER_ENDPOINT.UploadPicture}`, true, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(() => {
            GetProfile();
            setLoader(false);
          })
          .catch(() => {
            setLoader(false);
          });
      }
    } else {
      toast.error('Upload valid images only PNG, JPEG, JPG and TIFF are allowed');
    }
  };

  return (
    <Box component='div' sx={{ display: 'flex', minHeight: '100vh' }}>
      <PageLoader loading={loader} />
      <CssBaseline />
      <AppBar position='fixed' open={open} className='header-border'>
        <Toolbar className='custom-toolbar'>
          <Typography component={'div'} className='d-flex-a'>
            <Typography
              className='hr adminextra'
              sx={{ ...(open && { display: 'none' }) }}
              component={'p'}>
              <img src={Halflogo} alt='HR' />
            </Typography>
            {props.path === COMPANY && (
              <Typography component={'div'} className='page-title'>
                Companies
              </Typography>
            )}
            {props.path === EMPLOYEES && (
              <Typography component={'div'} className='d-flex-a pl-20'>
                <Typography
                  component={'div'}
                  className='d-flex-a pointer'
                  onClick={() => gotoNavigate(COMPANY)}>
                  <ArrowBackIosIcon className='backicon' />
                  <Typography component={'div'} className='backlabel'>
                    Back
                  </Typography>
                </Typography>
                <Typography className='vborder' />
                <Typography className='backlabel'>{location?.state?.companyName}</Typography>
              </Typography>
            )}
            {props.path === CLASSIFICATION && (
              <Typography component={'div'} className='d-flex-a pl-20'>
                <Typography
                  component={'div'}
                  className='d-flex-a pointer'
                  onClick={() => gotoNavigate(COMPANY)}>
                  <ArrowBackIosIcon className='backicon' />
                  <Typography component={'div'} className='backlabel'>
                    Back
                  </Typography>
                </Typography>
                <Typography className='vborder' />
                <Typography className='backlabel'>{location?.state?.companyName}</Typography>
              </Typography>
            )}
            {props.path === ADMINS && (
              <Typography component={'div'} className='page-title'>
                Admins
              </Typography>
            )}
            {props.path === SURVEYCATEGORIES && (
              <Typography component={'div'} className='page-title'>
                Survey Categories
              </Typography>
            )}
            {props.path === SURVEYQUESTIONS && (
              <Typography component={'div'} className='page-title'>
                Survey Questions
              </Typography>
            )}
          </Typography>
          <IconButton
            className={'collapse-btn'}
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              position: 'fixed',
              bottom: '16vh',
              left: '50px',
              ...(open && { display: 'none' }),
            }}>
            <Avatar sx={drawerStyle.iconMenu} variant='square' alt='Zasti' src={MenuLogo} />
          </IconButton>
          <Typography component={'div'}>
            {props.path === ADMINS && (
              <>
                <Button className='tool-add-btn' onClick={handleOpen}>
                  {' '}
                  + SUPER ADMIN
                </Button>
                <AddAndEdit visible={adminopen} handleClose={handleClose} />
              </>
            )}
            {props.path === SURVEYQUESTIONS && (
              <>
                <button className='tool-add-btn' style={{ width: 175 }} onClick={handleAddClick}>Add New Question +</button>
              </>
              )
            }
            {props.path === COMPANY && (
              <>
                <button className='tool-add-btn' style={{width:250}} onClick={() => window.location.href=`${process.env.REACT_APP_APIURL}Clients/GetEmployeeSurveyTemplate`}>
                  Download Employee Template
                </button>
                <button className='tool-add-btn' style={{ width: 175 }} onClick={handleAddClientClick}>Add New Company +</button>
              </>
              )
            }
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open} className={open ? 'normalside' : 'smallside'}>
        <DrawerHeader className='draw-header'>
          <Typography className='headerlogo-text d-flex-a'>
            <img src={Fulllogo} alt='SimplifiedHR' />
          </Typography>
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              bottom: '16vh',
              position: 'fixed',
              left: '224px',
              ...(open ? { display: 'block' } : { display: 'none' }),
            }}
            className='collapse-btn'>
            {theme.direction === 'ltr' ? (
              <Avatar sx={drawerStyle.iconMenu} variant='square' alt='Zasti' src={MenuLogo} />
            ) : (
              <></>
            )}
          </IconButton>
        </DrawerHeader>
        {open ? (
          <Typography component={'div'} className='headerlogo-text d-flex-a portal-name'>
            Admin Portal
          </Typography>
        ) : (
          ''
        )}
        <Divider className='smallborder' sx={{ background: ' rgba(177,180,190,0.22)' }} />
        <List>
          <ListItem className='profile-item' sx={{ cursor: 'pointer' }} onClick={handleProfileOpen}>
            <ListItemAvatar sx={{ textTransform: 'uppercase' }}>
              {profileList?.imageId ? (
                <Avatar
                  src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${profileList?.imageId}`}
                />
              ) : (
                <Avatar>
                  {profileList?.firstName
                    ? `${profileList?.firstName?.charAt(0)}${profileList?.lastName?.charAt(0)}`
                    : ''}
                </Avatar>
              )}
            </ListItemAvatar>

            <ListItemText
              sx={{ textTransform: 'capitalize' }}
              primary='Welcome'
              secondary={`${profileList?.firstName ? profileList?.firstName : ''} ${
                profileList?.lastName ? profileList?.lastName : ''
              }`}>
              {`${profileList?.clientName ? profileList?.clientName : ''} `}
            </ListItemText>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={COMPANY} className={'sidebarLink'}>
              <ListItemButton
                className={
                  props.path === COMPANY ||
                  props.path === EMPLOYEES ||
                  props.path === CLASSIFICATION
                    ? 'sideBarActive'
                    : ''
                }
                sx={{
                  ...drawerStyle.listButton,
                  justifyContent: open ? 'initial' : 'center',
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <Avatar
                    sx={drawerStyle.icon}
                    variant='square'
                    alt='Companies'
                    src={props.path === COMPANY ? DashboardActive : DashboardNormal}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={'Companies'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiTypography-root': drawerStyle.text,
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={ADMINS} className={'sidebarLink'}>
              <ListItemButton
                className={props.path === ADMINS ? 'sideBarActive' : ''}
                sx={{
                  ...drawerStyle.listButton,
                  justifyContent: open ? 'initial' : 'center',
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <Avatar
                    sx={drawerStyle.icon}
                    variant='square'
                    alt='ADMINS'
                    src={props.path === ADMINS ? NavNotificationActive : NavNotificationNormal}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={'Admins'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiTypography-root': drawerStyle.text,
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={SURVEYCATEGORIES} className={'sidebarLink'}>
              <ListItemButton
                className={props.path === SURVEYCATEGORIES ? 'sideBarActive' : ''}
                sx={{
                  ...drawerStyle.listButton,
                  justifyContent: open ? 'initial' : 'center',
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <Avatar
                    sx={drawerStyle.icon}
                    variant='square'
                    alt='Survey Categories'
                    src={props.path === SURVEYCATEGORIES ? NavNotificationActive : NavNotificationNormal}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={'Survey Categories'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiTypography-root': drawerStyle.text,
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={SURVEYQUESTIONS} className={'sidebarLink'}>
              <ListItemButton
                className={props.path === SURVEYQUESTIONS ? 'sideBarActive' : ''}
                sx={{
                  ...drawerStyle.listButton,
                  justifyContent: open ? 'initial' : 'center',
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <Avatar
                    sx={drawerStyle.icon}
                    variant='square'
                    alt='Survey Questions'
                    src={props.path === SURVEYQUESTIONS ? NavNotificationActive : NavNotificationNormal}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={'Survey Questions'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiTypography-root': drawerStyle.text,
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
        <Box component='div' className='admin-sidebar-footer'>
          <List>
            <Typography className='set-diveder' />
            <ListItem disablePadding sx={{ display: 'block' }} onClick={() => setLogoutOpen(true)}>
              <ListItemButton
                sx={{
                  ...drawerStyle.listButton,
                  justifyContent: open ? 'initial' : 'center',
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <Avatar sx={drawerStyle.icon} variant='square' alt='Scenarios' src={NavLogout} />
                </ListItemIcon>
                <ListItemText
                  primary={'Logout'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiTypography-root': drawerStyle.text,
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1 }} className='contant-bgcolor'>
        <DrawerHeader />
        {props.children}
      </Box>
      <AddSurveyQuestionPopup addOpen={addOpen} setAddOpen={setAddOpen} setLoading={setLoader} setSurveyQuestions={setSurveyQuestions}></AddSurveyQuestionPopup>
      <ClientEditorPopup popupOpen={clientEditorOpen} setPopupOpen={setClientEditorOpen} setLoading={setLoader} setClientsChanged={setClients} client={client} clientConfiguration={clientConfiguration}></ClientEditorPopup>
      <Modal
        className='profile-modal'
        open={profileopen}
        onClose={handleProfileClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box component='div' sx={style}>
          <Typography component={'div'} className='d-flex-a flex-sb'>
            <Typography className='p-title'>Profile Information</Typography>
            <Typography className='close-text' onClick={handleProfileClose}>
              Close
            </Typography>
          </Typography>
          <Typography
            component={'div'}
            className='mt-20'
            sx={{ display: 'flex', marginBottom: '14px' }}>
            <Typography component={'div'}>
              {localImage ? (
                <img className='profile-default d-flex-ja' src={localImage} />
              ) : profileList.imageId ? (
                <img
                  className='profile-default d-flex-ja'
                  src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${profileList?.imageId}`}
                />
              ) : (
                <Typography
                  className='profile-default d-flex-ja'
                  sx={{ textTransform: 'capitalize' }}>
                  {`${profileList?.firstName?.charAt(0)}${profileList?.lastName?.charAt(0)}`}
                </Typography>
              )}
              <Typography className='d-flex-ja'>
                <Button component='label' className='add-photo text-center'>
                  <input
                    hidden
                    type='file'
                    accept='image/*'
                    onChange={(e) => updatePhoto(e)}
                    onClick={(event: any) => {
                      event.target.value = null;
                    }}
                  />
                  + Photo
                </Button>

                <span className='add-photo' style={{ marginTop: '10px !important' }}>
                  <Tooltip
                    title={
                      <Typography className='tooltip-text'>
                        Maximum file size should be 10 MB
                      </Typography>
                    }
                    arrow
                    placement='bottom-start'>
                    <InfoIcon fontSize='small' />
                  </Tooltip>
                </span>
              </Typography>
            </Typography>
            <Typography component={'div'} className='pl-32 pt-10 flex-1'>
              <Grid container>
                <Grid item md={3} sm={12} xs={12}>
                  <Typography className='labels'>Name</Typography>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <Typography className='details' sx={{ textTransform: 'capitalize' }}>
                    {`${profileList?.firstName} ${profileList?.lastName}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={3} sm={12} xs={12}>
                  <Typography className='labels'>Email</Typography>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <Typography className='details'>{profileList?.username}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={3} sm={12} xs={12}>
                  <Typography className='labels'>Role</Typography>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <Typography className='details'>Super Admin</Typography>
                </Grid>
              </Grid>
              {/* <Grid container>
                <Grid item md={5} sm={12} xs={12}>
                  <Typography className='labels'>Department</Typography>
                </Grid>
                <Grid item md={7} sm={12} xs={12}>
                  <Typography className='details'>Web Developement</Typography>
                </Grid>
              </Grid> */}
            </Typography>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={logoutOpen}
        onClose={() => setLogoutOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box component='div' sx={logoutStyle}>
          <Typography className='d-flex-a flex-sb'>
            <Typography className='modal-title'>Logout</Typography>
          </Typography>
          <Typography className='modal-dis'>Are you sure you want to logout?</Typography>
          <Typography component={'div'} className='d-flex-a pt-15' columnGap='5px'>
            <Button className='modal-no-btn' onClick={() => setLogoutOpen(false)}>
              NO, CANCEL
            </Button>
            <Button className={'modal-yes-btn'} onClick={logoutFunction}>
              YES, Logout
            </Button>
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default AdminPrivateLayout;
