import {
    Typography,
    Modal,
    Box,
    Grid
} from '@mui/material';
import { useState, useEffect } from 'react';
import { addNewData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
interface LoaderProps {
    popupOpen: boolean,
    setPopupOpen: any,
    setLoading: any,
    setClientsChanged: any,
    client: any,
    clientConfiguration: any
}

const ClientEditorPopup = (props: LoaderProps) => {
    const { popupOpen, setPopupOpen, setLoading, setClientsChanged, client, clientConfiguration } = props;

    const [configId, setConfigId] = useState(0);
    const [companyDisplayName, setCompanyDisplayName] = useState('');
    const [surveyDay, setSurveyDay] = useState(0);
    const [weeksBetweenSurveys, setWeeksBetweenSurveys] = useState(0);
    const [numberOfDaysTillManagerEmail, setNumberOfDaysTillManagerEmail] = useState(0);
    const [numberOfDaysTillReminder, setNumberOfDaysTillReminder] = useState(0);
    const [numberOfSurveys, setNumberOfSurveys] = useState(0);
    const [configActive, setConfigActive] = useState(false);
    const [nextSurveyDate, setNextSurveyDate] = useState(new Date());
    const [disableButtons, setDisableButtons] = useState(false);

    const [clientId, setClientId] = useState(0);
    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [clientActive, setClientActive] = useState(false);

    const handleClosePopup = (event: any, reason: any) => {
        if (reason && reason === 'backdropClick')
            return;
        setPopupOpen(false);
    }

    useEffect(() => {
        loadControl();   
    }, [popupOpen])

    const loadControl = () => {
        setConfigId(clientConfiguration.id);
        setCompanyDisplayName(clientConfiguration.companyDisplayName);
        setSurveyDay(clientConfiguration.surveyDay);
        setWeeksBetweenSurveys(clientConfiguration.weeksBetweenSurveys);
        setNumberOfDaysTillManagerEmail(clientConfiguration.numberOfDaysTillManagerEmail);
        setNumberOfDaysTillReminder(clientConfiguration.numberOfDaysTillReminder);
        setNumberOfSurveys(clientConfiguration.numberOfSurveys);
        setConfigActive(clientConfiguration.active);
        setNextSurveyDate(clientConfiguration.nextSurveyDate);

        setClientId(client.id);
        setCompanyName(client.companyName);
        setAddress(client.address);
        setCity(client.city);
        setState(client.state);
        setZip(client.zip);
        setClientActive(client.active);
    }


    const CompanyDisplayNameChanged = (e: any) => {
        e.preventDefault();
        setCompanyDisplayName(e.target.value);
    }

    const SurveyDayChanged = (e: any) => {
        e.preventDefault();
        setSurveyDay(e.target.value);
    }

    const WeeksBetweenSurveysChanged = (e: any) => {
        e.preventDefault();
        setWeeksBetweenSurveys(e.target.value);
    }

    const NumberOfDaysTillManagerEmailChanged = (e: any) => {
        e.preventDefault();
        setNumberOfDaysTillManagerEmail(e.target.value);
    }

    const NumberOfDaysTillReminderChanged = (e: any) => {
        e.preventDefault();
        setNumberOfDaysTillReminder(e.target.value);
    }

    const NumberOfSurveysChanged = (e: any) => {
        e.preventDefault();
        setNumberOfSurveys(e.target.value);
    }

    const NextSurveyDateChanged = (e: any) => {
        setNextSurveyDate(e);
    }

    const CompanyNameChanged = (e: any) => {
        e.preventDefault();
        setCompanyName(e.target.value);
    }

    const AddressChanged = (e: any) => {
        e.preventDefault();
        setAddress(e.target.value);
    }

    const StateChanged = (e: any) => {
        e.preventDefault();
        setState(e.target.value);
    }

    const ZipChanged = (e: any) => {
        e.preventDefault();
        setZip(e.target.value);
    }

    const CityChanged = (e: any) => {
        e.preventDefault();
        setCity(e.target.value);
    }

    const getData = () => {
        setClientsChanged(true);
    };

    const closeAddAndSave = () => {
        setDisableButtons(true);

        let cliId = null;
        if (clientId > 0) cliId = clientId;

        let confId = null;
        if (configId > 0) confId = configId;

        const compDispName = companyDisplayName ? companyDisplayName : companyName;

        addNewData(
            {
                'client': {
                    'id': cliId,
                    'companyName': companyName,
                    'address': address,
                    'city': city,
                    'state': state,
                    'zip': zip,
                    'active': clientActive
                },
                'configuration': {
                    'id': confId,
                    'companyDisplayName': compDispName,
                    'surveyDay': surveyDay,
                    'weeksBetweenSurveys': weeksBetweenSurveys,
                    'numberOfDaysTillReminder': numberOfDaysTillReminder,
                    'numberOfDaysTillManagerEmail': numberOfDaysTillManagerEmail,
                    'nextSurveyDate': nextSurveyDate,
                    'numberOfSurveys': numberOfSurveys,
                    'active': configActive
                }
            },
            `${MASTER_ENDPOINT.AddOrUpdateClientData}`,
            {},
            true,
        )
            .then(() => {
                getData();
                setPopupOpen(false);
            })
            .catch(error => {
                alert('Error updating Company. Please try again later ' + error);
            })
            .finally(() => {
                setLoading(false);
                setDisableButtons(false);
            });
    }

    const closeAddNoSave = () => {
        setPopupOpen(false);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            <Modal
                className='profile-modal'
                open={popupOpen}
                onClose={handleClosePopup}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'>
                <Box sx={style}>
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                            {clientId > 0 ?
                                (<Typography id='modal-modal-title' className='modal-title'>
                                    Update Company
                                </Typography>) :
                                (
                                    <Typography id='modal-modal-title' className='modal-title'>
                                        Add New Company
                                    </Typography>)}
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                            <Typography className='client-labels'>
                                Company Name:
                            </Typography>
                        </Grid>
                        <Grid item md={9} sm={12} xs={12} >
                            <Typography className='client-details' component={'div'}>
                                <textarea onChange={CompanyNameChanged}
                                    className='textarea-noresize'
                                    value={companyName}
                                    placeholder='Company Name..'
                                    rows={1}
                                    cols={60}
                                    maxLength={255}
                                    required={true} />
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                            <Typography className='client-labels'>
                                Address:
                            </Typography>
                        </Grid>
                        <Grid item md={9} sm={12} xs={12} >
                            <Typography className='client-details' component={'div'}>
                                <textarea onChange={AddressChanged}
                                    className='textarea-noresize'
                                    placeholder='Address...'
                                    value={address}
                                    rows={1}
                                    cols={60}                                    
                                    maxLength={255}
                                    required={false} />
                                <textarea onChange={CityChanged}
                                    className='textarea-noresize'
                                    value={city}
                                    rows={1}
                                    cols={20}
                                    placeholder='City..'
                                    maxLength={100}
                                    required={false} style={{ marginRight: 2 }} /><br/>
                                <select value={state}
                                    onChange={StateChanged}
                                >
                                    <option value=""></option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District Of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                                <br/>
                                <textarea onChange={ZipChanged}
                                    className='textarea-noresize'
                                    placeholder='Zip...'                                     
                                    style={{ paddingBottom: 3 }}
                                    value={zip}
                                    rows={1}
                                    cols={8}
                                    maxLength={5}
                                    required={false} />
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography className='client-details' component={'div'}>
                                <input type='checkbox' name='ckClientActive'
                                    id='ckClientActive'
                                    checked={clientActive}
                                    onChange={() => setClientActive(!clientActive)}></input>
                                Company Active
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                            <Typography className='client-labels'>
                                Company Survey Display Name:
                            </Typography>
                        </Grid>
                        <Grid item md={9} sm={12} xs={12}>
                            <Typography className='client-details' component={'div'}>
                                <textarea onChange={CompanyDisplayNameChanged}
                                    className='textarea-noresize'
                                    value={companyDisplayName}
                                    placeholder='Company Survey Display Name...'
                                    rows={1}
                                    cols={60}
                                    required={true} />
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                            <Typography className='client-labels'>
                                Survey Day:
                            </Typography>
                        </Grid>
                        <Grid item md={9} sm={12} xs={12}>
                            <Typography className='client-details' component={'div'}>
                                <select onChange={SurveyDayChanged} value={surveyDay}>
                                    <option value='0'>Sunday</option>
                                    <option value='1'>Monday</option>
                                    <option value='2'>Tuesday</option>
                                    <option value='3'>Wednesday</option>
                                    <option value='4'>Thursday</option>
                                    <option value='5'>Friday</option>
                                    <option value='6'>Saturday</option>
                                </select>
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                            <Typography className='client-labels'>
                                Weeks Between Surveys:
                            </Typography>
                        </Grid>
                        <Grid item md={9} sm={12} xs={12}>
                            <Typography className='client-details' component={'div'}>
                                <input onChange={WeeksBetweenSurveysChanged}
                                    className='textarea-noresize'
                                    value={weeksBetweenSurveys}
                                    type='number'                                    
                                    required={true} />
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                            <Typography className='client-labels'>
                                Days to wait before sending reminders:
                            </Typography>
                        </Grid>
                        <Grid item md={9} sm={12} xs={12}>
                            <Typography className='client-details' component={'div'}>
                                <input onChange={NumberOfDaysTillReminderChanged}
                                    value={numberOfDaysTillReminder}
                                    className='textarea-noresize'
                                    type='number'
                                    required={true} />
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                            <Typography className='client-labels'>
                                Days to wait before sending manager email:
                            </Typography>
                        </Grid>
                        <Grid item md={9} sm={12} xs={12}>
                            <Typography className='client-details' component={'div'}>
                                <input onChange={NumberOfDaysTillManagerEmailChanged}
                                    value={numberOfDaysTillManagerEmail}
                                    className='textarea-noresize'
                                    type='number'
                                    required={true} />
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                            <Typography className='client-labels'>
                                Next Survey Date:
                            </Typography>
                        </Grid>
                        <Grid item md={9} sm={12} xs={12}>
                            <Typography component={'div'} className='client-details'>
                                <DatePicker
                                    wrapperClassName="react-datepicker"
                                    showDateSelect
                                    selected={nextSurveyDate}
                                    onChange={NextSurveyDateChanged}
                                />
                            </Typography>
                        </Grid>

                        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                            <Typography className='client-labels'>
                                Number of Surveys (-1 indicates unlimited):
                            </Typography>
                        </Grid>
                        <Grid item md={9} sm={12} xs={12}>
                            <Typography className='client-details' component={'div'}>
                                <input onChange={NumberOfSurveysChanged}
                                    value={numberOfSurveys}
                                    className='textarea-noresize'
                                    type='number'
                                    required={true} />
                            </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2 }}>

                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography className='client-details' component={'div'}>
                                <input type='checkbox' name='ckConfigActive'
                                    id='ckConfigActive'
                                    checked={configActive}
                                    onChange={() => setConfigActive(!configActive)}></input>
                                Company Configuration Active
                            </Typography>
                        </Grid>
                        <Typography id='modal-modal-description' component={'div'}>
                            <button disabled={disableButtons} className='modal-yes1-btn' style={{ width: 100 }} onClick={closeAddAndSave}>OK</button>
                            <button disabled={disableButtons} className='modal-yes-btn' style={{ marginLeft: 10, width: 100 }} onClick={closeAddNoSave}>Cancel</button>
                        </Typography>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

export default ClientEditorPopup;