import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import { addNewData, updateData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import moment from 'moment';
import { useAppSelector } from 'store/hooks';
import { useDispatch } from 'react-redux';
import { USER_REFRESH_TABLE } from 'store/types/UserTypes';
import { logout } from 'store/actions/UserAction';

interface PropsData {
  visible: boolean;
  handleClose: () => void;
  editObject?: any;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  minHeight: '168px',
  width: '414px',
  borderRadius: ' 8px',
  boxShadow: ' 0 5px 14px 0 rgba(0,0,0,0.22)',
  padding: '28px 30px',
};

const ActiveAndDeActive = (props: PropsData) => {
  const dispatch: any = useDispatch();
  const { visible, handleClose, editObject } = props;
  const userDetail: any = useAppSelector((store: any) => {
    return store.userLogin.userInfo;
  });

  const closeEvent = () => {
    handleClose();
  };

  const submitForm = () => {
    if (editObject?.isActive) {
      const postData = {
        ...editObject,
        isActive: false,
        updatedBy: userDetail.Id,
        updatedOn: moment(),
      };
      updateData('', postData, `${MASTER_ENDPOINT.UpdateSuperAdmin}`, true).then(() => {
        dispatch({
          type: USER_REFRESH_TABLE,
        });
        closeEvent();
        if (Number(userDetail.Id) === Number(editObject.id)) {
          logout(dispatch);
        }
      });
    } else {
      addNewData(
        {},
        `${MASTER_ENDPOINT.Login}?toEmailAddress=${editObject['username']}`,
        {},
        true,
      ).then(() => {
        dispatch({
          type: USER_REFRESH_TABLE,
        });
        closeEvent();
      });
    }
  };

  return (
    <Modal
      open={visible}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box component='div' sx={style}>
        <Typography className='d-flex-a flex-sb'>
          <Typography className='modal-title'>
            {editObject?.isActive ? 'DEACTIVATE USER' : 'Invite USER'}
          </Typography>
        </Typography>
        <Typography className='modal-dis'>
          {editObject?.isActive
            ? 'Are you sure you want to deactivate this user?'
            : 'Are you sure you want to Invite this user?'}
        </Typography>
        <Typography component={'div'} className='d-flex-a pt-15' columnGap='5px'>
          <Button className='modal-no-btn' onClick={handleClose}>
            NO, CANCEL
          </Button>
          <Button
            className={editObject?.isActive ? 'modal-yes-btn' : 'modal-yes1-btn'}
            onClick={submitForm}>
            {editObject?.isActive ? 'YES, DEACTIVATE' : 'YES, Invite'}
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
};

export default ActiveAndDeActive;
