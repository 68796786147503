/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormHelperText } from '@mui/material';
import _ from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
// import Select from 'react-dropdown-select';
import Select, { components, NoticeProps } from 'react-select';

type InputProps = {
  placeHolder?: string;
  className?: string;
  onChange?: (src: any) => void;
  extraStyle?: any;
  value?: any;
  error?: any;
  options?: any;
  labelKey?: string;
  valueKey?: string;
  id?: string;
  name?: string;
  ref?: any;
  disabled?: boolean;
  selectedValue?: string;
  autoFocus?: boolean;
  searchable?: boolean;
  onClose?: any;
  tabIndex?: number;
  onFocus?: (src: any) => void;
  sorting?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  isDefault?: boolean;
};

const NoOptionsMessage = (props: NoticeProps) => {
  return (
    <components.NoOptionsMessage {...props}>
      <p>No Options Available</p>
    </components.NoOptionsMessage>
  );
};

const CustomSelect = (props: InputProps): ReactElement => {
  const {
    placeHolder,
    onChange,
    value = '',
    error,
    options,
    labelKey = 'label',
    valueKey = 'value',
    id,
    name,
    ref,
    disabled,
    autoFocus = false,
    searchable = true,
    isClearable = true,
    onFocus,
    tabIndex,
    sorting,
    isMulti = false,
    closeMenuOnSelect = true,
    isDefault = false,
  } = props;
  const [option, setOption] = useState([]);

  useEffect(() => {
    setOption(
      sorting
        ? options
            .filter((ch: any) => !_.isNull(ch[valueKey]) || !_.isUndefined(ch[valueKey]))
            .map((e: any) => ({
              ...e,
              label: e[labelKey]
                ? `${String(e[labelKey])}${isDefault && e?.isDefault ? ' (Default Role)' : ''}`
                : '',
              [labelKey]: e[labelKey]
                ? `${String(e[labelKey])}${isDefault && e?.isDefault ? ' (Default Role)' : ''}`
                : '',
              value: e[valueKey],
            }))
            .sort((a: any, b: any) =>
              String(a[labelKey]).toLowerCase() > String(b[labelKey]).toLowerCase() ? 1 : -1,
            )
        : options
            .filter((ch: any) => !_.isNull(ch[valueKey]) || !_.isUndefined(ch[valueKey]))
            .map((e: any) => ({
              ...e,
              label: e[labelKey]
                ? `${String(e[labelKey])}${isDefault && e?.isDefault ? ' (Default Role)' : ''}`
                : '',
              [labelKey]: e[labelKey]
                ? `${String(e[labelKey])}${isDefault && e?.isDefault ? ' (Default Role)' : ''}`
                : '',
              value: e[valueKey],
            })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);
  return (
    <>
      <Select
        classNamePrefix={disabled ? 'en-selectbox-disabled' : ''}
        autoFocus={autoFocus}
        closeMenuOnSelect={closeMenuOnSelect}
        isClearable={isClearable}
        isSearchable={searchable}
        id={id}
        components={{
          IndicatorSeparator: () => null && NoOptionsMessage,
        }}
        ref={ref}
        isMulti={isMulti}
        onFocus={onFocus && onFocus}
        styles={customStyles}
        isDisabled={disabled}
        name={name}
        tabIndex={tabIndex}
        placeholder={placeHolder}
        {...(value !== '' || isMulti
          ? {
              value: isMulti
                ? value && Array.isArray(value) && value.length
                  ? option.filter((el) => value.includes(el[valueKey]))
                  : null
                : option &&
                    option.length &&
                    value &&
                    option.find((el) => String(el[valueKey]) === String(value))
                  ? option.find((el) => String(el[valueKey]) === String(value))
                  : null,
            }
          : {})}
        options={!_.isEmpty(option) && option.length ? option : []}
        onChange={(e: any) => {
          if (isMulti) {
            onChange &&
              onChange(
                e
                  ? {
                      target: {
                        value: e && Array.isArray(e) && e.length ? e.map((e) => e[valueKey]) : null,
                      },
                    }
                  : { target: { value: '' } },
              );
            return;
          }
          onChange && onChange(e ? { target: { value: e['value'] } } : { target: { value: '' } });
        }}
      />
      {error && (
        <FormHelperText
          style={{
            color: 'red !important',
            marginLeft: '0px',
            fontSize: '20px !important',
            fontFamily: 'TwCenMTStd-Regular',
          }}>
          {error}
        </FormHelperText>
      )}
    </>
  );
};
const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: '#F8F8FA',
    borderRadius: '6px !important',
    boxShadow: 'none !important',
    fontSize: '17px !important',
    fontFamily: 'TwCenMTStd-Regular',
    minHeight: '35px !important',
    border: '0px solid #D0D5DD',
    '&:hover': {
      boxShadow: 'none !important',
      outline: 'none !important',
    },
  }),
  indicatorContainer: (provided: any, state: any) => ({
    padding: '6px !important',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: '10000',
    fontSize: '14px !important',
    fontFamily: 'TwCenMTStd-Regular',
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    maxHeight: '110px !important',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    maxWidth: '100%',
    overflow: 'hidden',
    color: '#344051',
    fontSize: '16px',
    fontFamily: 'TwCenMTStd-Regular',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px !important',
    fontFamily: 'TwCenMTStd-Regular',
    cursor: 'pointer',
    background: state.isSelected ? '#3441D2' : '#F8F8FA',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: '17px !important',
    fontFamily: 'TwCenMTStd-Regular',
  }),
};
export default CustomSelect;
