import React from 'react';
import { ColorRing } from 'react-loader-spinner';

interface LoaderProps {
  loading: boolean;
}

const ScreenLoader = (props: LoaderProps) => {
  const { loading } = props;
  return (
    <ColorRing
      visible={loading}
      height='60'
      width='60'
      ariaLabel='vortex-loading'
      wrapperStyle={{}}
      wrapperClass='vortex-wrapper'
      colors={['#C3D34F', '#C3D34F', '#C3D34F', '#C3D34F', '#C3D34F']}
    />
  );
};

export default ScreenLoader;
