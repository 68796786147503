import React from 'react';
import { Navigate, Route, Routes, To, useNavigate } from 'react-router-dom';
import { PrivateRoutes as PrivateRoutesDirectives, PublicRoutes } from './routeConstans';
import Dashboard from 'pages/private/dashboard';
import PrivateLayout from 'pages/private';
import Oraganization from 'pages/private/organization';
import EmployeeEgage from 'pages/private/organizationalstructure';
import EmployeeEgageList from 'pages/private/organizationalstructure/list';
import EmployeeEngagementSummary from 'pages/private/employeeengagment';
import FilterDataContextProvider from 'context/FilterDataContextProvider';
import Performance from 'pages/private/performance';
import Accessmanagement from 'pages/private/accessmanagement';
import Recruiting from 'pages/private/recruiting';
import Demographics from 'pages/private/demographics';
import LearnDevlopment from 'pages/private/learndevelopment';
import Reports from 'pages/private/reports';
import Settings from 'pages/private/settings';
import RolesandPermissions from 'pages/private/accessmanagement/rolesandpermissions';
import { logout } from 'store/actions/UserAction';
import { toast } from 'react-toastify';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { alertOptions } from 'utils/Config';
import { useAppSelector } from 'store/hooks';
import EmployeeSearchContextProvider from 'context/EmployeeSearchContextProvider';

const PrivateRoutes = () => {
  const dispatch: any = useDispatch();
  const {
    DASHBOARD,
    ORGANIZATION,
    ORGANIZATIONALSTRUCTURE,
    ORGANIZATIONALSTRUCTURELIST,
    EMPLOYEEENGAGEMENT,
    PERFORMANCE,
    ACCESSMANAGEMENT,
    RECRUITING,
    SETTINGS,
    DEMOGRAPHICS,
    REPORTS,
    LEARNDEVLOPMENT,
  } = PrivateRoutesDirectives;

  const { 
    userInfo: userDetail, 
    menuList: screenList,
    }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });

  const [showDashboard, setshowDashboard] = React.useState(true);
  const [showOrganization, setshowOrganization] = React.useState(true);
  const [showOrginazitionalStructure, setshowOrginazitionalStructure] = React.useState(true);
  const [showEmployeeEngagement, setshowEmployeeEngagement] = React.useState(true);
  const [showRecruiting, setshowRecruiting] = React.useState(true);
  const [showPerformance, setshowPerformance] = React.useState(true);
  const [showDEI, setshowDEI] = React.useState(true);
  const [showLearning, setshowLearning] = React.useState(true);
  const [showReports, setshowReports] = React.useState(true);
  const [showAccessManagement, setshowAccessManagement] = React.useState(true);

  React.useEffect( () =>
  {
    if(screenList?.length > 0){
      setshowDashboard(screenList.findIndex((entry: any) => entry.screenId === 1 && entry.isAccessible === true ) !== -1);
      setshowOrganization(screenList.findIndex((entry: any) => entry.screenId === 2 && entry.isAccessible === true ) !== -1);
      setshowOrginazitionalStructure(screenList.findIndex((entry: any) => entry.screenId === 3 && entry.isAccessible === true ) !== -1);    
      setshowEmployeeEngagement(screenList.findIndex((entry: any) => entry.screenId === 35 && entry.isAccessible === true ) !== -1);
      setshowRecruiting(screenList.findIndex((entry: any) => entry.screenId === 4 && entry.isAccessible === true ) !== -1);
      setshowPerformance(screenList.findIndex((entry: any) => entry.screenId === 5 && entry.isAccessible === true ) !== -1);
      setshowDEI(screenList.findIndex((entry: any) => entry.screenId === 6 && entry.isAccessible === true ) !== -1); 
      setshowLearning(screenList.findIndex((entry: any) => entry.screenId === 7 && entry.isAccessible === true ) !== -1); 
      setshowReports(screenList.findIndex((entry: any) => entry.moduleId === 2 && entry.isAccessible === true ) !== -1); 
      setshowAccessManagement(screenList.findIndex((entry: any) => entry.screenId === 8 && entry.isAccessible === true ) !== -1); 
    }
  }, [screenList])
  
  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const handleIdle = () => {
    logout(dispatch);
    if (userDetail?.isImpersonate) {
      setTimeout(() => gotoNavigate(PublicRoutes.ADMINLOGIN), 0);
    }
    else{
      setTimeout(() => gotoNavigate(PublicRoutes.CLIENTLOGIN), 0);
    }
    toast.error('Session expired! Please login again.', alertOptions);
  };
  useIdleTimer({
    timeout: (45 * 60 * 1000),
    onIdle: handleIdle,
    debounce: 500,
  });
  return (
    <Routes>      
      <Route path='/' element={<Navigate to={DASHBOARD} replace />} />
      { showDashboard && (<Route
        path={DASHBOARD}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={DASHBOARD} title={'DashBoard'} header={true}>
              <Dashboard />
            </PrivateLayout>
          </FilterDataContextProvider> }       
      />)}
      { showOrganization && (<Route
        path={ORGANIZATION}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={ORGANIZATION} title={'Organizational Overview'} header={true}>
              <Oraganization />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />)}
      { showOrginazitionalStructure && (<Route
        path={ORGANIZATIONALSTRUCTURE}
        element={
          <FilterDataContextProvider>
            <EmployeeSearchContextProvider>
              <PrivateLayout path={ORGANIZATIONALSTRUCTURE} title={'Organizational Structure'} header={true}>
                <EmployeeEgage />
              </PrivateLayout>
            </EmployeeSearchContextProvider>
          </FilterDataContextProvider>
        }
      />)}
      { showOrginazitionalStructure && (<Route
        path={ORGANIZATIONALSTRUCTURELIST}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={ORGANIZATIONALSTRUCTURELIST} title={'Organizational Structure'} header={true}>
              <EmployeeEgageList />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />)}
      { showEmployeeEngagement && (<Route
        path={EMPLOYEEENGAGEMENT}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={EMPLOYEEENGAGEMENT} title={'Employee Optimization'} header={true}>
              <EmployeeEngagementSummary />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />)}
      { showRecruiting && (<Route
        path={RECRUITING}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={RECRUITING} title={'Recruiting'} header={true}>
              <Recruiting />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />)}
      { showPerformance && (<Route
        path={PERFORMANCE}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={PERFORMANCE} title={'Performance & Comp'} header={true}>
              <Performance />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />  )}    
      { showDEI && (<Route
        path={DEMOGRAPHICS}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={DEMOGRAPHICS} title={'Demographics'} header={true}>
              <Demographics />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />)}
      <Route
        path={SETTINGS}
        element={
          <PrivateLayout path={SETTINGS} header={false}>
            <Settings />
          </PrivateLayout>
        }
      />
      { showLearning && (<Route
        path={LEARNDEVLOPMENT}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={LEARNDEVLOPMENT} title={'Learning & Development'} header={true}>
              <LearnDevlopment />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />)}
      { showReports && (<Route
        path={REPORTS}
        element={
          <FilterDataContextProvider>
            <PrivateLayout path={REPORTS} title={'Reports'} header={true}>
              <Reports />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />)}
      { showAccessManagement && (<Route
        path={ACCESSMANAGEMENT.EMPLOYEES}
        element={
          <FilterDataContextProvider>
            <PrivateLayout
              path={ACCESSMANAGEMENT.EMPLOYEES}
              title={'Access Management'}
              header={true}>
              <Accessmanagement />
            </PrivateLayout>
          </FilterDataContextProvider>
        }
      />)}
      { showAccessManagement && (<Route
        path={ACCESSMANAGEMENT.ROLESPERMISSIONS}
        element={
          <PrivateLayout
            path={ACCESSMANAGEMENT.ROLESPERMISSIONS}
            title={'Access Management'}
            header={true}>
            <RolesandPermissions />
          </PrivateLayout>
        }
      />)}
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default PrivateRoutes;
