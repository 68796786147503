import axios from 'axios';
// import { useCustomNavigation } from 'hooks';
import { toast } from 'react-toastify';
import { store } from 'store';
import { logout } from 'store/actions/UserAction';
import { MASTER_ENDPOINT } from 'utils/Constant';
import Storage from 'utils/Storage';

const { dispatch }: any = store;

const instance = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  async (config: any) => {
    const TokenStatus: any = await Storage.getTokens();
    if (TokenStatus?.token) {
      const token = TokenStatus && TokenStatus.token ? TokenStatus.token : '';
      if (token && !config.url.includes('RefreshToken')) {
        config.headers['Authorization'] = 'Bearer '.concat(token);
      }
    }
    return config;

  },
  (error: any) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res: any) => {
    return res;
  },
  error => {
    if (error.response) {
      const originalConfig = error.config;
      const responseData = error.response?.data;

      if (originalConfig.url !== 'login' && error.response) {

        // Access Token was expired
        if (error.response?.status === 401) {
          originalConfig._retry = true;
          try {
            // setTimeout(async () => {
            const TokenStatus: any = Storage.getTokens();
            if (TokenStatus?.token) {
              // await Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
              instance.post(
                `${MASTER_ENDPOINT.RefreshToken}?token=${TokenStatus && TokenStatus.token ? TokenStatus.token : ''
                }`,
                {},
                { headers: { Authorization: '' } },
              )
                .then((rs: any) => {
                  if (rs?.data) {
                    const { token } = rs.data;
                    Storage.updateAccessToken(token);
                    return instance(originalConfig);
                  }
                  else {
                    logout(dispatch);                    
                    // useCustomNavigation('/login', { replace: true });
                  }
                })
                .catch(() => {
                  logout(dispatch);
                  // useCustomNavigation('/login', { replace: true });
                })
            }
            else {
              logout(dispatch);
              // useCustomNavigation('/login', { replace: true });
            }
          }
          catch {
            logout(dispatch);
            // useCustomNavigation('/login', { replace: true });
          }
        }
        else if (error.response?.status === 403) {
          toast.error('Your account has been deactivated. Please contact your Administrator.');
          logout(dispatch);
          // useCustomNavigation('/login', { replace: true });
        }
        if (responseData.error?.message && responseData.error?.message === 'Invalid Token!') {
          logout(dispatch);
          // useCustomNavigation('/login', { replace: true });
        }
      }
    }
    else {
      logout(dispatch);
      // useCustomNavigation('/login', { replace: true });
    }

    return Promise.reject(error);
  }
);
export default instance;
