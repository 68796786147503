import { Grid, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React, { useEffect, useContext } from 'react';
import { getAllListData } from 'services/CommonServices';
import { EE_DASHBOARD, MASTER_ENDPOINT } from 'utils/Constant';
import PageLoader from 'components/PageLoader';
import _ from 'lodash';
import EmployeeDetailPopOut from 'components/EmployeeDetailPopOut';
import { FilterDataContext } from 'context/FilterDataContextProvider';

const statickParam: any = {
  sortBy: '',
  order: '',
  heading: '',
};

const EmployeeEngagementList = () => {
  const [loader, setLoader] = React.useState<boolean>(true);
  const [param, setParam] = React.useState<{
    sortBy: string;
    order: 'asc' | 'desc';
    heading: string;
  }>(statickParam);
  const [autonomyEmployeeList, setAutonomyEmployeeList] = React.useState<any>([]);
  const [meaningEmployeeList, setMeaningEmployeeList] = React.useState<any>([]);
  const [growthEmployeeList, setGrowthEmployeeList] = React.useState<any>([]);
  const [impactEmployeeList, setImpactEmployeeList] = React.useState<any>([]);
  const [capacityEmployeeList, setCapacityEmployeeList] = React.useState<any>([]);
  const [connectionEmployeeList, setConnectionEmployeeList] = React.useState<any>([]);
  const [drawer, setDrawer] = React.useState(false);
  const [details, setDetails] = React.useState<any>([]);
  const [moreDetails, setMoreDetails] = React.useState<any>([]);
  const [viewMoreDetails, setViewMoreDetails] = React.useState<any>({}); 
  const [loader1, setLoader1] = React.useState<boolean>(false);
  const [currentEmployeeID, setCurrentEmployeeId] = React.useState<any>(0);
  const wireUpClick = false;

  const {filterData} = useContext(FilterDataContext);

  const EmployeeList = () => {
    setLoader(true);
    getAllListData(`${EE_DASHBOARD.OrgList}`)
      .then((res) => {
        if (res?.data && res?.data?.length) {
          res?.data.filter((e: any) => {
            if (e.value === 'Autonomy') {
              const employeeList = e?.employeeList;
              setAutonomyEmployeeList(employeeList);
            } else if (e.value === 'Meaning') {
              const employeeList = e?.employeeList;
              setMeaningEmployeeList(employeeList);
            } else if (e.value === 'Growth') {
              const employeeList = e?.employeeList;
              setGrowthEmployeeList(employeeList);
            } else if (e.value === 'Impact') {
              const employeeList = e?.employeeList;
              setImpactEmployeeList(employeeList);
            } else if (e.value === 'Connection') {
              const employeeList = e?.employeeList;
              setConnectionEmployeeList(employeeList);
            } else if (e.value === 'Capacity') {
              const employeeList = e?.employeeList;
              setCapacityEmployeeList(employeeList);
            }
          });
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    EmployeeList();
    setViewMoreDetails({});
  }, [filterData]);

  useEffect(() =>{
    if(!drawer){
      setCurrentEmployeeId(0);
    }
  },[drawer])

  useEffect(() => {
    if(wireUpClick){
      const elements = document.querySelectorAll('.ename');
    
      elements.forEach(element => {
        element.addEventListener('click', employeeClick);
      }); 
    }
  }, [EmployeeList]); 

  const employeeClick = async (event: any) => {    
    const employeeId = event?.target?.value;

    if (employeeId != currentEmployeeID) {
      setCurrentEmployeeId(employeeId);
      setLoader(true); 
      setLoader1(true);  
      setDrawer(true); 
      const url = `${EE_DASHBOARD.ViewMore}/${employeeId}`;
      setLoader1(true);
      await getAllListData(url)
        .then((res) => {
          setDetails(res?.data);
        });

      const UrlM = `${EE_DASHBOARD.ViewMoreMagic}/${employeeId}`;
      await getAllListData(UrlM).then((res2) => {
        setMoreDetails(res2?.data);           
      }) 
      .finally(() => {
        setLoader(false); 
        setLoader1(false);           
       });                                 
    }
  }
  
  const changeParam = (keyName: string, Heading: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        order:
          param.heading === Heading && param.sortBy === keyName && param.order === 'asc'
            ? 'desc'
            : 'asc',
        heading: Heading,
      };
    });
    setMeaningEmployeeList([
      ..._.orderBy(
        meaningEmployeeList,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'number'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };
  const changeParamAutonomy = (keyName: string, Heading: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        heading: Heading,
        order: param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setAutonomyEmployeeList([
      ..._.orderBy(
        autonomyEmployeeList,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'number'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };

  const changeParamGrowth = (keyName: string, Heading: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        heading: Heading,
        order: param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setGrowthEmployeeList([
      ..._.orderBy(
        growthEmployeeList,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'number'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };

  const changeParamImpact = (keyName: string, Heading: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        heading: Heading,
        order: param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setImpactEmployeeList([
      ..._.orderBy(
        impactEmployeeList,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'number'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };

  const changeParamConnection = (keyName: string, Heading: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        heading: Heading,
        order: param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setConnectionEmployeeList([
      ..._.orderBy(
        connectionEmployeeList,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'number'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };

  const changeParamCapacity = (keyName: string, Heading: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        heading: Heading,
        order: param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setCapacityEmployeeList([
      ..._.orderBy(
        capacityEmployeeList,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'number'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };


  return (
    <Typography component={'div'} className='employee-table'>
      <PageLoader loading={loader} />
      <Typography component={'div'} className='d-flex-ja flex-column'>
          <EmployeeDetailPopOut loader1={loader1} 
                        setLoader1={setLoader1} 
                        drawer={drawer} 
                        details={details} 
                        viewMoreDetails={viewMoreDetails} 
                        moreDetails={moreDetails} 
                        setDrawer={setDrawer} 
                        setDetails={setDetails} 
                        setMoreDetails={setMoreDetails} ></EmployeeDetailPopOut>         
      </Typography>
      <Grid container>
        <Grid item md={2.0} sm={12} xs={12}>
          <Typography
            component={'div'}
            className='d-flex-a flex-sb h-69 border-right border-bottom'>
            <Typography className='th-title'>Purpose</Typography>
            <Typography
              className='arrow-box'
              onClick={() => {
                // meaning changed has purpose
                changeParam('score', 'meaning');
              }}>
              <ArrowDropDownIcon
                className='down'
                style={{
                  fill:
                    param.heading === 'meaning' &&
                    param.sortBy === 'score' &&
                    param.order === 'desc'
                      ? 'black'
                      : '',
                }}
              />
              <ArrowDropUpIcon
                className='up'
                style={{
                  fill:
                    param.heading === 'meaning' && param.sortBy === 'score' && param.order === 'asc'
                      ? 'black'
                      : '',
                }}
              />
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.0} sm={12} xs={12}>
          <Typography
            component={'div'}
            className='d-flex-a flex-sb h-69 border-right border-bottom'>
            <Typography className='th-title'>Independence</Typography>
            <Typography
              className='arrow-box'
              onClick={() => {
                // Autonomy has changed Independence
                changeParamAutonomy('score', 'Autonomy');
              }}>
              <ArrowDropDownIcon
                className='down'
                style={{
                  fill:
                    param.heading === 'Autonomy' &&
                    param.sortBy === 'score' &&
                    param.order === 'desc'
                      ? 'black'
                      : '',
                }}
              />
              <ArrowDropUpIcon
                className='up'
                style={{
                  fill:
                    param.heading === 'Autonomy' &&
                    param.sortBy === 'score' &&
                    param.order === 'asc'
                      ? 'black'
                      : '',
                }}
              />
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.0} sm={12} xs={12}>
          <Typography
            component={'div'}
            className='d-flex-a flex-sb h-69 border-right border-bottom'>
            <Typography className='th-title'>Growth</Typography>
            <Typography
              className='arrow-box'
              onClick={() => {
                changeParamGrowth('score', 'Growth');
              }}>
              <ArrowDropDownIcon
                className='down'
                style={{
                  fill:
                    param.heading === 'Growth' && param.sortBy === 'score' && param.order === 'desc'
                      ? 'black'
                      : '',
                }}
              />
              <ArrowDropUpIcon
                className='up'
                style={{
                  fill:
                    param.heading === 'Growth' && param.sortBy === 'score' && param.order === 'asc'
                      ? 'black'
                      : '',
                }}
              />
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.0} sm={12} xs={12}>
          <Typography
            component={'div'}
            className='d-flex-a flex-sb h-69 border-right border-bottom'>
            <Typography className='th-title'>Impact</Typography>
            <Typography
              className='arrow-box'
              onClick={() => {
                changeParamImpact('score', 'Impact');
              }}>
              <ArrowDropDownIcon
                className='down'
                style={{
                  fill:
                    param.heading === 'Impact' && param.sortBy === 'score' && param.order === 'desc'
                      ? 'black'
                      : '',
                }}
              />
              <ArrowDropUpIcon
                className='up'
                style={{
                  fill:
                    param.heading === 'Impact' && param.sortBy === 'score' && param.order === 'asc'
                      ? 'black'
                      : '',
                }}
              />
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.0} sm={12} xs={12}>
          <Typography component={'div'} className='d-flex-a flex-sb h-69 border-bottom'>
            <Typography className='th-title'>Relationship</Typography>
            <Typography
              className='arrow-box'
              onClick={() => {
                // Connection has changed Relationship
                changeParamConnection('score', 'Connection');
              }}>
              <ArrowDropDownIcon
                className='down'
                style={{
                  fill:
                    param.heading === 'Connection' &&
                    param.sortBy === 'score' &&
                    param.order === 'desc'
                      ? 'black'
                      : '',
                }}
              />
              <ArrowDropUpIcon
                className='up'
                style={{
                  fill:
                    param.heading === 'Connection' &&
                    param.sortBy === 'score' &&
                    param.order === 'asc'
                      ? 'black'
                      : '',
                }}
              />
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.0} sm={12} xs={12}>
          <Typography component={'div'} className='d-flex-a flex-sb h-69 border-bottom'>
            <Typography className='th-title'>Capacity</Typography>
            <Typography
              className='arrow-box'
              onClick={() => {
                // Connection has changed Relationship
                changeParamCapacity('score', 'Capacity');
              }}>
              <ArrowDropDownIcon
                className='down'
                style={{
                  fill:
                    param.heading === 'Capacity' &&
                    param.sortBy === 'score' &&
                    param.order === 'desc'
                      ? 'black'
                      : '',
                }}
              />
              <ArrowDropUpIcon
                className='up'
                style={{
                  fill:
                    param.heading === 'Capacity' &&
                    param.sortBy === 'score' &&
                    param.order === 'asc'
                      ? 'black'
                      : '',
                }}
              />
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid container className='body-content'>
        {!autonomyEmployeeList.length &&
        !meaningEmployeeList.length &&
        !growthEmployeeList.length &&
        !impactEmployeeList.length &&
        !connectionEmployeeList.length  &&
        !capacityEmployeeList.length ? (
          <Grid item md={12} sm={12} xs={12}>
            <Typography component={'div'} className='d-flex-ja norecord'>
              No Records Found
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item md={2.0} sm={12} xs={12}>
              <Grid container>
                {meaningEmployeeList.map((row: any, i: number) => {
                  return (
                    <Grid md={12} sm={12} xs={12} key={i} item>
                      <Typography
                        component={'div'}
                        className={`${
                          row?.status === 'Needs Attention'
                            ? 'normal-box red-bg'
                            : row?.status === 'Good Standing'
                              ? 'normal-box blue-bg'
                              : row?.status === 'On the Move'
                                ? 'normal-box green-bg'
                                : 'normal-box'
                        }`}
                        // 'normal-box red-bg'
                      >
                        <Typography
                          className={`${
                            row?.status === 'Needs Attention'
                              ? 'redbar'
                              : row?.status === 'Good Standing'
                                ? 'bluebar'
                                : row?.status === 'On the Move'
                                  ? 'greenbar'
                                  : ''
                          }`}
                          // className='redbar'
                        />
                        <Typography className='count d-flex-ja'>{i + 1}</Typography>
                        <Typography component={'div'} className='d-flex-ja'>
                          {row?.userImageId ? (
                            <img
                              className='d-avtaor d-flex-ja'
                              src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${row?.userImageId}`}
                            />
                          ) : (
                            <Typography className='d-avtaor d-flex-ja'>{`${row?.name?.charAt(
                              0,
                            )}`}</Typography>
                          )}
                        </Typography>
                        <Typography component={'div'} className='ml-8 d-flex-a flex-1'>
                          <Typography component={'div'} className='pr-8' sx={{ width: '80%' }}>
                          <Typography className='ename'><data value={row?.employeeId}>{row?.name}</data></Typography>
                          <Typography className='edep'>{row?.description}</Typography>
                          </Typography>
                          <Typography className='eval text-right flex-1'>{row.score}</Typography>
                        </Typography>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item md={2.0} sm={12} xs={12}>
              <Grid container>
                {autonomyEmployeeList.map((row: any, i: number) => {
                  return (
                    <Grid md={12} sm={12} xs={12} key={i} item>
                      <Typography
                        component={'div'}
                        className={`${
                          row?.status === 'Needs Attention'
                            ? 'normal-box red-bg'
                            : row?.status === 'Good Standing'
                              ? 'normal-box blue-bg'
                              : row?.status === 'On the Move'
                                ? 'normal-box green-bg'
                                : 'normal-box'
                        }`}>
                        <Typography
                          className={`${
                            row?.status === 'Needs Attention'
                              ? 'redbar'
                              : row?.status === 'Good Standing'
                                ? 'bluebar'
                                : row?.status === 'On the Move'
                                  ? 'greenbar'
                                  : ''
                          }`}
                          // className='redbar'
                        />
                        <Typography className='count d-flex-ja'>{i + 1}</Typography>
                        <Typography component={'div'} className='d-flex-ja'>
                          {row?.userImageId ? (
                            <img
                              className='d-avtaor d-flex-ja'
                              src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${row?.userImageId}`}
                            />
                          ) : (
                            <Typography className='d-avtaor d-flex-ja'>{`${row?.name?.charAt(
                              0,
                            )}`}</Typography>
                          )}
                        </Typography>
                        <Typography component={'div'} className='ml-8 d-flex-a flex-1'>
                          <Typography component={'div'} className='pr-8' sx={{ width: '80%' }}>
                          <Typography className='ename'><data value={row?.employeeId}>{row?.name}</data></Typography>
                            <Typography className='edep'>{row?.description}</Typography>
                          </Typography>
                          <Typography className='eval text-right flex-1'>{row.score}</Typography>
                        </Typography>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item md={2.0} sm={12} xs={12}>
              <Grid container>
                {growthEmployeeList.map((row: any, i: number) => {
                  return (
                    <Grid md={12} sm={12} xs={12} key={i} item>
                      <Typography
                        component={'div'}
                        className={`${
                          row?.status === 'Needs Attention'
                            ? 'normal-box red-bg'
                            : row?.status === 'Good Standing'
                              ? 'normal-box blue-bg'
                              : row?.status === 'On the Move'
                                ? 'normal-box green-bg'
                                : 'normal-box'
                        }`}>
                        <Typography
                          className={`${
                            row?.status === 'Needs Attention'
                              ? 'redbar'
                              : row?.status === 'Good Standing'
                                ? 'bluebar'
                                : row?.status === 'On the Move'
                                  ? 'greenbar'
                                  : ''
                          }`}
                          // className='redbar'
                        />
                        <Typography className='count d-flex-ja'>{i + 1}</Typography>
                        <Typography component={'div'} className='d-flex-ja'>
                          {row?.userImageId ? (
                            <img
                              className='d-avtaor d-flex-ja'
                              src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${row?.userImageId}`}
                            />
                          ) : (
                            <Typography className='d-avtaor d-flex-ja'>{`${row?.name?.charAt(
                              0,
                            )}`}</Typography>
                          )}
                        </Typography>
                        <Typography component={'div'} className='ml-8 d-flex-a flex-1 '>
                          <Typography component={'div'} className='pr-8' sx={{ width: '80%' }}>
                          <Typography className='ename'><data value={row?.employeeId}>{row?.name}</data></Typography>
                            <Typography className='edep'>{row?.description}</Typography>
                          </Typography>
                          <Typography className='eval text-right flex-1'>{row.score}</Typography>
                        </Typography>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item md={2.0} sm={12} xs={12}>
              <Grid container>
                {impactEmployeeList.map((row: any, i: number) => {
                  return (
                    <Grid md={12} sm={12} xs={12} key={i} item>
                      <Typography
                        component={'div'}
                        className={`${
                          row?.status === 'Needs Attention'
                            ? 'normal-box red-bg'
                            : row?.status === 'Good Standing'
                              ? 'normal-box blue-bg'
                              : row?.status === 'On the Move'
                                ? 'normal-box green-bg'
                                : 'normal-box'
                        }`}>
                        <Typography
                          className={`${
                            row?.status === 'Needs Attention'
                              ? 'redbar'
                              : row?.status === 'Good Standing'
                                ? 'bluebar'
                                : row?.status === 'On the Move'
                                  ? 'greenbar'
                                  : ''
                          }`}
                          // className='redbar'
                        />
                        <Typography className='count d-flex-ja'>{i + 1}</Typography>
                        <Typography component={'div'} className='d-flex-ja'>
                          {row?.userImageId ? (
                            <img
                              className='d-avtaor d-flex-ja'
                              src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${row?.userImageId}`}
                            />
                          ) : (
                            <Typography className='d-avtaor d-flex-ja'>{`${row?.name?.charAt(
                              0,
                            )}`}</Typography>
                          )}
                        </Typography>
                        <Typography component={'div'} className='ml-8 d-flex-a flex-1'>
                          <Typography component={'div'} className='pr-8' sx={{ width: '80%' }}>
                          <Typography className='ename'><data value={row?.employeeId}>{row?.name}</data></Typography>
                            <Typography className='edep'>{row?.description}</Typography>
                          </Typography>
                          <Typography className='eval text-right flex-1'>{row.score}</Typography>
                        </Typography>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item md={2.0} sm={12} xs={12}>
              <Grid container>
                {connectionEmployeeList.map((row: any, i: number) => {
                  return (
                    <Grid md={12} sm={12} xs={12} key={i} item>
                      <Typography
                        component={'div'}
                        className={`${
                          row?.status === 'Needs Attention'
                            ? 'normal-box red-bg'
                            : row?.status === 'Good Standing'
                              ? 'normal-box blue-bg'
                              : row?.status === 'On the Move'
                                ? 'normal-box green-bg'
                                : 'normal-box'
                        }`}>
                        <Typography
                          className={`${
                            row?.status === 'Needs Attention'
                              ? 'redbar'
                              : row?.status === 'Good Standing'
                                ? 'bluebar'
                                : row?.status === 'On the Move'
                                  ? 'greenbar'
                                  : ''
                          }`}
                          // className='redbar'
                        />
                        <Typography className='count d-flex-ja'>{i + 1}</Typography>
                        <Typography component={'div'} className='d-flex-ja'>
                          {row?.userImageId ? (
                            <img
                              className='d-avtaor d-flex-ja'
                              src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${row?.userImageId}`}
                            />
                          ) : (
                            <Typography className='d-avtaor d-flex-ja'>{`${row?.name?.charAt(
                              0,
                            )}`}</Typography>
                          )}
                        </Typography>
                        <Typography component={'div'} className='ml-8 d-flex-a flex-1'>
                          <Typography component={'div'} className='pr-8' sx={{ width: '80%' }}>
                          <Typography className='ename'><data value={row?.employeeId}>{row?.name}</data></Typography>
                            <Typography className='edep'>{row?.description}</Typography>
                          </Typography>
                          <Typography className='eval text-right flex-1'>{row.score}</Typography>
                        </Typography>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item md={2.0} sm={12} xs={12}>
              <Grid container>
                {capacityEmployeeList.map((row: any, i: number) => {
                  return (
                    <Grid md={12} sm={12} xs={12} key={i} item>
                      <Typography
                        component={'div'}
                        className={`${
                          row?.status === 'Needs Attention'
                            ? 'normal-box red-bg'
                            : row?.status === 'Good Standing'
                              ? 'normal-box blue-bg'
                              : row?.status === 'On the Move'
                                ? 'normal-box green-bg'
                                : 'normal-box'
                        }`}>
                        <Typography
                          className={`${
                            row?.status === 'Needs Attention'
                              ? 'redbar'
                              : row?.status === 'Good Standing'
                                ? 'bluebar'
                                : row?.status === 'On the Move'
                                  ? 'greenbar'
                                  : ''
                          }`}
                          // className='redbar'
                        />
                        <Typography className='count d-flex-ja'>{i + 1}</Typography>
                        <Typography component={'div'} className='d-flex-ja'>
                          {row?.userImageId ? (
                            <img
                              className='d-avtaor d-flex-ja'
                              src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${row?.userImageId}`}
                            />
                          ) : (
                            <Typography className='d-avtaor d-flex-ja' >{`${row?.name?.charAt(
                              0,
                            )}`}</Typography>
                          )}
                        </Typography>
                        <Typography component={'div'} className='ml-8 d-flex-a flex-1'>
                          <Typography component={'div'} className='pr-8' sx={{ width: '80%' }}>
                          <Typography className='ename'><data value={row?.employeeId}>{row?.name}</data></Typography>
                            <Typography className='edep'>{row?.description}</Typography>
                          </Typography>
                          <Typography className='eval text-right flex-1'>{row.score}</Typography>
                        </Typography>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        )}
        {}
      </Grid>
    </Typography>
  );
};

export default EmployeeEngagementList;
