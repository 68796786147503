import  React from 'react';
import EmployeeSearchContext from './employeeSearchContext';

const EmployeeSearchContextProvider: React.FC<{children: React.ReactNode}> = ({ children }) =>{

    const [selectedEmployeeId, setSelectedEmployeeId] = React.useState(0);

    const data = { selectedEmployeeId, setSelectedEmployeeId };

    return(
        <EmployeeSearchContext.Provider value={data} >{ children }</EmployeeSearchContext.Provider>
    );
};

export default EmployeeSearchContextProvider;