import * as Yup from 'yup';

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Email is required')
    .email('Email is invalid')
    .matches(/^([a-zA-Z0-9._%-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/, {
      message: 'Enter valid email',
    }),
  clientId: Yup.string().when('from', {
    is: false,
    then(schema) {
      return schema.required('Company is required');
    },
  }),
  from: Yup.boolean().notRequired(),
});

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Email is required')
    .email('Email is invalid')
    .matches(/^([a-zA-Z0-9._%-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/, {
      message: 'Enter valid email',
    }),
  password: Yup.string().when('from', {
    is: true,
    then(schema) {
      return schema
        .required('Password is required')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
          'Enter valid password',
        )
        .matches(/^(\S+$)/g, ' password not accept white space');
    },
  }),
  from: Yup.boolean().notRequired(),
});

export const ClientLoginSchema = Yup.object().shape({
  clientId: Yup.string().required('Company is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{4,}$/,
      'Enter valid password',
    )
    .matches(/^(\S+$)/g, ' password not accept white space'),
});

export const OtpSchema = Yup.object().shape({
  otp: Yup.string().required('OTP is required').min(6, 'OTP must be an 6 numbers'),
});

export const AddAdminSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('First Name is required')
    .max(Number(25), `First Name should not exceed ${String(Number(25))} characters`),
  lastName: Yup.string()
    .trim()
    .required('Last Name is required')
    .max(Number(25), `Last Name should not exceed ${String(Number(25))} characters`),
  username: Yup.string()
    .trim()
    .required('Email is required')
    .email('Email is invalid')
    .matches(/^([a-zA-Z0-9._%-+]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/, {
      message: 'Enter valid email',
    }),
  jobId: Yup.string().trim().required('Job Title is required'),
});

export const EditEmployeeSchema = Yup.object().shape({
  // role: Yup.string().trim().required('Role is required'),
  role: Yup.string().optional(),
});

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Current Password is required'),
  newPassword: Yup.string()
    .required('New Password is required')
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*~|()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[A-Z]){1}).*$/,
      'Enter valid password',
    )
    .notOneOf([Yup.ref('oldPassword')], 'New password cannot be the same as old password')
    .matches(/^(\S+$)/g, 'password not accept white space'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('newPassword')], 'Passwords does not match'),
});

export const CreatePasswordSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords does not match'),
  // .when('password', {
  //   is: (password: any) => (password && password.length > 0 ? true : false),
  //   then: Yup.string().oneOf([Yup.ref('password')], "Password doesn't match"),
  // }),
  password: Yup.string()
    .required('New Password is required')
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*~|()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[A-Z]){1}).*$/,
      'Enter valid password',
    )
    .matches(/^(\S+$)/g, 'password not accept white space'),
});
