import React, { useEffect, useState } from 'react';
import BackImg from 'assets/png/loginbg.jpg';
import Logo from 'assets/png/LogoDark.svg';
import {
  Box,
  // Button,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { CreatePasswordForm } from 'utils/ValidationForm';
import { CreatePasswordSchema } from 'utils/ValidatorSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { addNewData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { PublicRoutes } from 'routes/routeConstans';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { alertOptions } from 'utils/Config';
import { toast } from 'react-toastify';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import PageLoader from 'components/PageLoader';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 5,
  width: 16,
  height: 16,
  backgroundColor: theme.palette.mode === 'dark' ? '#EFEFEF' : '#EFEFEF',
  '.Mui-focusVisible &': {
    outline: '1px auto #D9D9D9',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#EFEFEF' : '#EFEFEF',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#C3D34F',
  // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#C3D34F',
  },
});

const CreatePassword = ({ from }: any) => {
  const navigation: any = useNavigate();
  const query = useQuery();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { handleSubmit, control, formState, watch, trigger } = useForm<CreatePasswordForm>({
    defaultValues: {
      confirmPassword: '',
      password: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(CreatePasswordSchema),
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [showPassword1, setShowPassword1] = React.useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  const goBackFunction = () => {
    if (from === 'client') {
      navigation(PublicRoutes.CLIENTLOGIN);
    } else {
      navigation(PublicRoutes.ADMINLOGIN);
    }
  };

  const [loader, setLoader] = useState<boolean>(true);
  const verifyToken = (token: any) => {
    addNewData({}, `${MASTER_ENDPOINT.ValidateToken}?token=${token}`, {}, false)
      .then(() => {
        setLoader(false);
      })
      .catch((error: any) => {
        toast.error(error?.message || error?.error?.message || String(error), alertOptions);
        goBackFunction();
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!query.get('token')) {
      setLoader(false);
      goBackFunction();
    } else {
      verifyToken(query.get('token'));
    }
  }, [query]);

  const submitForm = (data: CreatePasswordForm) => {
    setLoading(true);
    const secertKey = CryptoJS.enc.Utf8.parse('6ENY03$PR@CT*CE$');
    const iv = CryptoJS.enc.Utf8.parse('6ENY03$PR@CT*CE$');
    const encryptedPassword = CryptoJS.AES.encrypt(`${data.password}`, secertKey, {
      keySize: 128 / 8,
      mode: CryptoJS.mode.CBC,
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    addNewData(
      {},
      `${MASTER_ENDPOINT.CreatePasswordClient}?token=${query.get(
        'token',
      )}&password=${encryptedPassword}&isfromresetpassword=${
        query.get('isfromresetpassword') ? query.get('isfromresetpassword') : false
      }`,
      {},
      false,
    )
      .then(() => {
        if (from === 'client') {
          navigation(PublicRoutes.CLIENTPASSWORDSUCESS);
        } else {
          navigation(PublicRoutes.ADMINPASSWORDSUCESS);
        }
      })
      .catch((error: any) => {
        toast.error(error?.message || error?.error?.message || String(error), alertOptions);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Grid container>
      <PageLoader loading={loader} />
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={'div'}
          sx={{ backgroundImage: `url(${BackImg})` }}
          className='login-banner'>
          <Typography component={'div'} className='login-box'>
            <Typography className='logo-text d-flex-a'>
              <img src={Logo} alt='simplifiedHR' />
            </Typography>
            <form id='crt-submit' onSubmit={handleSubmit(submitForm)}>
              <Box component='div' className='w-450'>
                <Grid container>
                  <Grid item md={12}>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>New Password*</Typography>
                      <Controller
                        control={control}
                        defaultValue=''
                        name='password'
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <TextField
                              className='custom-input'
                              id='standard-basic'
                              placeholder='Enter New Password'
                              variant='standard'
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onChange={
                                // onChange
                                (e) => {
                                  onChange(e.target.value.replace(/\s/g, ''));
                                  trigger('password');
                                }
                              }
                              sx={{
                                '& button': {
                                  marginRight: '5px',
                                },
                              }}
                              type={showPassword ? 'text' : 'password'}
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={handleClickShowPassword}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge='end'>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                ),
                              }}
                              value={value}
                              name={name}
                              error={formState.errors.password?.message ? true : false}
                              helperText={formState.errors.password?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography component={'div'} className='custom-field'>
                      <Typography component={'p'}>Confirm Password*</Typography>
                      <Controller
                        control={control}
                        defaultValue=''
                        name='confirmPassword'
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <TextField
                              className='custom-input'
                              id='standard-basic'
                              placeholder='Enter Confirm Password'
                              variant='standard'
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onChange={(e) => {
                                onChange(e.target.value.replace(/\s/g, ''));
                                trigger('confirmPassword');
                              }}
                              sx={{
                                '& button': {
                                  marginRight: '5px',
                                },
                              }}
                              type={showPassword1 ? 'text' : 'password'}
                              InputProps={{
                                endAdornment: (
                                  <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={handleClickShowPassword1}
                                    // onMouseDown={handleMouseDownPassword1}
                                    edge='end'>
                                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                ),
                              }}
                              value={value}
                              name={name}
                              error={formState.errors.confirmPassword?.message ? true : false}
                              helperText={formState.errors.confirmPassword?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                    <Typography sx={{ marginTop: '10px' }}>
                      <Typography component={'div'} className='custom-field d-flex-a'>
                        <Checkbox
                          className='cinput'
                          // disabled
                          checked={watch('password').length >= 8}
                          checkedIcon={<CheckedIcon />}
                          icon={<CheckIcon />}
                        />
                        <Typography>Length should be minimum 8 characters</Typography>
                      </Typography>
                      <Typography component={'div'} className='custom-field d-flex-a'>
                        <Checkbox
                          className='cinput'
                          // disabled
                          checked={/[A-Z]+/.test(watch('password'))}
                          checkedIcon={<CheckedIcon />}
                          icon={<CheckIcon />}
                        />
                        <Typography>Use minimum one upper case letter</Typography>
                      </Typography>
                      <Typography component={'div'} className='custom-field d-flex-a'>
                        <Checkbox
                          className='cinput'
                          // disabled
                          checked={/[*@!$#%&()^~|{}]+/.test(watch('password'))}
                          checkedIcon={<CheckedIcon />}
                          icon={<CheckIcon />}
                        />
                        <Typography>Use minimum one special character</Typography>
                      </Typography>
                      <Typography component={'div'} className='custom-field d-flex-a'>
                        <Checkbox
                          className='cinput'
                          // disabled
                          checked={/[0-9]+/.test(watch('password'))}
                          checkedIcon={<CheckedIcon />}
                          icon={<CheckIcon />}
                        />
                        <Typography className='cinput'>Use minimum one number (0-9)</Typography>
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <LoadingButton
                className='submit-btn'
                type='submit'
                form='crt-submit'
                loading={loading}
                loadingPosition='center'>
                Continue
              </LoadingButton>
              <Typography className='border-box' />
            </form>
            <Typography className='copy-text'>
              ©{moment().format('YYYY')} SimplifiedHR, Inc. All rights reserved.
            </Typography>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CreatePassword;
