import React, { useEffect, useState } from 'react';
import BackImg from 'assets/png/loginbg.jpg';
import Logo from 'assets/png/LogoDark.svg';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { LoginForm } from 'utils/ValidationForm';
import { LoginSchema } from 'utils/ValidatorSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from 'routes/routeConstans';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { USER_LOGIN_SUCCESS } from 'store/types/UserTypes';
import { useDispatch } from 'react-redux';
import Storage from 'utils/Storage';
import CryptoJS from 'crypto-js';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import moment from 'moment';

const Login = ({ from }: any) => {
  const navigation: any = useNavigate();
  const dispatch: any = useDispatch();
  const [ip, setIP] = useState('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const { handleSubmit, control, formState, trigger } = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
      from: from === 'admin' ? true : false,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(LoginSchema),
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   event.preventDefault();
  // };

  const getUserIp = async () => {
    const res = await axios.get('https://api.ipify.org?format=json');
    setIP(res?.data?.ip);
  };

  useEffect(() => {
    getUserIp();
  }, []);

  const submitForm = (data: LoginForm) => {
    setLoading(true);

    if (from === 'admin') {
      const secertKey = CryptoJS.enc.Utf8.parse('6ENY03$PR@CT*CE$');
      const iv = CryptoJS.enc.Utf8.parse('6ENY03$PR@CT*CE$');
      const encryptedPassword = CryptoJS.AES.encrypt(`${data.password}`, secertKey, {
        keySize: 128 / 8,
        mode: CryptoJS.mode.CBC,
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
      }).toString();
      getAllListData(
        `${MASTER_ENDPOINT.VerifyUser}?userId=${
          data['email']
        }&password=${encryptedPassword}&userIp=${ip ? ip : null}`,
        true,
      )
        .then((response: any) => {
          if (response?.token) {
            const userData: any = jwtDecode(String(response?.token));
            userData['token'] = response?.token;
            userData['userDetailsData'] = response?.data;
            userData['isAdmin'] = true;
            userData['isClient'] = false;
            userData['isImpersonate'] = false;
            userData['isFromSuperAdmin'] = '';
            userData['superUserId'] = '';
            const tokenData: any = {
              refreshToken: '',
              token: response?.token,
              userId: userData.id,
              isAdmin: true,
              isClient: false,
              isImpersonate: false,
              isFromSuperAdmin: '',
              superUserId: '',
            };
            Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokenData));
            setTimeout(() => {
              dispatch({
                payload: userData,
                type: USER_LOGIN_SUCCESS,
              });
            }, 1000);
          } else {
            navigation(PublicRoutes.ADMINRESET, { state: data });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      getAllListData(`${MASTER_ENDPOINT.ClientNewLogin}?userId=${data['email']}`, true)
        .then((response: any) => {
          const sortData = response?.data?.sort(
            (a: any, b: any) => a?.clientName.localeCompare(b?.clientName),
          );
          navigation(PublicRoutes.CLIENTCOMPANYLOGIN, {
            state: { ...data, companyList: response?.data ? sortData : [] },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={'div'}
          sx={{ backgroundImage: `url(${BackImg})` }}
          className='login-banner'>
          <Typography component={'div'} className='login-box'>
            <Typography className='logo-text d-flex-a'>
              <img src={Logo} alt='simplifiedHR' />
            </Typography>
            <form id='login-form' onSubmit={handleSubmit(submitForm)}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Email*</Typography>
                <Controller
                  control={control}
                  defaultValue=''
                  name='email'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <TextField
                        className='custom-input'
                        id='standard-basic'
                        placeholder='Enter Email'
                        variant='standard'
                        onChange={(e) => {
                          const vals = e.target.value.replace(
                            /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|}~(),:;<>@[\]]/g,
                            '',
                          );
                          onChange(vals);
                          trigger('email');
                        }}
                        value={value}
                        name={name}
                        error={formState.errors.email?.message ? true : false}
                        helperText={formState.errors.email?.message}
                      />
                    );
                  }}
                />
              </Typography>
              {from === 'admin' && (
                <Typography component={'div'} className='custom-field' sx={{ paddingTop: '10px' }}>
                  <Typography sx={{ display: 'flex' }} justifyContent={'space-between'}>
                    <Typography component={'p'}>Password*</Typography>
                    <Typography
                      className='forgot-password'
                      component={'p'}
                      onClick={() => navigation(PublicRoutes.FORGOTPASSWORDADMIN)}>
                      Forgot Password?
                    </Typography>
                  </Typography>
                  <Controller
                    control={control}
                    defaultValue=''
                    name='password'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <TextField
                          className='custom-input'
                          id='standard-basic'
                          placeholder='Enter Password'
                          variant='standard'
                          sx={{
                            '& button': {
                              marginRight: '5px',
                            },
                          }}
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge='end'>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            ),
                          }}
                          onChange={(e) => {
                            onChange(e.target.value.replace(/\s/g, ''));
                          }}
                          value={value}
                          name={name}
                          error={formState.errors.password?.message ? true : false}
                          helperText={formState.errors.password?.message}
                        />
                      );
                    }}
                  />
                </Typography>
              )}
              <LoadingButton
                className='submit-btn'
                loading={loading}
                form='login-form'
                type='submit'
                loadingPosition='center'>
                Continue
              </LoadingButton>
              <Typography className='border-box' />
            </form>
            <Typography className='copy-text'>
              ©{moment().format('YYYY')} SimplifiedHR, Inc. All rights reserved.
            </Typography>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Login;
