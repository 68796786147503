import { Box, Modal, TextField, Typography, Button, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { AddAdminForm } from 'utils/ValidationForm';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddAdminSchema } from 'utils/ValidatorSchema';
import { addNewData, getAllListData, updateData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { useAppSelector } from 'store/hooks';
import { useDispatch } from 'react-redux';
import { USER_REFRESH_TABLE } from 'store/types/UserTypes';
import CustomSelect from 'components/customSelect';
import { toast } from 'react-toastify';

interface PropsData {
  visible: boolean;
  handleClose: () => void;
  editObject?: any;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  minHeight: '407px',
  width: '414px',
  borderRadius: ' 8px',
  boxShadow: ' 0 5px 14px 0 rgba(0,0,0,0.22)',
  padding: '28px 30px',
};

const AddAndEdit = (props: PropsData) => {
  const dispatch: any = useDispatch();
  const { visible, handleClose, editObject } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [job, setJob] = React.useState<any>([]);
  const [uploadImage, setUploadImage] = React.useState<any>({});
  const [localImage, setLocalImage] = React.useState<any>('');
  const userDetail: any = useAppSelector((store: any) => {
    return store.userLogin.userInfo;
  });

  const { handleSubmit, control, formState, setValue, reset, watch, trigger } =
    useForm<AddAdminForm>({
      defaultValues: {
        firstName: '',
        lastName: '',
        username: '',
        jobId: '',
      },
      reValidateMode: 'onChange',
      resolver: yupResolver(AddAdminSchema),
    });

  const closeEvent = () => {
    reset();
    handleClose();
    setUploadImage({});
    setLocalImage('');
    setLoading(false);
  };
  useEffect(() => {
    if (editObject && visible && Object.keys(editObject).length) {
      setValue('firstName', editObject.firstName);
      setValue('lastName', editObject.lastName);
      setValue('username', editObject.username);
      setValue('jobId', editObject.jobId);
    }
  }, [editObject, visible]);

  const submitForm = (data: AddAdminForm) => {
    setLoading(true);
    const jobFind = job.findIndex((item: any) => item.id === parseInt(data['jobId']));
    let jobTitle = '';
    if (jobFind !== -1) {
      jobTitle = job[jobFind].jobTitles;
    }
    if (editObject && Object.keys(editObject).length) {
      const postData = {
        ...data,
        createdBy: editObject.Id,
        updatedBy: userDetail.Id,
        isActive: editObject.isActive,
        updatedOn: moment(),
        createdOn: editObject.createdOn,
        lastActiveDate: editObject.lastActiveDate,
        id: editObject.id,
        jobTitle: jobTitle,
      };
      updateData('', postData, `${MASTER_ENDPOINT.UpdateSuperAdmin}`, true)
        .then(async () => {
          if (localImage && localImage?.length) {
            const formData2 = new FormData();
            if (uploadImage) {
              formData2.append('files', uploadImage);
            }
            formData2.append('UserId', editObject?.username);
            if (userDetail.ClientId) {
              formData2.append('ClientId', userDetail.ClientId);
            } else {
              formData2.append('ClientId', `${0}`);
            }
            editObject?.imageId
              ? formData2.append('ImageId', editObject?.imageId)
              : formData2.append('ImageId', '');
            formData2.append('CreatedBy', userDetail.Id);
            formData2.append('CreatedOn', moment().format());
            formData2.append('UpdatedBy', userDetail.Id);
            formData2.append('UpdatedOn', moment().format());

            await updateData('', formData2, `${MASTER_ENDPOINT.UploadPicture}`, true, {
              headers: { 'Content-Type': 'multipart/form-data' },
            }).then(() => {
              dispatch({
                type: USER_REFRESH_TABLE,
              });
            });
          }
          if (!localImage) {
            dispatch({
              type: USER_REFRESH_TABLE,
            });
          }
          closeEvent();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const postJSon = {
        ...data,
        createdBy: userDetail.Id,
        createdOn: moment(),
        jobTitle: jobTitle,
      };
      addNewData(postJSon, `${MASTER_ENDPOINT.CreateSuperAdmin}`, {}, true)
        .then(() => {
          if (localImage && localImage?.length) {
            const formData = new FormData();
            if (uploadImage) {
              formData.append('files', uploadImage);
            }
            if (userDetail.ClientId) {
              formData.append('ClientId', userDetail.ClientId);
            } else {
              formData.append('ClientId', `${0}`);
            }
            // formData.append('Id', userDetail.Id);
            formData.append('UserId', data.username);
            userDetail.ClientId ? formData.append('ClientId', userDetail.ClientId) : 0;
            formData.append('ImageId', '');
            formData.append('CreatedBy', userDetail.Id);
            formData.append('CreatedOn', moment().format());
            formData.append('UpdatedBy', userDetail.Id);
            formData.append('UpdatedOn', moment().format());
            updateData('', formData, `${MASTER_ENDPOINT.UploadPicture}`, true, {
              headers: { 'Content-Type': 'multipart/form-data' },
            }).then(() => {
              dispatch({
                type: USER_REFRESH_TABLE,
              });
            });
          }
          if (!localImage) {
            dispatch({
              type: USER_REFRESH_TABLE,
            });
          }
          closeEvent();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (visible) {
      getAllListData(`${MASTER_ENDPOINT.JobTitle}`).then((res) => {
        setJob(res?.data);
      });
    }
  }, [visible]);

  const updatePhoto = (e: any) => {
    const imageFormatCheck = e?.target?.files[0]?.name.split('.');
    const formatCheck = ['tiff', 'jpeg', 'jpg', 'png'].includes(
      imageFormatCheck[imageFormatCheck.length - 1].toLowerCase(),
    );
    const maxSizeInMB = 10;
    const fileSizeInMB = e?.target?.files[0].size / 1024 / 1024;
    if (fileSizeInMB > maxSizeInMB) {
      toast.error('File size should not exceed 10MB.');
    } else if (formatCheck) {
      setUploadImage(e?.target?.files[0]);
      setLocalImage(URL.createObjectURL(e?.target?.files[0]));
    } else {
      toast.error('Upload valid images only PNG, JPEG, JPG and TIFF are allowed');
    }
  };

  return (
    <Modal
      open={visible}
      onClose={closeEvent}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box component='div' sx={style}>
        <Typography className='d-flex-a flex-sb'>
          <Typography className='modal-title'>
            {' '}
            {editObject && editObject?.id ? 'EDIT SUPER ADMIN' : 'INVITE SUPER ADMIN'}
          </Typography>
          <CloseIcon className='closesvg' onClick={closeEvent} />
        </Typography>
        <Typography className='modal-dis'>
          {editObject && editObject?.id
            ? 'Edit any of the super admin info below and save changes.'
            : 'Invite other Encompass users to this Admin portal. Please note that they will be granted a Super Admin role.'}
        </Typography>
        <Typography component={'div'} sx={{ display: 'flex', marginBottom: '14px' }}>
          <Typography component={'div'} className='d-flex-a'>
            <Typography component={'div'}>
              <Typography className='pop-default d-flex-ja' sx={{ textTransform: 'uppercase' }}>
                {' '}
                {localImage ? (
                  <img className='pop-default2 d-flex-ja' src={localImage} />
                ) : editObject?.imageId ? (
                  <img
                    className='pop-default2 d-flex-ja'
                    src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${editObject?.imageId}`}
                  />
                ) : (
                  `${watch('firstName') ? watch('firstName').charAt(0) : ''}${
                    watch('lastName') ? watch('lastName').charAt(0) : ''
                  }`
                )}
              </Typography>
            </Typography>
            <Typography className='d-flex-ja'>
              <Button component='label' className='add-photo ml-10'>
                <input
                  hidden
                  type='file'
                  accept='image/*'
                  onChange={(e) => updatePhoto(e)}
                  onClick={(event: any) => {
                    event.target.value = null;
                  }}
                />
                + Photo{' '}
              </Button>
              <span className='add-photo' style={{ marginTop: '10px !important' }}>
                <Tooltip
                  title={
                    <Typography className='tooltip-text'>
                      Maximum file size should be 10 MB
                    </Typography>
                  }
                  arrow
                  placement='bottom-start'>
                  <InfoIcon fontSize='small' />
                </Tooltip>
              </span>
            </Typography>
          </Typography>
        </Typography>
        <form className='private-form'>
          <Typography component={'div'} className='custom-field'>
            <Typography component={'p'}>First Name*</Typography>
            <Controller
              control={control}
              defaultValue=''
              name='firstName'
              render={({ field: { onChange, value, name } }) => {
                return (
                  <TextField
                    className='custom-input'
                    id='standard-basic'
                    placeholder='Enter First Name'
                    variant='standard'
                    onChange={(e) => {
                      const vals = e.target.value
                        // .toLowerCase()
                        .replace(/[^a-zA-Z ]/g, '')
                        .replace(/\b\w/g, (char) => char.toUpperCase())
                        .trimStart();
                      onChange(vals);
                      trigger('firstName');
                    }}
                    inputProps={{ maxLength: 26, minLength: 1 }}
                    value={value}
                    name={name}
                    error={formState.errors.firstName?.message ? true : false}
                    helperText={formState.errors.firstName?.message}
                  />
                );
              }}
            />
          </Typography>
          <Typography component={'div'} className='custom-field'>
            <Typography component={'p'}>Last Name*</Typography>
            <Controller
              control={control}
              defaultValue=''
              name='lastName'
              render={({ field: { onChange, value, name } }) => {
                return (
                  <TextField
                    className='custom-input'
                    id='standard-basic'
                    placeholder='Enter Last Name'
                    variant='standard'
                    onChange={(e) => {
                      const vals = e.target.value
                        // .toLowerCase()
                        .replace(/[^a-zA-Z ]/g, '')
                        .replace(/\b\w/g, (char) => char.toUpperCase())
                        .trimStart();
                      onChange(vals);
                      trigger('lastName');
                    }}
                    inputProps={{ maxLength: 26, minLength: 1 }}
                    value={value}
                    name={name}
                    error={formState.errors.lastName?.message ? true : false}
                    helperText={formState.errors.lastName?.message}
                  />
                );
              }}
            />
          </Typography>
          <Typography component={'div'} className='custom-field'>
            <Typography component={'p'}>Email*</Typography>
            <Controller
              control={control}
              defaultValue=''
              name='username'
              render={({ field: { onChange, value, name } }) => {
                return (
                  <TextField
                    className='custom-input'
                    id='standard-basic'
                    placeholder='Enter your Email'
                    variant='standard'
                    onChange={(e) => {
                      const vals = e.target.value.replace(
                        /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|}~(),:;<>@[\]]/g,
                        '',
                      );
                      onChange(vals);
                      trigger('username');
                    }}
                    value={value}
                    name={name}
                    disabled={editObject?.id ? true : false}
                    error={formState.errors.username?.message ? true : false}
                    helperText={formState.errors.username?.message}
                  />
                );
              }}
            />
          </Typography>
          <Typography component={'div'} className='custom-field'>
            <Typography component={'p'}>Job Title*</Typography>
            <Controller
              control={control}
              defaultValue=''
              name='jobId'
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomSelect
                    id='outlined-basic'
                    placeHolder={'Select Job Title'}
                    name={name}
                    value={value}
                    labelKey={'jobTitles'}
                    valueKey={'id'}
                    options={job}
                    error={formState.errors.jobId?.message}
                    onChange={onChange}
                  />
                );
              }}
            />
          </Typography>
          <LoadingButton
            className='submit-btn'
            onClick={handleSubmit(submitForm)}
            loading={loading}
            loadingPosition='center'>
            {editObject && editObject?.id ? 'Save' : 'Add & Invite Super Admin'}
          </LoadingButton>
        </form>
      </Box>
    </Modal>
  );
};

export default AddAndEdit;
