import {
    Typography,
    Modal,
    Box,
    Grid
} from '@mui/material';
import { useState, useEffect } from 'react';
import Select from 'react-dropdown-select';
import { addNewData } from 'services/CommonServices';
import { SURVEY_EDITOR_ENDPOINT } from 'utils/Constant';

interface LoaderProps {
    addOpen: boolean,
    setAddOpen: any,
    setLoading: any,
    setSurveyQuestions: any
}

const AddSurveyQuestionPopup = (props: LoaderProps) => {
    const { addOpen, setAddOpen, setLoading, setSurveyQuestions } = props;

    const [categories, setCategories] = useState<any[]>([]);
    const [categoryId, setCategoryId] = useState(0);
    const [questionText, setQuestionText] = useState('');

    const handleClosePopup = (event: any, reason: any) => {
        if (reason && reason === 'backdropClick')
            return;
        setAddOpen(false);
    }

    useEffect(() => {
        loadControl();
    }, [addOpen])

    const loadControl = () => {
        setQuestionText('');
        setCategoryId(1);
    }

    const getCategories = () => {
        addNewData(
            {},
            `${SURVEY_EDITOR_ENDPOINT.GetCategories}`,
            {},
            true,
        )
            .then((response: any) => {
                if (response?.surveyCategories?.length > 0) {
                    setCategories(response?.surveyCategories);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const questionTextChanged = (e: any) => {
        e.preventDefault();
        setQuestionText(e.target.value);
    }

    const getQuestions = () => {
        addNewData(
            {},
            `${SURVEY_EDITOR_ENDPOINT.GetQuestions}`,
            {},
            true,
        )
            .then((response: any) => {
                if (response?.surveyQuestions?.length > 0) {
                    setSurveyQuestions(response?.surveyQuestions);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeAddAndSave = () => {
        addNewData(
            {
                categoryId: categoryId,
                value: questionText
            },
            `${SURVEY_EDITOR_ENDPOINT.AddSurveyQuestion}`,
            {},
            true,
        )
            .then(() => {
                getQuestions();
            })
            .catch(error => {
                alert('Error updating question. Please try again later ' + error);
            })
            .finally(() => {
                setLoading(false);
            });

        setAddOpen(false);
    }

    useEffect(() => {
        getCategories();
    }, []);

    const closeAddNoSave = () => {
        setAddOpen(false);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            <Modal
                className='profile-modal'
                open={addOpen}
                onClose={handleClosePopup}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'>
                <Box sx={style}>
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography id='modal-modal-title' className='modal-title'>
                                Add Survey Question
                            </Typography>        
                        </Grid>            
                        <Grid item md={3} sm={12} xs={12}  sx={{ mt: 2 }}>
                            <Typography className='labels'>
                                Category:
                            </Typography>
                        </Grid>
                        <Grid item md={9} sm={12} xs={12}  sx={{ mt: 2 }}>
                            <Typography className='details'>
                                <Select
                                    values={[]}
                                    options={categories}
                                    required={true}
                                    valueField='id'
                                    multi={false}
                                    labelField='value'
                                    style={{ width: 250 }}
                                    onChange={(values: any) => setCategoryId(values[0]?.id)} />
                            </Typography>
                        </Grid>

                        <Typography id='details'>
                            <textarea onChange={questionTextChanged}
                                value={questionText}
                                rows={3}
                                cols={60}
                                required={true} />
                        </Typography>
                        <Typography id='modal-modal-description'>
                            <button className='modal-yes1-btn' style={{ width: 100 }} onClick={closeAddAndSave}>OK</button>
                            <button className='modal-yes-btn' style={{ marginLeft: 10, width: 100 }} onClick={closeAddNoSave}>Cancel</button>
                        </Typography>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

export default AddSurveyQuestionPopup;