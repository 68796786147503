'use client'
import { addNewData } from 'services/CommonServices';
import Loader from 'components/Loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { SURVEY_EDITOR_ENDPOINT } from 'utils/Constant';
import {
    Grid,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';

const Categories = () =>{
    const [loading, setLoading] = useState(true);

    const [categories, setCategories] = useState<any[]>([]);
      
    const getCategories = () => {
        addNewData(
            {},
            `${SURVEY_EDITOR_ENDPOINT.GetCategories}`,
            {},
            true,
        )
            .then((response: any) => {
                if (response?.surveyCategories?.length > 0) {
                    setCategories(response?.surveyCategories);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getCategories();
    }, []);

    const tableData = React.useMemo(() => {
        if (categories.length) {
            return categories.map((row: any, index: number) => (
                <TableRow key={index} sx={{ ' &:last-child th': { border: 0 } }}>
                    <TableCell>
                        <Typography
                            component={'div'}
                            className='company-name'>{`${row?.id}`}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            component={'div'}
                            className='company-name'>{`${row?.value}`}</Typography>
                    </TableCell>                                      
                </TableRow>
            ));
        }
        return (
            <TableRow>
                <TableCell colSpan={5} align='center'>
                    <Typography component={'div'} className='d-flex-ja norecord'>
                        No Records Found
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }, [categories]);


    return (
        <Grid container className='d-flex'>
            <Grid item md={12} sm={12} xs={12}>
                <TableContainer component={Paper} className='companie-table'>
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '25%' }}>
                                    <Typography component={'div'} className='d-flex-a'>
                                        <Typography className='th-title'>ID</Typography>
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ width: '75%' }}>
                                    <Typography component={'div'} className='d-flex-a'>
                                        <Typography className='th-title'>Category</Typography>
                                    </Typography>
                                </TableCell>                               
                            </TableRow>
                        </TableHead>
                        <TableBody>{loading ? <Loader loading={loading} colsSpan={5} /> : tableData}</TableBody>
                    </Table>
                </TableContainer>                             
            </Grid>           
        </Grid>
    );
}

export default Categories;