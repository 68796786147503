import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import React from 'react';

interface PropsData {
  visible: boolean;
  handleClose: () => void;
  type?: any;
  onConfirm?: () => void;
  roleName: string;
  setRoleName: any;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  minHeight: '168px',
  width: '450px',
  borderRadius: ' 8px',
  boxShadow: ' 0 5px 14px 0 rgba(0,0,0,0.22)',
  padding: '28px 25px',
};

const HandleRolesAndPermissions = (props: PropsData) => {
  const { visible, handleClose, onConfirm, type, roleName, setRoleName } = props;
  const closeEvent = () => {
    handleClose();
  };

  return (
    <Modal
      open={visible}
      onClose={closeEvent}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box component='div' sx={style}>
        <Typography className='d-flex-a flex-sb'>
          <Typography className='modal-title'>
            {type === 'delete' || type === 'deleteDefault'
              ? 'Delete Role'
              : type === 'clone' || type === 'cloneDefault'
                ? 'Clone Role'
                : ''}
          </Typography>
        </Typography>
        <Typography className='modal-dis'>
          {type === 'delete'
            ? 'Are you sure you want to delete this role?'
            : type === 'deleteDefault'
              ? 'Make some role as default and delete this role'
              : type === 'clone' || type === 'cloneDefault'
                ? 'Please rename and clone the role'
                : ''}
        </Typography>
        {(type === 'clone' || type === 'cloneDefault') && (
          <Typography
            className='modal-dis'
            component={'div'}
            sx={{
              '& .MuiTextField-root': {
                width: '100%',
              },
            }}>
            <TextField
              className='custom-big-input'
              id='standard-basic'
              placeholder='Enter Role*'
              variant='standard'
              onChange={(e: any) => setRoleName(e.target.value)}
              value={roleName}
              onKeyDown={(e) => {
                if (e.keyCode == 13 || e.key === 'Enter') {
                  onConfirm && onConfirm();
                }
              }}
            />
          </Typography>
        )}
        <Typography component={'div'} className='d-flex-a pt-15' columnGap='5px'>
          <Button className='modal-no-btn' onClick={closeEvent}>
            NO, CANCEL
          </Button>
          <Button
            className={type === 'delete' ? 'modal-yes-btn' : 'modal-yes1-btn'}
            onClick={onConfirm}>
            {type === 'delete'
              ? 'YES, Delete'
              : type === 'deleteDefault'
                ? 'YES, Ok'
                : type === 'clone' || type === 'cloneDefault'
                  ? 'YES, Clone'
                  : ''}
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
};

export default HandleRolesAndPermissions;
