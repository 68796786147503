export const MASTER_ENDPOINT = {
  ClientNewLogin: 'Login/GetClientsByUserId',
  Login: 'Login/InviteSAdminUser',
  ChangePassword: 'Login/ChangePassword',
  ResetPassword: 'Login/ResetPassword',
  ValidateToken: 'Login/ValidateToken',
  ClientLogin: 'Login/InviteUser',
  CreatePasswordClient: 'Login/CreatePassword',
  VerifyClientOtp: 'Login/ValidateUserOTP',
  VerifyAdminOtp: 'Login/ValidateSAdminOTP',
  VerifyClient: 'Login/ValidateLoginUser',
  VerifyUser: 'Login/ValidateSAdminUser',
  RefreshToken: 'Login/RefreshToken',

  SuperAdminUser: 'SuperAdmin/GetAllSAdminUser',
  CreateSuperAdmin: 'SuperAdmin/CreateSAdminUser',
  CreateRole: 'Master/CreateRole',
  UpdateRole: 'Master/UpdateRole',
  UpdateSuperAdmin: 'SuperAdmin/UpdateSAdminUser',
  UpdateEmployee: 'Employee/UpdateEmployeeStatus',
  UpdateEmployeeRole: 'Employee/UpdateEmployeeRole',
  CompanyList: 'Clients/GetClientsWithActiveEmployeeCount',
  employeList: 'Employee/GetAllEmployeesByClientId',
  ClientRole: 'Master/GetAllRoleByClientID',
  GetSuperAdminId: 'SuperAdmin/GetSAdminUserById',
  GetEmployeeId: 'Employee/GetEmployeeById',
  GetModules: 'Master/GetModules',
  GetRoles: 'Master/GetAllRoleByClientID',
  GetRoleById: 'Master/GetRoleById',
  DeleteRole: 'Master/DeleteRole',
  JobTitle: 'Master/GetAllJobTitles',
  Classification: 'Master/GetAllClassificationCodeByClientID',
  LearingAndDevelopment: 'Learnings/GetLearningandDevelopmentByClientId',
  ImpersonateUser: 'Login/ImpersonateUser',
  BackImpersonateUser: 'Login/BackToOriginalUser',
  ClassificationCode: 'Master/GetAllClassificationCodeByClientID',
  ClassificationCodeForClient: 'Master/GetClassForFilterbyClientIDandClassId',
  ClassificationCodeDetails: 'Master/GetClassbyClientIDandClassId',
  CreateFilter: 'Master/CreateFilterClassCode',
  DeleteFilter: 'Master/DeleteFilterClassCode',
  GetJobCode: 'Master/GetJobTitlesForFilterByClientId',
  GetCompany: 'Master/GetCompaniesForFilterByClientId',
  GetFilter: 'Master/GetFilterApplyCriteria',
  GetDirectSupervisor: 'Master/GetDirectSupervisorForFilter',
  UploadPicture: 'Master/UploadUserImage',
  GetImage: 'Login/GetUserImage',
  LogoutUser: 'Login/UserLogout',
};

export const REPORT_ENDPOINT = {
  GetCountWDetails: 'Reports/Organizational/GetAllHeadcountWithDetailsByClientId',
  CostOfTurnOver: 'Reports/Organizational/GetAllCostofTurnoverByClientId',
  GetAllHeadcountMOMwithDetails: 'Reports/Organizational/GetAllHeadcountMOMwithDetailsByClientId',
  Organizationalchat: 'Reports/Organizational/GetAllOrganizationalChartByClientId',
  GetHiresvsTerms: 'Reports/Organizational/GetAllHiresvsTermswithDetailsByClientId',
  GetAllLastPreformance: 'Reports/Performance/GetAllLastPerformanceScoreByClientId',
  GetAllNextPreformance: 'Reports/Performance/GetAllNextPerformanceReviewByClientId',
  GetCourseCompleted: 'Reports/Learnings/GetAllCoursesCompletedByClientId',
  GetCourseOverDue: 'Reports/Learnings/GetAllCoursesOverdueByClientId',
  GetCourseAssigned: 'Reports/Learnings/GetAllCoursesAssignedByClientId',
  GetTurnOverDetails: 'Reports/Organizational/GetAllTurnoverWithDetailsByClientId',
  GetCompaRation: 'Reports/Performance/GetAllCompaRatioByClientId',
  GetAllEmployeesEthnicity: 'Reports/DiversityEquityInclusion/GetAllEmployeesByEthnicityByClientId',
  GetAllEmployesGender: 'Reports/DiversityEquityInclusion/GetAllEmployeesByGenderByClientId',
  GetAllPayEthnicity: 'Reports/DiversityEquityInclusion/GetAllPayByEthnicityByClientId',
  GetAllPayGender: 'Reports/DiversityEquityInclusion/GetAllPayByGenderByClientId',
  GetAllTenureRace: 'Reports/DiversityEquityInclusion/GetAllTenureByRaceByClientId',
  GetDemographics: 'Reports/Organizational/EmployeeDemographics',
  GetOverAllSurveyResult: 'Reports/EmployeeEngagement/GetOverallSurveyResults',
  GetAllScoreByCategory: 'Reports/EmployeeEngagement/GetAllScoresByCategory',
  GetAllTimeToFill: 'Reports/Recruiting/GetAllTimetoFillByClientId',
  GetOpenRequisition: 'Reports/Recruiting/GetAllOpenRequisitionwithDetailsByClientId',
  GetHiredCandidates: 'Reports/Recruiting/GetAllHiredCandidateswithDetailsByClientId',
  GetOverAllBreakDown: 'Reports/DiversityEquityInclusion/OverallBreakdown',
  GetSurveySubmission: 'Reports/EmployeeEngagement/GetAllSurveySubmissionRate',
  GetOfferedStart: 'Reports/Recruiting/GetOfferedToStartedTimeframeByClientId',
};

export const EE_DASHBOARD = {
  OrgChart: 'EmployeeEngagements/GetEENetworkGraphAndMagicScoreCompanyLevel',
  OrgChart1: 'EmployeeEngagements/GetEENetworkGraphAndMagicScoreManagerLevel',
  OrgChart2: 'EmployeeEngagements/GetEENetworkGraphAndMagicScoreEmployeeLevel',
  OrgList: 'EmployeeEngagements/GetEmployeeList',
  ViewMore: 'EmployeeEngagements/GetSatisfactionScoreMonthwise',
  ViewMoreMagic: 'EmployeeEngagements/GetSatisfactionScoreAverageData',
  GetEmployeesBySearch: 'EmployeeEngagements/GetListOfEmployeesBySearch',
  GetAllDirectReports: 'EmployeeEngagements/GetAllDirectReports',
  GetEmployeeEngagementSummary: 'EmployeeEngagements/GetEmployeeEngagementSummary',
  GetClientSurveyResponses: 'EmployeeEngagements/GetClientSurveyResponses',
  GetEmployeeSurveyResults: 'EmployeeEngagements/GetEmployeeSurveyResults'
};

export const ORGANIZTION_DASHBOARD = {
  HiresAndTerms: 'Organizational/GetHiresandTermsByMonth',
  PopuationTernue: 'Organizational/GetCurrentEmployeePopulationTenureByClientId',
  HeadCountTurnOverRadio: 'Organizational/GetAvgHeadCountVsTurnoverRatioByClientId',
  TurnoverTermination: 'Organizational/GetTurnoverAndTerminationByClientId',
};

export const DEI_DASHBOARD = {
  EmployeeEthnicity: 'DiversityEquityInclusion/GetEmployeesCountByEthnicity',
  EmployeeGender: 'DiversityEquityInclusion/GetEmployeesCountByGender',
  TenureRace: 'DiversityEquityInclusion/GetTenureByRace',
  HiresRace: 'DiversityEquityInclusion/GetHiresByRace',
};

export const DASHBOARD = {
  HeadCount: 'Dashboard/GetHeadcount',
  HiresTerm: 'Dashboard/GetHiresvsTerms',
  GetTurnover: 'Dashboard/GetTurnover',
  HeadCountMonthWise: 'Dashboard/GetHeadcountMonthwise',
  OpenRequisition: 'Dashboard/GetOpenRequisitions',
  NetWorkGraph: 'Dashboard/GetEENetworkGraphAndMagicScoreCompanyLevel',
  EngagementScore: 'Dashboard/GetEngagementScore',
  Calendar: 'Dashboard/GetCalendar',
  EngagementMonth: 'Dashboard/GetEngagementScoreMonthwise',
  EngagementCount: 'Dashboard/GetAIModelResultCount',
};

export const PERFORMANCE = {
  PerformanceRating: 'Performance/GetPerformancePieChart',
  CompaRatio: 'Performance/GetPerformanceCompaRatioScatterGraph',
  SalaryDistribution: 'Performance/GetSalaryDistribution',
};

export const RECRUITING_DASHBOARD = {
  CandidateByScore: 'Recruiting/GetCandidatesBySourceYTD',
  AverageTime: 'Recruiting/GetAverageTimetoHire',
  CandidateActivity: 'Recruiting/GetCandidateActivityYTD',
  OpenRecruiting: 'Recruiting/GetOpenRequisitions',
};

export const COLORS = [
  '#344A5C',
  '#0CAFC6',
  '#C3D34F',
  '#241A2E',
  '#0DA728',
  '#C60C68',
  '#34D28C',
  '#DE8450',
  '#363a64',
  '#244e53',
  '#6e7537',
  '#21043e',
  '#272241',
  '#782f54',
  '#357e5d',
  '#88eabe',
  '#1c5564',
  '#ef08d6',
  '#b2f9e6',
  '#b784d9',
  '#79267d',
];
