import React from 'react';
import Employee from 'pages/Common/Employee';
import { useParams } from 'react-router-dom';

const Employees = () => {

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const urlParams: any = useParams();
  return <Employee clientId={urlParams.companyId} from={'admin'} />;
};

export default Employees;
