import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

interface LoaderProps {
  loading: boolean;
}

const PageLoader = (props: LoaderProps) => {
  const { loading } = props;
  return (
    <Backdrop
      sx={{ color: '#C3D34F', zIndex: (theme) => theme.zIndex.drawer + 10000 }}
      open={loading}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default PageLoader;
