'use client'
import { addNewData } from 'services/CommonServices';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Loader from 'components/Loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { SURVEY_EDITOR_ENDPOINT } from 'utils/Constant';
import {
    Grid,
    Typography,
    Modal,
    Box,
    Button,
    Popover
} from '@mui/material';
import React, { useEffect, useState } from 'react';

interface SurveyQuestionsProps {
    surveyQuestionsChanged: boolean,
    setSurveyQuestionsChanged: any
}

const Questions = (props: SurveyQuestionsProps) => {
    const [surveyQuestions, setSurveyQuestions] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [openPop, setOpen] = useState(false);
    
    const [activeQuestion, setActiveQuestion] = useState<any>({ questionId: 0, question: '', categoryId: 0, categoryName: '' });
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [cItem, setCItem] = React.useState<any>({});

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClosePopup = (event: any, reason: any) => {
        if (reason && reason === 'backdropClick')
            return;
        setOpen(false);
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, item: any) => {
        setAnchorEl(event.currentTarget);
        setCItem(item);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCItem({});
    };

    const [isActive, setIsActive] = useState<boolean>(true);
    const [questionText, setQuestionText] = useState('');

    const tableData = React.useMemo(() => {
        if (surveyQuestions.length) {
            return surveyQuestions.map((row: any, index: number) => (
                <TableRow key={index} sx={{ ' &:last-child th': { border: 0 } }}>
                    <TableCell>
                        <Typography
                            component={'div'}
                            className='company-name'>{`${row?.categoryName}`}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            component={'div'}
                            className='company-name'>{`${row?.question}`}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography
                            component={'div'}
                            className='company-name'>{`${row?.active}`}</Typography>
                    </TableCell>
                    <TableCell align='right'>
                        <Button aria-describedby={id} onClick={(e) => handleClick(e, row)}>
                            <MoreVertIcon />
                        </Button>
                    </TableCell>
                </TableRow>
            ));
        }
        return (
            <TableRow>
                <TableCell colSpan={5} align='center'>
                    <Typography component={'div'} className='d-flex-ja norecord'>
                        No Records Found
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }, [surveyQuestions]);

    const handleEditClick = (id: number) => {
        console.log(id);
        const question = surveyQuestions.find((q) => q?.questionId == id);
        setActiveQuestion(question);
        setIsActive(question.active);
        setQuestionText(question.question);
        setOpen(true);
    }   

    useEffect(() => {
        props.setSurveyQuestionsChanged(false);
        getQuestions();        
    }, [props.surveyQuestionsChanged]);

    const getQuestions = () => {
        addNewData(
            {},
            `${SURVEY_EDITOR_ENDPOINT.GetQuestions}`,
            {},
            true,
        )
            .then((response: any) => {
                if (response?.surveyQuestions?.length > 0) {
                    setSurveyQuestions(response?.surveyQuestions);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const activeCheckboxChanged = (e: any) => {
        setIsActive(e.target.checked);
        console.log(e.target.checked);
    }

    const questionTextChanged = (e: any) => {
        e.preventDefault();
        setQuestionText(e.target.value);
    }

    const closeAndSave = () => {
        addNewData(
            {
                questionId: activeQuestion.questionId,
                active: isActive,
                questionText: questionText
            },
            `${SURVEY_EDITOR_ENDPOINT.UpdateSurveyQuestion}`,
            {},
            true,
        )
            .then(() => {
                getQuestions();
            })
            .catch(error => {
                alert('Error updating question. Please try again later ' + error);
            })
            .finally(() => {
                setLoading(false);
            });

        setOpen(false);
    }

    const closeNoSave = () => {
        setOpen(false);
    }

    return (
        <Grid container className='d-flex'>
            <Grid item md={12} sm={12} xs={12}>
                <TableContainer component={Paper} className='companie-table'>
                    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '25%' }}>
                                    <Typography component={'div'} className='d-flex-a'>
                                        <Typography className='th-title'>Category</Typography>
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ width: '75%' }}>
                                    <Typography component={'div'} className='d-flex-a'>
                                        <Typography className='th-title'>Question</Typography>
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ width: '15%' }}>
                                    <Typography component={'div'} className='d-flex-a'>
                                        <Typography className='th-title'>Active</Typography>
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ width: '10%' }}>
                                    <Typography component={'div'} className='d-flex-a'>
                                        <Typography className='th-title'></Typography>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{loading ? <Loader loading={loading} colsSpan={5} /> : tableData}</TableBody>
                    </Table>
                </TableContainer>
                <Modal
                    className='profile-modal'
                    open={openPop}
                    onClose={handleClosePopup}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'>
                    <Box component='div'  sx={style}>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography id='modal-modal-title' className='modal-title'>
                                Edit Survey Question
                            </Typography>
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                            <Grid item md={3} sm={12} xs={12}>
                                <Typography className='labels'>
                                   Category:
                                </Typography>
                            </Grid>
                            <Grid item md={9} sm={12} xs={12}>
                                <Typography className='details'>{activeQuestion.categoryName}</Typography>
                            </Grid>
                        </Grid>
                        <Typography id='details' sx={{ mt: 1 }}>
                            <textarea onChange={questionTextChanged}
                                value={questionText}
                                rows={3}
                                cols={60} />
                        </Typography>
                        <Typography id='details' sx={{ mt: 1 }}>
                            <label>
                                <input
                                    type='checkbox'
                                    onChange={activeCheckboxChanged}
                                    checked={isActive}
                                    required={true}
                                /> Active
                            </label>
                        </Typography>
                        <Typography id='details' sx={{ mt: 1 }}>
                            <button className='modal-yes1-btn' style={{ width: 100 }} onClick={closeAndSave}>OK</button>
                            <button className='modal-yes-btn' style={{ marginLeft: 10, width: 100 }} onClick={closeNoSave}>Cancel</button>
                        </Typography>
                    </Box>
                </Modal>                
            </Grid>
            <Popover
                className='edit-popup'
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <Typography className='arrow-top' />
                <Typography
                    className='pop-item'
                    onClick={() => handleEditClick(cItem?.questionId) }>
                    Edit Question
                </Typography>
            </Popover>
        </Grid>
    );
}

export default Questions;