export const TurnOverTable = [
  { name: 'Date', value: '' },
  { name: 'Beginning Of the Month Count', value: '' },
  { name: 'End of the Month Count', value: '' },
  { name: 'Terminations TO Ratio', value: '' },
];

export const CostOfTurnOver = [
  { name: 'Last Name', value: 'lName', minWidth: '100px' },
  { name: 'First Name', value: 'fName', minWidth: '100px' },
  { name: 'Employee ID', value: 'employeeId', minWidth: '100px' },
  { name: 'Direct Supervisor', value: 'directSupervisor', minWidth: '130px' },
  { name: 'Date', value: 'date', minWidth: '100px', date: true, format: 'MM-YYYY' },
  { name: 'Hire Date', value: 'hireDate', minWidth: '100px', date: true, format: 'MM-DD-YYYY' },
  { name: 'Term Date', value: 'termDate', minWidth: '100px', date: true, format: 'MM-DD-YYYY' },
  { name: 'Job Code', value: 'jobCode', minWidth: '100px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '130px' },
  { name: 'Term Type', value: 'termType' },
  { name: 'Turnover Reason', value: 'turnoverReason', minWidth: '130px' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '150px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];

export const HeadCountMonthOverMonthDetails = [
  { name: 'Employee ID', value: 'client2EmployeeId', minWidth: '100px' },
  { name: 'Year Month', value: 'yearMonth', minWidth: '100px' },
  { name: 'Name', value: 'name', minWidth: '130px' },
  { name: 'Hire Date', value: 'hireDate', minWidth: '130px', date: true, format: 'MM-DD-YYYY' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '180px' },
  { name: 'Annual Rate', value: 'annualRate', astric: true },
  { name: 'Payband', value: 'payBand', minWidth: '200px' },
  { name: 'Performance Rating', value: 'performanceRating', minWidth: '150px', amount: true },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '150px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];

export const HeadCountWDetails = [
  { name: 'Last Name', value: 'lName', minWidth: '100px' },
  { name: 'First Name', value: 'fName', minWidth: '100px' },
  { name: 'Employee ID', value: 'employeeId', minWidth: '100px' },
  { name: 'Hire Date', value: 'hireDate', minWidth: '100px', date: true, format: 'MM-DD-YYYY' },
  { name: 'Job Code', value: 'jobCode', minWidth: '10px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '200px' },
  { name: 'Direct Supervisor', value: 'directSupervisor', minWidth: '150px' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '200px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];
export const organizationalchart = [
  { name: 'Employee', value: 'employee', minWidth: '130px' },
  { name: 'Direct Supervisor', value: 'directSupervisor', minWidth: '130px' },
];

export const HiresvsTerms = [
  { name: 'Month', value: 'yearMonth', date: true, format: 'MMM-YYYY', minWidth: '100px' },
  { name: 'Total Hired', value: 'totalHired', minWidth: '130px' },
  { name: 'Total Termination', value: 'totalTermination', minWidth: '130px', plus: true },
];

export const EmployeeDemographics = [
  { name: 'Last Name', value: 'lName', minWidth: '100px' },
  { name: 'First Name', value: 'fName', minWidth: '100px' },
  { name: 'Employee ID', value: 'employeeId', minWidth: '100px' },
  { name: 'Date of Birth', value: 'dob', date: true, format: 'MM-DD-YYYY', minWidth: '100px' },
  { name: 'Work Email', value: 'workEmail', minWidth: '100px' },
  { name: 'Direct Supervisor', value: 'directSupervisor', minWidth: '100px' },
  { name: 'Personal Email', value: 'personalEmail', minWidth: '100px' },
  { name: 'Address 1', value: 'address1', minWidth: '150px' },
  { name: 'Address 2', value: 'address2', minWidth: '150px' },
  { name: 'City', value: 'city', minWidth: '100px' },
  { name: 'State', value: 'state', minWidth: '100px' },
  { name: 'Zip Code', value: 'zip', minWidth: '100px' },
  { name: 'Hourly Rate', value: 'hourlyRate', minWidth: '100px', astric: true },
  { name: 'Annual Rate', value: 'annualRate', minWidth: '100px', astric: true },
  { name: 'Pay Method', value: 'payMethod', minWidth: '100px' },
  { name: 'Standard Hours', value: 'standardHours', minWidth: '100px' },
  { name: 'Pay Frequency', value: 'payFrequency', minWidth: '100px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '100px' },
  { name: 'Class Code1', value: 'classCode1Description', minWidth: '100px' },
  { name: 'Class Code2', value: 'classCode2Description', minWidth: '100px' },
  { name: 'Class Code3', value: 'classCode3Description', minWidth: '100px' },
  { name: 'Class Code4', value: 'classCode4Description', minWidth: '100px' },
  { name: 'Class Code5', value: 'classCode5Description', minWidth: '100px' },
];

export const LastPreformance = [
  { name: 'Last Name', value: 'lName', minWidth: '130px' },
  { name: 'First Name', value: 'fName', minWidth: '130px' },
  { name: 'Employee ID ', value: 'employeeId', minWidth: '130px' },
  { name: 'Performance Rating', value: 'performanceRating', amount: true, minWidth: '150px' },
  { name: 'Direct Supervisor ', value: 'directSupervisor', minWidth: '130px' },
  { name: 'Hire Date', value: 'hireDate', minWidth: '100px', date: true, format: 'MM-DD-YYYY' },
  { name: 'Job Code', value: 'jobCode', minWidth: '100px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '180px' },
  { name: 'Annual Rate', value: 'annualRate', minWidth: '100px', astric: true },
  { name: 'Term Date', value: 'term_Date', minWidth: '100px', date: true, format: 'MM-DD-YYYY' },
  { name: 'Pay Band', value: 'payBand', minWidth: '180px' },
  { name: 'Term Type', value: 'termType' },
  { name: 'Turnover Reason', value: 'turnoverReason', minWidth: '150px' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '150px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '150px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '200px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '150px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '200px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '150px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '150px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];

export const NextPreformance = [
  { name: 'Category', value: 'category', minWidth: '100px' },
  { name: 'Last Name', value: 'lName', minWidth: '100px' },
  { name: 'First Name', value: 'fName', minWidth: '100px' },
  { name: 'Employee', value: 'employee', minWidth: '130px' },
  { name: 'Direct Supervisor', value: 'directSupervisor', minWidth: '130px' },
  { name: 'Hire Date', value: 'hireDate', minWidth: '100px', date: true, format: 'MM-DD-YYYY' },
  { name: 'Term Date', value: 'termDate', minWidth: '100px', date: true, format: 'MM-DD-YYYY' },
  { name: 'Job Code', value: 'jobCode', minWidth: '100px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '180px' },
  { name: 'Annual Rate', value: 'annualRate', minWidth: '100px', astric: true },
  { name: 'Age', value: 'age' },
  { name: 'Gender', value: 'gender' },
  { name: 'Ethnicity', value: 'ethnicity', minWidth: '180px' },
  { name: 'Term Date', value: 'term_Date', minWidth: '100px', date: true, format: 'MM-DD-YYYY' },
  { name: 'Tenure', value: 'tenure', amount: true },
  { name: 'Pay Band', value: 'payBand', minWidth: '130px' },
  { name: 'Term Type', value: 'termType' },
  { name: 'Turnover Reason', value: 'turnoverReason', minWidth: '130px' },
  { name: 'Performance Rating', value: 'performanceRating' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '200px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '200px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
  {
    name: 'Effective Start Date',
    value: 'effectiveStartDate',
    minWidth: '130px',
    date: true,
    format: 'MM-DD-YYYY',
  },
  {
    name: 'Effective End Date',
    value: 'effectiveEndDate',
    minWidth: '120px',
    date: true,
    format: 'MM-DD-YYYY',
  },
  { name: 'Status', value: 'status' },
  { name: 'Client 2 EmployeeId', value: 'client2EmployeeId', minWidth: '100px' },
];
export const CourseComplete = [
  { name: 'Last Name', value: 'lName', minWidth: '100px' },
  { name: 'First Name', value: 'fName', minWidth: '100px' },
  { name: 'Employee ID', value: 'employeeID', minWidth: '200px' },
  { name: 'Course Name', value: 'courseName', minWidth: '200px' },
  {
    name: 'Course Started Date',
    value: 'courseStartedDate',
    date: true,
    format: 'MM-DD-YYYY',
    minWidth: '100px',
  },
  {
    name: 'Course Completed Date',
    value: 'courseCompletedDate',
    date: true,
    format: 'MM-DD-YYYY',
    minWidth: '100px',
  },
  { name: 'User Name', value: 'userName', minWidth: '100px' },
  { name: 'Direct Supervisor', value: 'directSupervisor', minWidth: '200px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '150px' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '150px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];
export const CourseOverdue = [
  { name: 'Last Name', value: 'lName', minWidth: '100px' },
  { name: 'First Name', value: 'fName', minWidth: '100px' },
  { name: 'Employee ID', value: 'employeeID', minWidth: '200px' },
  { name: 'Course Name', value: 'courseName', minWidth: '200px' },
  {
    name: 'Course Started Date',
    value: 'courseStartedDate',
    date: true,
    format: 'MM-DD-YYYY',
    minWidth: '100px',
  },
  {
    name: 'Course Assigned Date',
    value: 'courseCreateDate',
    date: true,
    format: 'MM-DD-YYYY',
    minWidth: '100px',
  },
  {
    name: 'Course Due Date',
    value: 'courseDueDate',
    date: true,
    format: 'MM-DD-YYYY',
    minWidth: '100px',
  },
  { name: 'Percentage Complete', value: 'percentageComplete', minWidth: '100px', amount: true },
  { name: 'User Name', value: 'userName', minWidth: '100px' },
  { name: 'Direct Supervisor', value: 'directSupervisor', minWidth: '200px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '150px' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '150px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];

export const CoursesAssigned = [
  { name: 'Last Name', value: 'lName', minWidth: '100px' },
  { name: 'First Name', value: 'fName', minWidth: '100px' },
  { name: 'Employee ID', value: 'employeeID', minWidth: '200px' },
  { name: 'Course Name', value: 'courseName', minWidth: '200px' },
  {
    name: 'Course Started Date',
    value: 'courseStartedDate',
    date: true,
    format: 'MM-DD-YYYY',
    minWidth: '100px',
  },
  {
    name: 'Course Assigned Date',
    value: 'courseCreateDate',
    date: true,
    format: 'MM-DD-YYYY',
    minWidth: '100px',
  },
  {
    name: 'Course Due Date',
    value: 'courseDueDate',
    date: true,
    format: 'MM-DD-YYYY',
    minWidth: '100px',
  },
  { name: 'User Name', value: 'userName', minWidth: '100px' },
  { name: 'Direct Supervisor', value: 'directSupervisor', minWidth: '200px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '150px' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '150px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];

export const TurnOverDetails = [
  {
    name: 'Date',
    value: 'date',
    minWidth: '120px',
    date: true,
    format: 'MM-YYYY',
  },
  {
    name: 'Beginning Month Count',
    value: 'beginningOfTheMonthCount',
  },

  {
    name: 'End Month Count',
    value: 'endOfTheMonthCount',
  },
  { name: 'Hires', value: 'hires' },
  {
    name: 'Termination',
    value: 'terminations',
  },
  {
    name: 'Ratio',
    value: 'toRatio',
    amount: true,
  },
];

export const CompaRation = [
  { name: 'Last Name', value: 'lName', minWidth: '150px' },
  { name: 'First Name', value: 'fName', minWidth: '150px' },
  { name: 'Employee ID', value: 'employeeID', minWidth: '150px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '150px' },
  { name: 'Status', value: 'status', minWidth: '150px' },
  { name: 'Hourly Rate', value: 'hourlyRate', minWidth: '150px', astric: true },
  { name: 'Annual Rate', value: 'annualRate', minWidth: '150px', astric: true },
  { name: 'Pay Method', value: 'payMethod', minWidth: '150px' },
  { name: 'Standard Hours', value: 'standardHours', minWidth: '150px' },
  { name: 'Pay Frequency', value: 'payFrequency', minWidth: '150px' },
  { name: 'Compa Ratio%', value: 'compaRatio', minWidth: '150px', percentage: true },
  { name: 'Payband Mid', value: 'midNumber', minWidth: '150px', astric: true },
  { name: 'Tenure', value: 'tenure', minWidth: '100px', amount: true },
  { name: 'Hire Date', value: 'hireDate', date: true, format: 'MM-DD-YYYY', minWidth: '100px' },
  { name: 'Term Date', value: 'termDate', date: true, format: 'MM-DD-YYYY', minWidth: '100px' },
  { name: 'Term Type', value: 'termType', minWidth: '100px' },
  { name: 'Turnover Reason', value: 'turnoverReason', minWidth: '150px' },
  { name: 'Class Code1', value: 'class1', minWidth: '150px' },
  { name: 'Class Code2', value: 'class2', minWidth: '190px' },
  { name: 'Class Code3', value: 'class3', minWidth: '190px' },
  { name: 'Class Code4', value: 'class4', minWidth: '150px' },
  { name: 'Class Code5', value: 'class5', minWidth: '150px' },
];

export const EmployeeEthnicity = [
  { name: 'Name', value: 'name', minWidth: '130px' },
  { name: 'Job Code', value: 'jobCode', minWidth: '100px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '130px' },
  { name: 'Annual Rate', value: 'annualRate', minWidth: '100px', astric: true },
  { name: 'Age', value: 'age' },
  { name: 'Gender', value: 'gender' },
  { name: 'Ethnicity', value: 'ethnicity', minWidth: '180px' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '150px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];

export const EmployeeGender = [
  { name: 'Name', value: 'name', minWidth: '130px' },
  { name: 'Job Code', value: 'jobCode', minWidth: '100px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '130px' },
  { name: 'Annual Rate', value: 'annualRate', minWidth: '100px', astric: true },
  { name: 'Age', value: 'age' },
  { name: 'Gender', value: 'gender' },
  { name: 'Ethnicity', value: 'ethnicity', minWidth: '180px' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '150px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];

export const PayEthnicity = [
  { name: 'Name', value: 'name', minWidth: '130px' },
  { name: 'Job Code', value: 'jobCode', minWidth: '100px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '130px' },
  { name: 'Annual Rate', value: 'annualRate', minWidth: '100px', astric: true },
  { name: 'Hourly Rate', value: 'hourlyRate', minWidth: '100px', astric: true },
  { name: 'Pay Method', value: 'payMethod', minWidth: '100px' },
  { name: 'Age', value: 'age' },
  { name: 'Gender', value: 'gender' },
  { name: 'Ethnicity', value: 'ethnicity', minWidth: '180px' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '150px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];

export const PayGender = [
  { name: 'Name', value: 'name', minWidth: '130px' },
  { name: 'Job Code', value: 'jobCode', minWidth: '100px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '130px' },
  { name: 'Annual Rate', value: 'annualRate', minWidth: '100px', astric: true },
  { name: 'Age', value: 'age' },
  { name: 'Gender', value: 'gender' },
  { name: 'Ethnicity', value: 'ethnicity', minWidth: '180px' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '150px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];

//  its has been renamed has over all Tenure
export const TenureRace = [
  { name: 'Name', value: 'name', minWidth: '130px' },
  { name: 'Job Code', value: 'jobCode', minWidth: '100px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '130px' },
  { name: 'Annual Rate', value: 'annualRate', minWidth: '100px', astric: true },
  { name: 'Age', value: 'age' },
  { name: 'Gender', value: 'gender' },
  { name: 'Ethnicity', value: 'ethnicity', minWidth: '180px' },
  { name: 'Tenure', value: 'tenure', amount: true },
  { name: 'Hire Date', value: 'hireDate', minWidth: '100px', date: true, format: 'MM-DD-YYYY' },
  { name: 'Term Date', value: 'term_Date', minWidth: '100px', date: true, format: 'MM-DD-YYYY' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '150px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];

export const OverallSurveyResults = [
  { name: 'Last Name', value: 'lName', minWidth: '100px' },
  { name: 'First Name', value: 'fName', minWidth: '100px' },
  { name: 'Employee ID', value: 'employeeId', minWidth: '100px' },
  {
    name: 'Date',
    value: 'date',
    minWidth: '120px',
    date: true,
    format: 'MM-DD-YYYY',
  },
  { name: 'Question', value: 'question', minWidth: '200px' },
  { name: 'Category', value: 'category', minWidth: '150px' },
  { name: 'Score', value: 'score', minWidth: '100px' },
  { name: 'Direct Supervisor', value: 'directSupervisor', minWidth: '150px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '150px' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '150px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];

export const ScoresbyCategory = [
  { name: 'Last Name', value: 'lName', minWidth: '100px' },
  { name: 'First Name', value: 'fName', minWidth: '100px' },
  { name: 'Employee ID', value: 'employeeId', minWidth: '100px' },
  { name: 'Purpose', value: 'meaning', minWidth: '150px', amount: true },
  { name: 'Independence', value: 'autonomy', minWidth: '150px' },
  { name: 'Growth', value: 'growth', minWidth: '150px' },
  { name: 'Impact', value: 'impact', minWidth: '150px' },
  { name: 'Relationship', value: 'connection', minWidth: '150px' },
  { name: 'Capacity', value: 'capacity', minWidth: '150px' },
  { name: 'Average Score', value: 'averageScore', amount: true, minWidth: '150px' },
  { name: 'Direct Supervisor', value: 'directSupervisor', minWidth: '150px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '150px' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '150px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];

export const TimetoFill = [
  { name: 'Job Title', value: 'jobTitle', minWidth: '150px' },
  { name: 'Name', value: 'name', minWidth: '150px' },
  { name: 'Source', value: 'source', minWidth: '150px' },
  { name: 'Location', value: 'location', minWidth: '150px' },
  { name: 'Department', value: 'dept', minWidth: '150px' },
  { name: 'Req Owner', value: 'reqOwner', minWidth: '150px' },
  { name: 'Req Number', value: 'reqNumber', minWidth: '150px' },
  { name: 'Hiring Manager', value: 'hm', minWidth: '150px' },
  { name: 'Annual Comp', value: 'annualComp', minWidth: '150px', amount: true },
  { name: 'Hourly Comp', value: 'hourlyComp', minWidth: '150px', amount: true },
  {
    name: 'Date Opened',
    value: 'created',
    minWidth: '120px',
    date: true,
    format: 'MM-DD-YYYY',
  },
  {
    name: 'Hired Date',
    value: 'closed',
    minWidth: '120px',
    date: true,
    format: 'MM-DD-YYYY',
  },
  { name: 'Days to Fill', value: 'daysToFill', minWidth: '150px', amount: true },
];

export const OpenRequisition = [
  { name: 'Job Title', value: 'jobTitle' },
  { name: 'Requisition Number', value: 'requisitionNumber', minWidth: '150px' },
  { name: 'Date Opened', value: 'dateOpened', minWidth: '150px', date: true, format: 'MM-DD-YYYY' },
  { name: 'Days Open', value: 'daysOpen' },
  { name: 'Location', value: 'location', minWidth: '150px' },
  { name: 'Department', value: 'department' },
  { name: 'Recruiter', value: 'recruiter' },
  { name: 'Hiring Manager', value: 'hiringManager' },
  { name: 'Applicants', value: 'applicants' },
  { name: 'Interview', value: 'interview' },
  { name: 'Offers', value: 'offers' },
  { name: 'Hires', value: 'hires' },
];

export const hiredCandidates = [
  { name: 'Name', value: 'name', minWidth: '150px' },
  { name: 'Address1', value: 'add1', minWidth: '150px' },
  { name: 'Address2', value: 'add2', minWidth: '150px' },
  { name: 'City', value: 'city', minWidth: '150px' },
  { name: 'State', value: 'state', minWidth: '150px' },
  { name: 'Zip Code', value: 'zip', minWidth: '150px' },
  { name: 'Location', value: 'location', minWidth: '150px' },
  { name: 'Source', value: 'source', minWidth: '150px' },
  { name: 'Department', value: 'dept', minWidth: '150px' },
  { name: 'Req Owner', value: 'reqOwner', minWidth: '150px' },
  { name: 'HM', value: 'hm', minWidth: '150px' },
  { name: 'Annual Comp', value: 'annualComp', minWidth: '150px', amount: true },
  { name: 'Hourly Comp', value: 'hourlyComp', minWidth: '150px', amount: true },
  {
    name: 'Created Date',
    value: 'created',
    minWidth: '120px',
    date: true,
    format: 'MM-DD-YYYY',
  },
  {
    name: 'Closed Date',
    value: 'closed',
    minWidth: '120px',
    date: true,
    format: 'MM-DD-YYYY',
  },
];
export const offeredStart = [
  { name: 'Name', value: 'name', minWidth: '150px' },
  { name: 'Requisition Number', value: 'reqNumber', minWidth: '150px' },
  { name: 'Requisition Owner', value: 'reqOwner', minWidth: '150px' },
  { name: 'Hiring Manager', value: 'hiringManager', minWidth: '150px' },
  { name: 'Days from Hire to Onboard', value: 'daysFromHiretoOnboard', minWidth: '120px' },
];

// its has been renamed as Overall DE&I Breakdown With Pay

export const OverallBreakdown = [
  { name: 'Last Name', value: 'lName', minWidth: '150px' },
  { name: 'First Name', value: 'fName', minWidth: '150px' },
  { name: 'Employee ID', value: 'employeeId', minWidth: '150px' },
  { name: 'Status', value: 'status', minWidth: '150px' },
  { name: 'Age', value: 'age', minWidth: '150px' },
  { name: 'Gender', value: 'gender', minWidth: '150px' },
  { name: 'Ethnicity', value: 'ethnicity', minWidth: '150px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '150px' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '150px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];
export const SurveySubmissionRate = [
  { name: 'Last Name', value: 'lName', minWidth: '100px' },
  { name: 'First Name', value: 'fName', minWidth: '100px' },
  { name: 'Employee ID', value: 'employeeID', minWidth: '150px' },
  { name: 'Sent', value: 'sent', minWidth: '100px' },
  { name: 'Submitted', value: 'submitted', minWidth: '100px' },
  { name: 'Submission Rate', value: 'submissionRate', minWidth: '100px' },
  { name: 'Direct Supervisor', value: 'directSupervisor', minWidth: '150px' },
  { name: 'Job Title', value: 'jobTitle', minWidth: '150px' },
  { name: 'Class Code1', value: 'classCode1', minWidth: '100px' },
  { name: 'Class Code1 Description', value: 'classCode1Description', minWidth: '150px' },
  { name: 'Class Code2', value: 'classCode2', minWidth: '100px' },
  { name: 'Class Code2 Description', value: 'classCode2Description', minWidth: '150px' },
  { name: 'Class Code3', value: 'classCode3', minWidth: '100px' },
  { name: 'Class Code3 Description', value: 'classCode3Description', minWidth: '150px' },
  { name: 'Class Code4', value: 'classCode4', minWidth: '100px' },
  { name: 'Class Code4 Description', value: 'classCode4Description', minWidth: '150px' },
  { name: 'Class Code5', value: 'classCode5', minWidth: '100px' },
  { name: 'Class Code5 Description', value: 'classCode5Description', minWidth: '150px' },
];
