import { Grid, IconButton, Menu, MenuItem, Typography, Tooltip as TooltipMUI } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import InfoIcon from 'assets/png/info.png';
import React, { useEffect, useRef} from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  ResponsiveContainer
} from 'recharts';
import { getAllListData } from 'services/CommonServices';
import { COLORS, EE_DASHBOARD } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import ScreenLoader from 'components/ScreenLoader';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import domtoimage from 'dom-to-image';
import fileDownload from 'js-file-download';
import Logo from 'assets/png/LogoDarkPng.png';
import { EyeToolTipTextOverAll } from 'utils/Config';
import { FilterDataContext } from 'context/FilterDataContextProvider';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EmployeeDetailPopOut from 'components/EmployeeDetailPopOut';
import PageLoader from 'components/PageLoader';

const COLOR: { [key: string]: string } = {
  'GoodStanding': '#344A5C',
  'OnTheMove': '#C3D34F',
  'NeedsAttention': '#C60C68',
};


const EmployeeEngagementSummary = () => {
  const downloadRef0: any = useRef(null);
  const downloadRef1: any = useRef(null);
  const downloadRef2: any = useRef(null);
  const downloadRef3: any = useRef(null);
  const downloadRef4: any = useRef(null);
  const downloadRef5: any = useRef(null);
  const [loader1, setLoader1] = React.useState<boolean>(false);
  const [drawer, setDrawer] = React.useState(false);
  const [details, setDetails] = React.useState<any>([]);
  const [viewMoreDetails, setViewMoreDetails] = React.useState<any>({});
  const [moreDetails, setMoreDetails] = React.useState<any>([]);
  const [hideNeedsAttention, setHideNeedsAttention] = React.useState(false);
  const [hideOnTheMove, setHideOnTheMove] = React.useState(false);
  const [hideGoodStanding, setHideGoodStanding] = React.useState(false);
  const { userInfo: userDetail, profileInfo }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });
  const [engagementSummaryLoader, setEngagementSummaryLoader] = React.useState<boolean>(true);
  const [engagementSummaryList, setEngagementSummaryList] = React.useState<any>([]);

  const [surveyResponseLoader, setSurveyResponseLoader] = React.useState<boolean>(false);
  const [surveyTopResponseList, setSurveyTopResponseList] = React.useState<any>([]);
  const [surveyTopResponseChartList, setSurveyTopResponseChartList] = React.useState<any>([]);
  const [surveyBottomResponseList, setSurveyBottomResponseList] = React.useState<any>([]);
  const [surveyBottomResponseChartList, setSurveyBottomResponseChartList] = React.useState<any>([]);
  const [tab, setTab] = React.useState<any>('All');

  const [className, setClassName] = React.useState('');

  const [needsAttentionList, setNeedsAttentionList] = React.useState<any>([]);
  const [needsAttentionChartList, setNeedsAttentionChartList] = React.useState<any>([]);
  const [onTheMoveList, setOnTheMoveList] = React.useState<any>([]);
  const [onTheMoveChartList, setOnTheMoveChartList] = React.useState<any>([]);
  const [inGoodStandingList, setInGoodStandingList] = React.useState<any>([]);
  const [inGoodStandingChartList, setInGoodStandingChartList] = React.useState<any>([]);

  const [employeeSummaryLoader, setEmployeeSummaryLoader] = React.useState<boolean>(true);
  const [employeeSummaryList, setEmployeeSummaryList] = React.useState<any>([]);

  const [hideIcon, setHideIcon] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dwnldIndex, setDwnldIndex] = React.useState<any>({
    index: 0,
    name: '',
  });

  const { filterData, showNumbers, showCharts } = React.useContext(FilterDataContext);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, idx: number, name: string) => {
    setAnchorEl(event.currentTarget);
    setDwnldIndex({
      index: idx,
      name: name,
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDwnldIndex({
      index: 0,
      name: '',
    });
  };

  useEffect(() =>
  {
    downloadRef0.current.style.minHeight = '450px';
    downloadRef1.current.style.minHeight = '450px';

    if(downloadRef0.current?.clientHeight > downloadRef1.current?.clientHeight){
      downloadRef1.current.style.minHeight = `${downloadRef0.current?.clientHeight}px`;
    }
    else if(downloadRef1.current?.clientHeight > downloadRef0.current?.clientHeight){
      downloadRef0.current.style.minHeight = `${downloadRef1.current?.clientHeight}px`;
    }

    downloadRef2.current.style.minHeight = '450px';
    downloadRef3.current.style.minHeight = '450px';

    if(downloadRef2.current?.clientHeight > downloadRef3.current?.clientHeight){
      downloadRef3.current.style.minHeight = `${downloadRef2.current?.clientHeight}px`;
    }
    else if(downloadRef3.current?.clientHeight > downloadRef2.current?.clientHeight){
      downloadRef2.current.style.minHeight = `${downloadRef3.current?.clientHeight}px`;
    }

    downloadRef4.current.style.minHeight = '450px';
    downloadRef5.current.style.minHeight = '450px';

    if(downloadRef4.current?.clientHeight > downloadRef5.current?.clientHeight){
      downloadRef5.current.style.minHeight = `${downloadRef4.current?.clientHeight}px`;
    }
    else if(downloadRef5.current?.clientHeight > downloadRef4.current?.clientHeight){
      downloadRef4.current.style.minHeight = `${downloadRef5.current?.clientHeight}px`;
    }
  }, [showCharts])

  const CustomEngagementSummaryTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'} className='last-valountry-blue'>
            {payload[0].payload.key}
          </Typography>
          <Typography component={'p'} className='valountry-blue' style={hideNeedsAttention ? {display: 'none'} : {}} >
            {'Needs Attention'}:{' '}
            {Math.round(payload[0].payload.needsAttention)}
          </Typography>
          <Typography component={'p'} className='valountry-blue' style={hideOnTheMove ? {display: 'none'} : {}}>
            {'On The Move'}:{' '}
            {Math.round(payload[0].payload.onTheMove)}
          </Typography>
          <Typography component={'p'} className='valountry-blue' style={hideGoodStanding ? {display: 'none'} : {}}>
            {'Good Standing'}:{' '}
            {Math.round(payload[0].payload.goodStanding)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const handleTabChange = (tabName: string) =>{
    setTab(tabName);

    switch(tabName){
      case 'All':
        setHideNeedsAttention(false);
        setHideOnTheMove(false);
        setHideGoodStanding(false);
        break;
      case 'Needs Attention':
        setHideNeedsAttention(false);
        setHideOnTheMove(true);
        setHideGoodStanding(true);
        break;
      case 'On the Move':
        setHideNeedsAttention(true);
        setHideOnTheMove(false);
        setHideGoodStanding(true);
        break;
      case 'Good Standing':
        setHideNeedsAttention(true);
        setHideOnTheMove(true);
        setHideGoodStanding(false);
        break;
      default:
        setHideNeedsAttention(false);
        setHideOnTheMove(false);
        setHideGoodStanding(false);
        break;
    }
  };
 
  const getChartApi = async () => {
    setEngagementSummaryLoader(true);
    setSurveyResponseLoader(true);
    setEmployeeSummaryLoader(true);
    getAllListData(
      `${EE_DASHBOARD.GetEmployeeEngagementSummary}/${userDetail.ClientId}/${userDetail.Id}`,
    )
      .then((resp: any) => {
        setEngagementSummaryList(resp?.data?.totals?.length > 0 ? resp?.data?.totals : []);
        setClassName(resp?.data?.className);

        const needsAttnLst = resp?.data?.details.map((entry: any, index: number) => {         
          return {
            id: index,
            title: entry.title,
            needsAttention: { data: entry.needsAttentionEmployees, title: entry.title },
            needsAttentionValue: entry.needsAttention,
            needsAttentionPercent: { data: entry.needsAttentionEmployees, title: entry.title },
            needsAttentionPercentValue: entry.needsAttentionPercent
          }
        });

        let needsAttnChartLst = [...needsAttnLst].filter((element) => element.needsAttentionValue != 0);
        needsAttnChartLst= needsAttnChartLst.sort((a: any, b: any) => b.needsAttentionValue - a.needsAttentionValue);

        if(needsAttnChartLst.length > 15){
          needsAttnChartLst = needsAttnChartLst.slice(0,15);
        }

        setNeedsAttentionChartList(needsAttnChartLst);

       if (needsAttnLst) {
          // Sort
          if (showNumbers) {
            setNeedsAttentionList(needsAttnLst.sort((a: any, b: any) => b.needsAttentionValue - a.needsAttentionValue));
          }
          else {
            setNeedsAttentionList(needsAttnLst.sort((a: any, b: any) => b.needsAttentionPercentValue - a.needsAttentionPercentValue));
          }
        }

        const onTheMvLst = resp?.data?.details.map((entry: any, index: number) => {
          return {
            id: index,
            title: entry.title,
            onTheMove: { data: entry.onTheMoveEmployees, title: entry.title },
            onTheMoveValue: entry.onTheMove,
            onTheMovePercent: { data: entry.onTheMoveEmployees, title: entry.title },
            onTheMovePercentValue: entry.onTheMovePercent
          }
        });

        let onTheMoveChartLst = [...onTheMvLst].filter((element) => element.onTheMoveValue != 0);
        onTheMoveChartLst= onTheMoveChartLst.sort((a: any, b: any) => b.onTheMoveValue - a.onTheMoveValue);

        if(onTheMoveChartLst.length > 15){
          onTheMoveChartLst = onTheMoveChartLst.slice(0,15);
        }

        setOnTheMoveChartList(onTheMoveChartLst);

        if (onTheMvLst) {
          // Sort
          if (showNumbers) {
            setOnTheMoveList(onTheMvLst.sort((a: any, b: any) => b.onTheMoveValue - a.onTheMoveValue));
          }
          else {
            setOnTheMoveList(onTheMvLst.sort((a: any, b: any) => b.onTheMovePercentValue - a.onTheMovePercentValue));
          }
        }

        const inGoodStndLst = resp?.data?.details.map((entry: any, index: number) => {
          return {
            id: index,
            title: entry.title,
            inGoodStanding: entry.goodStanding,
            inGoodStandingPercent: entry.goodStandingPercent
          }
        });

        let inGoodStandingChartLst = [...inGoodStndLst].filter((element) => element.inGoodStanding != 0);
        inGoodStandingChartLst= inGoodStandingChartLst.sort((a: any, b: any) => b.inGoodStanding - a.inGoodStanding);

        if(inGoodStandingChartLst.length > 15){
          inGoodStandingChartLst = inGoodStandingChartLst.slice(0,15);
        }

        setInGoodStandingChartList(inGoodStandingChartLst);

        if (inGoodStndLst) {
          // Sort
          if (showNumbers) {
            setInGoodStandingList(inGoodStndLst.sort((a: any, b: any) => b.inGoodStanding - a.inGoodStanding));
          }
          else {
            setInGoodStandingList(inGoodStndLst.sort((a: any, b: any) => b.inGoodStandingPercent - a.inGoodStandingPercent));
          }
        }
      })
      .finally(() => {
        setEngagementSummaryLoader(false);
      });

    getAllListData(
      `${EE_DASHBOARD.GetClientSurveyResponses}/${userDetail.ClientId}/${userDetail.Id}`,
    )
      .then((resp: any) => {
        if (resp?.data) {
          const surveyResponseList = resp?.data?.map((entry: any, index: number) => {
            return {
              id: index,
              text: entry.text,
              submitted: entry.submitted,
              sent: entry.sent,
              percentSubmitted: entry.percentSubmitted,
              percentNotSubmitted: entry.percentNotSubmitted
            }
          });

          const surveyBottomResponseList = resp?.data?.map((entry: any, index: number) => {
            return {
              id: index,
              text: entry.text,
              submitted: entry.sent - entry.submitted,
              sent: entry.sent,
              percentSubmitted: entry.percentSubmitted,
              percentNotSubmitted: entry.percentNotSubmitted
            }
          });

          let surveyResponseChartLst = [...surveyResponseList].filter((element) => element.submitted != 0);
          surveyResponseChartLst= surveyResponseChartLst.sort((a: any, b: any) => b.submitted - a.submitted);
  
          if(surveyResponseChartLst.length > 15){
            surveyResponseChartLst = surveyResponseChartLst.slice(0,15);
          }
  
          setSurveyTopResponseChartList(surveyResponseChartLst);

          let surveyResponseBottomChartLst = [...surveyBottomResponseList].filter((element) => element.submitted != 0);
          surveyResponseBottomChartLst= surveyResponseBottomChartLst.sort((a: any, b: any) => b.submitted - a.submitted);
  
          if(surveyResponseBottomChartLst.length > 15){
            surveyResponseBottomChartLst = surveyResponseBottomChartLst.slice(0,15);
          }
  
          setSurveyBottomResponseChartList(surveyResponseBottomChartLst);

          if (surveyResponseList) {
            // Sort
            if (showNumbers) {
              setSurveyTopResponseList([...surveyResponseList].sort((a: any, b: any) => b.submitted - a.submitted));
            }
            else {
              setSurveyTopResponseList([...surveyResponseList].sort((a: any, b: any) => b.percentSubmitted - a.percentSubmitted));
            }
            // Sort
            if (showNumbers) {
              setSurveyBottomResponseList([...surveyBottomResponseList].sort((a: any, b: any) => b.submitted - a.submitted));
            }
            else {
              setSurveyBottomResponseList([...surveyBottomResponseList].sort((a: any, b: any) => b.percentNotSubmitted - a.percentNotSubmitted));
            }
          }
        }
      })
      .finally(() => {
        setSurveyResponseLoader(false);
      });
      
      getAllListData(
        `${EE_DASHBOARD.GetEmployeeSurveyResults}/${userDetail.ClientId}/${userDetail.Id}`,
      )
        .then((resp: any) => {
          if (resp?.data) {
            const employeeSumList = resp?.data?.map((entry: any, index: number) => {
              return {
                id: index,
                internalEmployeeId: entry.internalEmployeeId,
                fName: entry.fName,
                lName: entry.lName,
                notes: entry.notes,
                result: entry.result,
                timeStamp: entry.timeStamp,
                oldResult: entry.oldResult,
                oldResultTimeStamp: entry.oldResultTimeStamp,
                employeeId: entry.employeeId,
              }
            });

              setEmployeeSummaryList(employeeSumList);
            }
          }
         )
        .finally(() => {
          setEmployeeSummaryLoader(false);
        });
  };

  useEffect(() => {
    getChartApi();
    setViewMoreDetails({});
  }, [filterData]);

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderCustomizedLabel1 = ({ x, y, width, value }: any) => {
    if (value) {
      return (
        <g>
          <text
            x={x + width / 2}
            y={y + 8}
            fill='#fff'
            textAnchor='middle'
            fontSize={'8px'}
            dominantBaseline='middle'>
            {value}
          </text>
        </g>
      );
    }
    return <Typography />;
  };

  const handleDownload = (type: string, name: string, idx: number) => {
    setHideIcon(true);
    const refOption =
      idx === 0
        ? downloadRef0.current
        : idx === 1
          ? downloadRef1.current
          : idx === 2
            ? downloadRef2.current
            : idx === 3
              ? downloadRef3.current
              : idx === 4
                ? downloadRef4.current
                : idx === 5
                  ? downloadRef5.current
                  : '';
    if (type === 'img') {
      domtoimage.toBlob(refOption).then(function (blob) {
        fileDownload(
          blob,
          `${name}-${profileInfo?.clientName}-${moment().format('MM/DD/YYYY')}.png`,
        );
        setHideIcon(false);
      });
    } else {
      domtoimage.toBlob(refOption).then(function (blob) {
        const newImg = new Image();
        newImg.onload = function () {
          const height = newImg.height;
          const width = newImg.width;
          const LogoSrc: any = newImg.src;
          const doc = new jsPDF();
          doc.setFontSize(10);
          doc.text(`Downloaded Date: ${moment().format('MM-DD-YYYY')}`, 10, 10);
          doc.text(`Company Name: ${profileInfo?.clientName}`, 10, 18);
          doc.addImage(
            LogoSrc,
            'png',
            30,
            30,
            width / 4 > 150 ? 150 : width / 4,
            height / 4 > 287 ? 250 : height / 4,
          );
          const img = new Image();
          const src = Logo;
          img.src = src;
          doc.addImage(img, 'png', 100, 287, 40, 5);
          doc.setFontSize(10);
          doc.text('Powered by ', 80, 290);
          doc.save(`${name}-${profileInfo?.clientName}-${moment().format('MM/DD/YYYY')}.pdf`);
          setHideIcon(false);
        };
        const imgSrc = URL.createObjectURL(blob);
        newImg.src = imgSrc;
      });
    }
    handleClose();
  };

  useEffect(() => {
    changeShowNumbers();
  }, [showNumbers])

  // Update the lists based on user selection
  const changeShowNumbers = () => {

    if (showNumbers) {
      const needsAttnLst = [...needsAttentionList].sort((a: any, b: any) => b.needsAttentionValue - a.needsAttentionValue);
      setNeedsAttentionList(needsAttnLst);

      const inGoodStndLst = [...inGoodStandingList].sort((a: any, b: any) => b.inGoodStanding - a.inGoodStanding)
      setInGoodStandingList(inGoodStndLst);

      const onTheMvLst = [...onTheMoveList].sort((a: any, b: any) => b.onTheMoveValue - a.onTheMoveValue);
      setOnTheMoveList(onTheMvLst);

      const bottomRespList = [...surveyBottomResponseList].sort((a: any, b: any) => b.submitted - a.submitted);
      setSurveyBottomResponseList(bottomRespList);

      const topRespList = [...surveyTopResponseList].sort((a: any, b: any) => b.submitted - a.submitted);
      setSurveyTopResponseList(topRespList);
    }
    else {
      const needsAttnLst = [...needsAttentionList].sort((a: any, b: any) => b.needsAttentionPercentValue - a.needsAttentionPercentValue);
      setNeedsAttentionList(needsAttnLst);

      const inGoodStndLst = [...inGoodStandingList].sort((a: any, b: any) => b.inGoodStandingPercent - a.inGoodStandingPercent)
      setInGoodStandingList(inGoodStndLst);

      const onTheMvLst = [...onTheMoveList].sort((a: any, b: any) => b.onTheMovePercentValue - a.onTheMovePercentValue);
      setOnTheMoveList(onTheMvLst);

      const bottomRespList = [...surveyBottomResponseList].sort((a: any, b: any) => b.percentNotSubmitted - a.percentNotSubmitted);
      setSurveyBottomResponseList(bottomRespList);

      const topRespList = [...surveyTopResponseList].sort((a: any, b: any) => b.percentSubmitted - a.percentSubmitted);
      setSurveyTopResponseList(topRespList);
    }
  };

  const needsAttentionNumberColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 10,
      sortable: false,
      resizable: false,
      renderCell: (params) => <Typography
        component={'div'}
        sx={{ backgroundColor: `${COLORS[params.value]}`, width: '100%' }}
        className='legend-dot' />,
      headerClassName: 'super-app-theme-header',
        cellClassName: 'super-app-theme-cell'
    },
    {
      field: 'needsAttention',
      headerName: 'Title',
      minWidth: 200,
      maxWidth: 550,
      flex: 1,
      resizable: false,
      sortable: false,
      renderCell: (params: any) => 
        params.value?.data?.length ?
        (<TooltipMUI title={
          <Typography component={'div'} className='custom-tooltip'>
            <Typography component={'p'} className='last-valountry-black'>
              Employees Needing Attention - {params?.value?.title}
            </Typography>
            {
              params.value?.data?.map((p: any, index: number) =>
              (
                <Typography style={{cursor: 'pointer'}} onClick={() => handleToggle(p.employeeId)} component={'p'} className='valountry-blue' key={index}>
                  {p.name}
                </Typography>
              )
              )}
          </Typography>
        }>
          {params?.value?.title}
        </TooltipMUI>)
        : (params?.value?.title)      
    },
    { field: 'needsAttentionValue', headerName: 'Needs Attention', width: 150, resizable: false, sortable: false },
  ];

  const needsAttentionPercentColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 10,
      sortable: false,
      resizable: false,
      renderCell: (params) => <Typography
        component={'div'}
        sx={{ backgroundColor: `${COLORS[params.value]}`, width: '100%' }}
        className='legend-dot' />,
        headerClassName: 'super-app-theme-header',
        cellClassName: 'super-app-theme-cell'
    },
    {
      field: 'needsAttentionPercent',
      headerName: 'Title',
      minWidth: 200,
      maxWidth: 550,
      flex: 1,
      resizable: false,
      sortable: false,
      renderCell: (params: any) => 
        params.value?.data?.length ?
        (<TooltipMUI title={
          <Typography component={'div'} className='custom-tooltip'>
            <Typography component={'p'} className='last-valountry-black'>
              Employees Needing Attention - {params?.value?.title}
            </Typography>
            {
              params.value?.data?.map((p: any, index: number) =>
              (
                <Typography style={{cursor: 'pointer'}} onClick={() => handleToggle(p.employeeId)} component={'p'} className='valountry-blue' key={index}>
                  {p.name}
                </Typography>
              )
              )}
          </Typography>
        }>
          {params?.value?.title}
        </TooltipMUI>)
        : (params?.value?.title)   
    },
    {
      field: 'needsAttentionPercentValue',
      headerName: 'Needs Attention',
      width: 150,
      resizable: false,
      sortable: false,
      renderCell: (params) => <Typography>{params.value}%</Typography>,
    },
  ];

  const renderLegend = () => {
    return (
      <Typography className='legend-ul'>
        {/* <Typography className='leg-info'>Top 10 Categories</Typography> */}
        {needsAttentionChartList.map((entry: any, index: any) => (
          <Typography key={`item-${index}`}>
            <Typography component={'div'} className='d-flex-a flex-sb'>
              <li className='leg-info d-flex-a' style={{ width: '100%' }}>
                <Typography
                  component={'div'}
                  sx={{ backgroundColor: `${COLORS[index]}`, width: '100%' }}
                  className='legend-dot'
                />
                {entry.title}
              </li>
              <Typography className='leg-info'>{entry.needsAttentionValue}</Typography>
            </Typography>
          </Typography>
        ))}
      </Typography>
    );
  };

  const renderOnTheMoveLegend = () => {
    return (
      <Typography className='legend-ul'>
        {/* <Typography className='leg-info'>Top 10 Categories</Typography> */}
        {onTheMoveChartList.map((entry: any, index: any) => (
          <Typography key={`item-${index}`}>
            <Typography component={'div'} className='d-flex-a flex-sb'>
              <li className='leg-info d-flex-a' style={{ width: '100%' }}>
                <Typography
                  component={'div'}
                  sx={{ backgroundColor: `${COLORS[index]}`, width: '100%' }}
                  className='legend-dot'
                />
                {entry.title}
              </li>
              <Typography className='leg-info'>{entry.onTheMoveValue}</Typography>
            </Typography>
          </Typography>
        ))}
      </Typography>
    );
  };

  const renderInGoodStandingLegend = () => {
    return (
      <Typography className='legend-ul'>
        {/* <Typography className='leg-info'>Top 10 Categories</Typography> */}
        {inGoodStandingChartList.map((entry: any, index: any) => (
          <Typography key={`item-${index}`}>
            <Typography component={'div'} className='d-flex-a flex-sb'>
              <li className='leg-info d-flex-a' style={{ width: '100%' }}>
                <Typography
                  component={'div'}
                  sx={{ backgroundColor: `${COLORS[index]}`, width: '100%' }}
                  className='legend-dot'
                />
                {entry.title}
              </li>
              <Typography className='leg-info'>{entry.inGoodStanding}</Typography>
            </Typography>
          </Typography>
        ))}
      </Typography>
    );
  };

  const renderSurveyResponseTopLegend = () => {
    return (
      <Typography className='legend-ul'>
        {/* <Typography className='leg-info'>Top 10 Categories</Typography> */}
        {surveyTopResponseChartList.map((entry: any, index: any) => (
          <Typography key={`item-${index}`}>
            <Typography component={'div'} className='d-flex-a flex-sb'>
              <li className='leg-info d-flex-a' style={{ width: '100%' }}>
                <Typography
                  component={'div'}
                  sx={{ backgroundColor: `${COLORS[index]}`, width: '100%' }}
                  className='legend-dot'
                />
                {entry.text}
              </li>
              <Typography className='leg-info'>{entry.submitted}</Typography>
            </Typography>
          </Typography>
        ))}
      </Typography>
    );
  };

  const renderSurveyResponseBottomLegend = () => {
    return (
      <Typography className='legend-ul'>
        {/* <Typography className='leg-info'>Top 10 Categories</Typography> */}
        {surveyBottomResponseChartList.map((entry: any, index: any) => (
          <Typography key={`item-${index}`}>
            <Typography component={'div'} className='d-flex-a flex-sb'>
              <li className='leg-info d-flex-a' style={{ width: '100%' }}>
                <Typography
                  component={'div'}
                  sx={{ backgroundColor: `${COLORS[index]}`, width: '100%' }}
                  className='legend-dot'
                />
                {entry.text}
              </li>
              <Typography className='leg-info'>{entry.submitted}</Typography>
            </Typography>
          </Typography>
        ))}
      </Typography>
    );
  };


  const onTheMoveColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      resizable: false,
      width: 10,
      sortable: false,
      renderCell: (params) => <Typography
        component={'div'}
        sx={{ backgroundColor: `${COLORS[params.value]}`, width: '100%' }}
        className='legend-dot' />,
        headerClassName: 'super-app-theme-header',
        cellClassName: 'super-app-theme-cell'
    },
    {
      field: 'onTheMove',
      headerName: 'Title',
      minWidth: 200,
      maxWidth: 550,
      flex: 1,
      resizable: false,
      sortable: false,
      renderCell: (params: any) => 
        params.value?.data?.length ?
        (<TooltipMUI title={
          <Typography component={'div'} className='custom-tooltip'>
            <Typography component={'p'} className='last-valountry-black'>
              Employees On The Move - {params?.value?.title}
            </Typography>
            {
              params.value?.data?.map((p: any, index: number) =>
              (
                <Typography style={{cursor: 'pointer'}} onClick={() => handleToggle(p.employeeId)} component={'p'} className='valountry-blue' key={index}>
                {p.name}
              </Typography>
              )
              )}
          </Typography>
        }>
          {params?.value?.title}
        </TooltipMUI>)
        : (params?.value?.title)      
    },
    { field: 'onTheMoveValue', headerName: 'On The Move', width: 150, resizable: false, sortable: false },
  ];

  const onTheMovePercentColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      resizable: false,
      width: 10,
      sortable: false,
      renderCell: (params) => <Typography
        component={'div'}
        sx={{ backgroundColor: `${COLORS[params.value]}`, width: '100%' }}
        className='legend-dot' />,
        headerClassName: 'super-app-theme-header',
        cellClassName: 'super-app-theme-cell'
    },
    {
      field: 'onTheMovePercent',
      headerName: 'Title',
      minWidth: 200,
      maxWidth: 550,
      flex: 1,
      resizable: false,
      sortable: false,
      renderCell: (params: any) => 
        params.value?.data?.length ?
        (<TooltipMUI title={
          <Typography component={'div'} className='custom-tooltip'>
            <Typography component={'p'} className='last-valountry-black'>
              Employees On The Move - {params?.value?.title}
            </Typography>
            {
              params.value?.data?.map((p: any, index: number) =>
              (
                <Typography style={{cursor: 'pointer'}} onClick={() => handleToggle(p.employeeId)} component={'p'} className='valountry-blue' key={index}>
                  {p.name}
                </Typography>
              )
              )}
          </Typography>
        }>
          {params?.value?.title}
        </TooltipMUI>)
        : (params?.value?.title)      
    },
    {
      field: 'onTheMovePercentValue',
      headerName: 'On The Move',
      width: 150,
      resizable: false,
      sortable: false,
      renderCell: (params) => <Typography>{params.value}%</Typography>,
    },
  ];

  const NeedsAttentionTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          {/* <Typography component={'p'}>Top 10 Categories</Typography> */}
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.title}: {Math.round(payload[0].payload.needsAttentionValue)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const OnTheMoveTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          {/* <Typography component={'p'}>Top 10 Categories</Typography> */}
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.title}: {Math.round(payload[0].payload.onTheMoveValue)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const InGoodStandingTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          {/* <Typography component={'p'}>Top 10 Categories</Typography> */}
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.title}: {Math.round(payload[0].payload.inGoodStanding)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const SurveyResponseTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          {/* <Typography component={'p'}>Top 10 Categories</Typography> */}
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.text}: {Math.round(payload[0].payload.submitted)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const inGoodStandingNumberColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 10,
      resizable: false,
      sortable: false,
      renderCell: (params) => <Typography
        component={'div'}
        sx={{ backgroundColor: `${COLORS[params.value]}`, width: '100%' }}
        className='legend-dot' />,
        headerClassName: 'super-app-theme-header',
        cellClassName: 'super-app-theme-cell'
    },
    { field: 'title', headerName: 'Title', minWidth: 200, maxWidth: 550, flex: 1, resizable: false, sortable: false },
    { field: 'inGoodStanding', headerName: 'In Good Standing', width: 150, resizable: false, sortable: false },
  ];

  const inGoodStandingPercentColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 10,
      resizable: false,
      sortable: false,
      renderCell: (params) => <Typography
        component={'div'}
        sx={{ backgroundColor: `${COLORS[params.value]}`, width: '100%' }}
        className='legend-dot' />,
        headerClassName: 'super-app-theme-header',
        cellClassName: 'super-app-theme-cell'
    },
    { field: 'title', headerName: 'Title', minWidth: 200, maxWidth: 550, flex: 1, resizable: false, sortable: false },
    {
      field: 'inGoodStandingPercent',
      headerName: 'In Good Standing',
      width: 150,
      resizable: false,
      sortable: false,
      renderCell: (params) => <Typography>{params.value}%</Typography>,
    },
  ];

  const surveyResponseColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      resizable: false,
      width: 10,
      sortable: false,
      renderCell: (params) => <Typography
        component={'div'}
        sx={{ backgroundColor: `${COLORS[params.value]}`, width: '100%' }}
        className='legend-dot' />,
        headerClassName: 'super-app-theme-header',
        cellClassName: 'super-app-theme-cell'
    },
    { field: 'text', headerName: 'Title', minWidth: 200, maxWidth: 550, flex: 1, resizable: false, sortable: false },
    { field: 'submitted', headerName: 'Sutmitted', width: 150, resizable: false, sortable: false },
  ];

  const surveyResponsePercentColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      resizable: false,
      width: 10,
      sortable: false,
      renderCell: (params) => <Typography
        component={'div'}
        sx={{ backgroundColor: `${COLORS[params.value]}`, width: '100%' }}
        className='legend-dot' />,
        headerClassName: 'super-app-theme-header',
        cellClassName: 'super-app-theme-cell'
    },
    { field: 'text', headerName: 'Title', minWidth: 200, maxWidth: 550, flex: 1, resizable: false, sortable: false },
    {
      field: 'percentSubmitted',
      headerName: 'Percent Sutmitted',
      width: 150,
      resizable: false,
      sortable: false,
      renderCell: (params) => <Typography>{params.value}%</Typography>,
    },
  ];

  const surveyResponsePercentNotColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      resizable: false,
      width: 10,
      sortable: false,
      renderCell: (params) => <Typography
        component={'div'}
        sx={{ backgroundColor: `${COLORS[params.value]}`, width: '100%' }}
        className='legend-dot' />,
        headerClassName: 'super-app-theme-header',
        cellClassName: 'super-app-theme-cell'
    },
    { field: 'text', headerName: 'Title', minWidth: 200, maxWidth: 550, flex: 1, resizable: false, sortable: false },
    {
      field: 'percentNotSubmitted',
      headerName: 'Percent Submitted',
      width: 150,
      resizable: false,
      sortable: false,
      renderCell: (params) => <Typography>{params.value}%</Typography>,
    },
  ];

  const employeeSummaryListColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', resizable: false,  width: 10, sortable: false, headerClassName: 'super-app-theme-header', cellClassName: 'super-app-theme-cell'},    
   
    { field: 'lName', headerName: 'Last Name', flex: 1, resizable: false, sortable: true , headerClassName: 'super-app-theme-header', cellClassName: 'super-app-theme-cell',
      renderCell: (params) => <Typography style={{cursor: 'pointer'}} onClick={() => handleToggle(params.row.employeeId)}>{params.value}</Typography>
    },
    { field: 'fName', headerName: 'First Name', flex: 1, resizable: false, sortable: true , headerClassName: 'super-app-theme-header', cellClassName: 'super-app-theme-cell',
      renderCell: (params) => <Typography style={{cursor: 'pointer'}} onClick={() => handleToggle(params.row.employeeId)}>{params.value}</Typography>
    },
    { field: 'internalEmployeeId', headerName: 'Employee Number', flex: 1, resizable: false, sortable: true , headerClassName: 'super-app-theme-header', cellClassName: 'super-app-theme-cell',
      renderCell: (params) => <Typography style={{cursor: 'pointer'}} onClick={() => handleToggle(params.row.employeeId)}>{params.value}</Typography>
    },
    { field: 'result', headerName: 'Current Status', flex: 1, resizable: false, sortable: true , headerClassName: 'super-app-theme-header', cellClassName: 'super-app-theme-cell'},
    { field: 'timeStamp', headerName: 'Last Status Update', flex: 1, resizable: false, sortable: false , headerClassName: 'super-app-theme-header', cellClassName: 'super-app-theme-cell'},
    { field: 'oldResult', headerName: 'Previous Status', flex: 1, resizable: false, sortable: true , headerClassName: 'super-app-theme-header', cellClassName: 'super-app-theme-cell'},
    { field: 'oldResultTimeStamp', headerName: 'Previous Status Time', flex: 1, resizable: false, sortable: false , headerClassName: 'super-app-theme-header', cellClassName: 'super-app-theme-cell'}
  ];

  const handleToggle = async (employeeId: number) => {
     const url = `${EE_DASHBOARD.ViewMore}/${userDetail.ClientId}/${userDetail.Id}/${employeeId}`;     
       setLoader1(true);
       await getAllListData(url)
         .then((res) => {
           setDetails(res?.data);
         })
         .finally(() => {
           setLoader1(false);
         });
       const UrlM = `${EE_DASHBOARD.ViewMoreMagic}/${userDetail.ClientId}/${userDetail.Id}/${employeeId}`;
       await getAllListData(UrlM).then((res) => {
         setMoreDetails(res?.data);
       })
       .finally(() =>
       {
         setDrawer(true);
       });
 };


  return (
    
    <Grid container className='dashboard-box'>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={() => handleDownload('img', dwnldIndex.name, dwnldIndex.index)}>
          Image
        </MenuItem>
        <MenuItem onClick={() => handleDownload('pdf', dwnldIndex.name, dwnldIndex.index)}>
          PDF
        </MenuItem>
      </Menu>
      <Grid item md={12} sm={12} xs={12}>
      <PageLoader loading={loader1} />
        <Typography component={'div'} className='d-flex-ja flex-column'>
          <EmployeeDetailPopOut loader1={loader1} 
                          setLoader1={setLoader1} 
                          drawer={drawer} 
                          userDetail={userDetail} 
                          details={details} 
                          viewMoreDetails={viewMoreDetails} 
                          moreDetails={moreDetails} 
                          setDrawer={setDrawer} 
                          setDetails={setDetails} 
                          setMoreDetails={setMoreDetails} ></EmployeeDetailPopOut> 
        </Typography>
        <Typography component={'div'} className='ov-box' >
          <Grid container columnSpacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='pie-box' >
                <Typography className='c-title d-flex-a'>
                  Summary
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {
                            EyeToolTipTextOverAll.organizationalOverview
                              .engagementSummary
                          }
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {employeeSummaryLoader ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={employeeSummaryLoader} />
                  </Typography>
                ) : !employeeSummaryList?.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                <Typography component={'div'} sx={{ height: 260 }} className='datagrid-container'>
                      <DataGrid
                          columns={employeeSummaryListColumns}                         
                          className='datagrid'
                          rows={employeeSummaryList}
                          rowHeight={30}
                          columnHeaderHeight={30}
                          disableColumnSelector={true}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 5,
                              }
                            },                            
                          }}
                          columnVisibilityModel= {{
                            id: false
                          }}
                          sx={{
                            '&, [class^=MuiDataGrid]': {
                              border: 'none !important'
                            },
                            '.MuiDataGrid-columnSeparator': {
                              display: 'none',
                            },
                            '&.MuiDataGrid-root': {
                              border: 'none'
                            }
                          }}
                          pageSizeOptions={[5]}
                          autoPageSize={true}
                          autosizeOnMount={true} />
                      </Typography>
                )}
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='pie-box' ref={downloadRef0} minHeight={450}>
                {!hideIcon && engagementSummaryList?.length ? (
                  <Typography className='down-box d-flex-ja' sx={{ right: '6px !important' }}>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) => handleClick(e, 0, 'Needs Attention by ' + className)}>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a mb-15'>
                  Engagement Summary by Month
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {EyeToolTipTextOverAll.organizationalOverview
                            .engagementSummaryByMonths}
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {engagementSummaryList?.length ? (
                    <Typography
                      component={'div'}
                      className='d-flex-a view-option-tab employee-legent'
                      sx={{ width: '500px', position: 'absolute', right: '20px'}}>
                      <Typography
                        className={tab === 'All' ? 'tab-btn active' : 'tab-btn'}
                        onClick={() => handleTabChange('All')}>
                        All
                      </Typography>
                      <Typography
                        className={
                          tab === 'Needs Attention' ? 'tab-btn active d-flex-a' : 'tab-btn d-flex-a'
                        }
                        onClick={() => handleTabChange('Needs Attention')}>
                        <Typography className='opt-box na' />
                        <Typography className='opt-text'>Needs Attention</Typography>
                      </Typography>
                      <Typography
                        className={
                          tab === 'On the Move' ? 'tab-btn active d-flex-a' : 'tab-btn d-flex-a'
                        }
                        onClick={() => handleTabChange('On the Move')}>
                        <Typography className='opt-box rs' />
                        <Typography className='opt-text'> On the Move</Typography>
                      </Typography>
                      <Typography
                        className={
                          tab === 'Good Standing' ? 'tab-btn active d-flex-a' : 'tab-btn d-flex-a'
                        }
                        onClick={() => handleTabChange('Good Standing')}>
                        <Typography className='opt-box gs' />
                        <Typography className='opt-text'> Good Standing</Typography>
                      </Typography>
                      
                    </Typography>
                  ) : (
                    <Typography />
                  )}
                {engagementSummaryLoader ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={engagementSummaryLoader} />
                  </Typography>
                ) : !engagementSummaryList?.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <Typography component={'div'} className='turnoverChart d-flex'
                      sx={{ marginTop:'40px'}}>
                    <ResponsiveContainer width={'100%'} minHeight={350}>
                      <BarChart
                        className='hrmonth-chart'
                        data={engagementSummaryList}
                        stackOffset='sign'
                        margin={{
                          top: 20,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}>
                        <CartesianGrid stroke='white' />

                        <XAxis
                          label={{ value: 'Month/Year', dx: 10, dy: 15 }}
                          fontSize={12}
                          interval={0}
                          dataKey='monthVal'
                          height={50}
                          strokeDasharray='0 10'
                        />
                        <YAxis strokeDasharray='0 10' />

                        <Tooltip content={<CustomEngagementSummaryTooltip />} />
                        <Bar dataKey='needsAttention' stackId='NeedsAttention' fill={COLOR['NeedsAttention']} hide={hideNeedsAttention}>
                          {hideIcon && (
                            <LabelList dataKey='needsAttention' content={renderCustomizedLabel1} />
                          )}
                        </Bar>
                        <Bar dataKey='onTheMove' stackId='OnTheMove' fill={COLOR['OnTheMove']} hide={hideOnTheMove}>
                          {hideIcon && (
                            <LabelList dataKey='onTheMove' content={renderCustomizedLabel1} />
                          )}
                        </Bar>
                        <Bar dataKey='goodStanding' stackId='GoodStanding' fill={COLOR['GoodStanding']} hide={hideGoodStanding}>
                          {hideIcon && (
                            <LabelList dataKey='goodStanding' content={renderCustomizedLabel1} />
                          )}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography ref={downloadRef1} component={'div'} className='pie-box' style={{ minHeight: 450 }}>
                <Typography component={'div'} className='d-flex'>
                  {engagementSummaryLoader ? (
                    <Typography
                      component={'div'}
                      textAlign={'center'}
                      sx={{
                        paddingTop: '100px',
                      }}>
                      <ScreenLoader loading={engagementSummaryLoader} />
                    </Typography>
                  ) : !engagementSummaryList.length ? (
                    <Typography
                      component={'div'}
                      sx={{ height: '350px', display: 'flex' }}
                      alignItems={'center'}
                      justifyContent={'center'}>
                      No Records Found
                    </Typography>
                  ) : 
                  (
                    <Typography component={'div'} sx={{ minHeight: 400 }} className='datagrid-outside'>
                      {!hideIcon && engagementSummaryList.length ? (
                        <Typography className='down-box d-flex-ja'>
                          <IconButton
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(e: any) => handleClick(e, 1, 'Needs Attention by ' + className)}>
                            <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                          </IconButton>
                        </Typography>
                      ) : (
                        <Typography />
                      )}
                      <Typography className='c-title d-flex-a'>
                        Needs Attention by {className}
                        {!hideIcon && (
                          <TooltipMUI
                            title={
                              <Typography className='tooltip-text'>
                                {
                                  EyeToolTipTextOverAll.organizationalOverview
                                    .needsAttentionByClass + className
                                }
                              </Typography>
                            }>
                            <img className='info-im' src={InfoIcon} alt='info' />
                          </TooltipMUI>
                        )}
                      </Typography>
                      {!showCharts ?
                      (<Typography component={'div'} sx={{ height: 360 }} className='datagrid-container'>
                        <DataGrid
                          className='datagrid'
                          rows={needsAttentionList}
                          columns={showNumbers ? needsAttentionNumberColumns : needsAttentionPercentColumns}
                          rowHeight={30}
                          columnHeaderHeight={0}
                          disableColumnSelector={true}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              }
                            }
                          }}
                          sx={{
                            '&, [class^=MuiDataGrid]': {
                              border: 'none !important'
                            },
                            '.MuiDataGrid-columnSeparator': {
                              display: 'none',
                            },
                            '&.MuiDataGrid-root': {
                              border: 'none'
                            }
                          }}
                          pageSizeOptions={[10]}
                          autoPageSize={true}
                          autosizeOnMount={true} />
                      </Typography>   ) :
                      (<Typography component={'div'} className='d-flex'>
                        <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                          <ResponsiveContainer width={'100%'} height={'100%'}>
                            <PieChart >
                              <Tooltip content={<NeedsAttentionTooltip />} />
                              <Pie 
                                data={needsAttentionChartList}
                                cx='50%'
                                cy='50%'
                                innerRadius={60}
                                outerRadius={80}
                                fill='#8884d8'
                                paddingAngle={1}
                                dataKey='needsAttentionValue'>
                                {needsAttentionChartList.map((entry: any, index: number) => (
                                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}                                
                              </Pie>
                            </PieChart>
                          </ResponsiveContainer>
                        </Typography>
                        <Typography component={'div'} className='flex-1'>
                          {renderLegend()}
                        </Typography>
                      </Typography>)
                    }               
                    </Typography> 
                  )}
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography ref={downloadRef2} component={'div'} className='pie-box' style={{ minHeight: 450 }}>
                <Typography component={'div'} className='d-flex'>
                  {engagementSummaryLoader ? (
                    <Typography
                      component={'div'}
                      textAlign={'center'}
                      sx={{
                        paddingTop: '100px',
                      }}>
                      <ScreenLoader loading={engagementSummaryLoader} />
                    </Typography>
                  ) : !engagementSummaryList.length ? (
                    <Typography
                      component={'div'}
                      sx={{ height: '350px', display: 'flex' }}
                      alignItems={'center'}
                      justifyContent={'center'}>
                      No Records Found
                    </Typography>
                  ) : (
                    <Typography component={'div'} sx={{ minHeight: 400 }} className='datagrid-outside'>
                      {!hideIcon && engagementSummaryList.length ? (
                        <Typography className='down-box d-flex-ja'>
                          <IconButton
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(e: any) => handleClick(e, 2, 'On the Move by ' + className)}>
                            <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                          </IconButton>
                        </Typography>
                      ) : (
                        <Typography />
                      )}
                      <Typography className='c-title d-flex-a'>
                        On The Move by {className}
                        {!hideIcon && (
                          <TooltipMUI
                            title={
                              <Typography className='tooltip-text'>
                                {
                                  EyeToolTipTextOverAll.organizationalOverview
                                    .onTheMoveByClass + className
                                }
                              </Typography>
                            }>
                            <img className='info-im' src={InfoIcon} alt='info' />
                          </TooltipMUI>
                        )}
                      </Typography>
                      {!showCharts ?
                      (
                      <Typography component={'div'} sx={{ height: 360 }} className='datagrid-container'>
                        <DataGrid
                          className='datagrid'
                          rows={onTheMoveList}
                          columns={showNumbers ? onTheMoveColumns : onTheMovePercentColumns}
                          rowHeight={30}
                          columnHeaderHeight={0}
                          disableColumnSelector={true}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              }
                            }
                          }}
                          sx={{
                            '&, [class^=MuiDataGrid]': {
                              border: 'none !important'
                            },
                            '.MuiDataGrid-columnSeparator': {
                              display: 'none',
                            },
                            '&.MuiDataGrid-root': {
                              border: 'none',
                            },
                          }}
                          pageSizeOptions={[10]}
                          autoPageSize={true}
                          autosizeOnMount={true} />
                      </Typography>   ) :
                      (<Typography component={'div'} className='d-flex'>
                        <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                          <ResponsiveContainer width={'100%'} height={'100%'}>
                            <PieChart >
                              <Tooltip content={<OnTheMoveTooltip />} />
                              <Pie 
                                data={onTheMoveChartList}
                                cx='50%'
                                cy='50%'
                                innerRadius={60}
                                outerRadius={80}
                                fill='#8884d8'
                                paddingAngle={1}
                                dataKey='onTheMoveValue'>
                                {onTheMoveChartList.map((entry: any, index: number) => (
                                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}                                
                              </Pie>
                            </PieChart>
                          </ResponsiveContainer>
                        </Typography>
                        <Typography component={'div'} className='flex-1'>
                          {renderOnTheMoveLegend()}
                        </Typography>
                      </Typography>)
                    }     
                    </Typography>
                  )}
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography ref={downloadRef3} component={'div'} className='pie-box' style={{ minHeight: 450 }}>
                <Typography component={'div'} className='d-flex'>
                  {engagementSummaryLoader ? (
                    <Typography
                      component={'div'}
                      textAlign={'center'}
                      sx={{
                        paddingTop: '100px',
                      }}>
                      <ScreenLoader loading={engagementSummaryLoader} />
                    </Typography>
                  ) : !engagementSummaryList.length ? (
                    <Typography
                      component={'div'}
                      sx={{ height: '350px', display: 'flex' }}
                      alignItems={'center'}
                      justifyContent={'center'}>
                      No Records Found
                    </Typography>
                  ) : (
                    <Typography component={'div'} sx={{ minHeight: 400 }} className='datagrid-outside'>
                      {!hideIcon && engagementSummaryList.length ? (
                        <Typography className='down-box d-flex-ja'>
                          <IconButton
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(e: any) => handleClick(e, 3, 'In Good Standing by ' + className)}>
                            <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                          </IconButton>
                        </Typography>
                      ) : (
                        <Typography />
                      )}
                      <Typography className='c-title d-flex-a'>
                        In Good Standing by {className}
                        {!hideIcon && (
                          <TooltipMUI
                            title={
                              <Typography className='tooltip-text'>
                                {
                                  EyeToolTipTextOverAll.organizationalOverview
                                    .inGoodStandingByClass + className
                                }
                              </Typography>
                            }>
                            <img className='info-im' src={InfoIcon} alt='info' />
                          </TooltipMUI>
                        )}
                      </Typography>
                      {!showCharts ?
                      (
                      <Typography component={'div'} sx={{ height: 360 }} className='datagrid-container'>
                        <DataGrid
                          className='datagrid'
                          rows={inGoodStandingList}
                          columns={showNumbers ? inGoodStandingNumberColumns : inGoodStandingPercentColumns}
                          disableColumnSelector={true}
                          rowHeight={30}
                          columnHeaderHeight={0}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              }
                            }
                          }}
                          sx={{
                            '&, [class^=MuiDataGrid]': {
                              border: 'none !important'
                            },
                            '& .MuiDataGrid-columnSeparator': {
                              display: 'none',
                            },
                            '& .MuiDataGrid-root': {
                              border: 'none !important',
                            },
                            '& .MuiDataGrid-footerContainer': {
                              border: 'none !important'
                            },
                          }}
                          pageSizeOptions={[10]}
                          autoPageSize={true}
                          autosizeOnMount={true} />
                      </Typography>  ) :
                      (<Typography component={'div'} className='d-flex'>
                        <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                          <ResponsiveContainer width={'100%'} height={'100%'}>
                            <PieChart >
                              <Tooltip content={<InGoodStandingTooltip />} />
                              <Pie 
                                data={inGoodStandingChartList}
                                cx='50%'
                                cy='50%'
                                innerRadius={60}
                                outerRadius={80}
                                fill='#8884d8'
                                paddingAngle={1}
                                dataKey='inGoodStanding'>
                                {inGoodStandingChartList.map((entry: any, index: number) => (
                                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}                                
                              </Pie>
                            </PieChart>
                          </ResponsiveContainer>
                        </Typography>
                        <Typography component={'div'} className='flex-1'>
                          {renderInGoodStandingLegend()}
                        </Typography>
                      </Typography>)
                    }     
                    </Typography>
                  )}
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography ref={downloadRef4} component={'div'} className='pie-box' style={{ minHeight: 450 }}>
                <Typography component={'div'} className='d-flex'>
                  {surveyResponseLoader ? (
                    <Typography
                      component={'div'}
                      textAlign={'center'}
                      sx={{
                        paddingTop: '100px',
                      }}>
                      <ScreenLoader loading={surveyResponseLoader} />
                    </Typography>
                  ) : !surveyTopResponseList.length ? (
                    <Typography
                      component={'div'}
                      sx={{ height: '350px', display: 'flex' }}
                      alignItems={'center'}
                      justifyContent={'center'}>
                      No Records Found
                    </Typography>
                  ) : (
                    <Typography component={'div'} sx={{ minHeight: 400 }} className='datagrid-outside'>
                      {!hideIcon && surveyTopResponseList.length ? (
                        <Typography className='down-box d-flex-ja'>
                          <IconButton
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(e: any) => handleClick(e, 4, 'Latest Survey Responses By ' + className)}>
                            <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                          </IconButton>
                        </Typography>
                      ) : (
                        <Typography />
                      )}
                      <Typography className='c-title d-flex-a'>
                        Latest Survey Responses by {className}
                        {!hideIcon && (
                          <TooltipMUI
                            title={
                              <Typography className='tooltip-text'>
                                {
                                  EyeToolTipTextOverAll.organizationalOverview
                                    .surveyTopResponsesByClass + className
                                }
                              </Typography>
                            }>
                            <img className='info-im' src={InfoIcon} alt='info' />
                          </TooltipMUI>
                        )}
                      </Typography>
                      {!showCharts ?
                      (<Typography component={'div'} sx={{ height: 360 }} className='datagrid-container'>
                        <DataGrid
                          className='datagrid'
                          rows={surveyTopResponseList}
                          columns={showNumbers ? surveyResponseColumns : surveyResponsePercentColumns}
                          disableColumnSelector={true}
                          rowHeight={30}
                          columnHeaderHeight={0}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              }
                            }
                          }}
                          sx={{
                            '&, [class^=MuiDataGrid]': {
                              border: 'none !important'
                            },
                            '& .MuiDataGrid-columnSeparator': {
                              display: 'none',
                            },
                            '& .MuiDataGrid-root': {
                              border: 'none !important',
                            },
                            '& .MuiDataGrid-footerContainer': {
                              border: 'none !important'
                            },
                          }}
                          pageSizeOptions={[10]}
                          autoPageSize={true}
                          autosizeOnMount={true} />
                      </Typography> ) :
                      (<Typography component={'div'} className='d-flex'>
                        <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                          <ResponsiveContainer width={'100%'} height={'100%'}>
                            <PieChart >
                              <Tooltip content={<SurveyResponseTooltip />} />
                              <Pie 
                                data={surveyTopResponseChartList}
                                cx='50%'
                                cy='50%'
                                innerRadius={60}
                                outerRadius={80}
                                fill='#8884d8'
                                paddingAngle={1}
                                dataKey='submitted'>
                                {surveyTopResponseChartList.map((entry: any, index: number) => (
                                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}                                
                              </Pie>
                            </PieChart>
                          </ResponsiveContainer>
                        </Typography>
                        <Typography component={'div'} className='flex-1'>
                          {renderSurveyResponseTopLegend()}
                        </Typography>
                      </Typography>)
                    }   
                    </Typography>
                  )}
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography ref={downloadRef5} component={'div'} className='pie-box' style={{ minHeight: 450 }}>
                <Typography component={'div'} className='d-flex'>
                  {surveyResponseLoader ? (
                    <Typography
                      component={'div'}
                      textAlign={'center'}
                      sx={{
                        paddingTop: '100px',
                      }}>
                      <ScreenLoader loading={surveyResponseLoader} />
                    </Typography>
                  ) : !surveyBottomResponseList.length ? (
                    <Typography
                      component={'div'}
                      sx={{ height: '350px', display: 'flex' }}
                      alignItems={'center'}
                      justifyContent={'center'}>
                      No Records Found
                    </Typography>
                  ) : (
                    <Typography component={'div'} sx={{ minHeight: 400 }} className='datagrid-outside'>
                      {!hideIcon && surveyBottomResponseList.length ? (
                        <Typography className='down-box d-flex-ja'>
                          <IconButton
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(e: any) => handleClick(e, 5, 'Latest Survey Non-Responses By ' + className)}>
                            <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                          </IconButton>
                        </Typography>
                      ) : (
                        <Typography />
                      )}
                      <Typography className='c-title d-flex-a'>
                        Latest Survey Non-Responses by {className}
                        {!hideIcon && (
                          <TooltipMUI
                            title={
                              <Typography className='tooltip-text'>
                                {
                                  EyeToolTipTextOverAll.organizationalOverview
                                    .surveyBottomResponsesByClass + className
                                }
                              </Typography>
                            }>
                            <img className='info-im' src={InfoIcon} alt='info' />
                          </TooltipMUI>
                        )}
                      </Typography>
                      {!showCharts ?
                      (<Typography component={'div'} sx={{ height: 360 }} className='datagrid-container'>
                        <DataGrid
                          className='datagrid'
                          rows={surveyBottomResponseList}
                          columns={showNumbers ? surveyResponseColumns : surveyResponsePercentNotColumns}
                          disableColumnSelector={true}
                          rowHeight={30}
                          columnHeaderHeight={0}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              }
                            }
                          }}
                          sx={{
                            '&, [class^=MuiDataGrid]': {
                              border: 'none !important'
                            },
                            '& .MuiDataGrid-columnSeparator': {
                              display: 'none',
                            },
                            '& .MuiDataGrid-root': {
                              border: 'none !important',
                            },
                            '& .MuiDataGrid-footerContainer': {
                              border: 'none !important'
                            },
                          }}
                          pageSizeOptions={[10]}
                          autoPageSize={true}
                          autosizeOnMount={true} />
                      </Typography>) :
                      (<Typography component={'div'} className='d-flex'>
                        <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                          <ResponsiveContainer width={'100%'} height={'100%'}>
                            <PieChart >
                              <Tooltip content={<SurveyResponseTooltip />} />
                              <Pie 
                                data={surveyBottomResponseChartList}
                                cx='50%'
                                cy='50%'
                                innerRadius={60}
                                outerRadius={80}
                                fill='#8884d8'
                                paddingAngle={1}
                                dataKey='submitted'>
                                {surveyBottomResponseChartList.map((entry: any, index: number) => (
                                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}                                
                              </Pie>
                            </PieChart>
                          </ResponsiveContainer>
                        </Typography>
                        <Typography component={'div'} className='flex-1'>
                          {renderSurveyResponseBottomLegend()}
                        </Typography>
                      </Typography>)
                    }   
                    </Typography>
                  )}                   
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmployeeEngagementSummary;