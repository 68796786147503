import  React from 'react';

interface IFilterDataContextType {
    filterData: any,
    setFilterData: (data:any) => void;
    showNumbers: boolean,
    setShowNumbers: (data:boolean) => void;
    showCharts: boolean,
    setShowCharts: (data:boolean) => void;
}

const defaultSearchData = {
    filterData: { },
    setFilterData: function(data: any){ console.log(data)},
    showNumbers: false,
    setShowNumbers: function(data:boolean){ console.log(data)},
    showCharts: false,
    setShowCharts: function(data:boolean){console.log(data)}
}

export const FilterDataContext = React.createContext<IFilterDataContextType>(defaultSearchData);

const FilterDataContextProvider: React.FC<{children: React.ReactNode}> = ({ children }) =>{

    const [filterData, setFilterDataVal] = React.useState( {
        pre :{},
        codeList: [],
        jobList: [],
        companyList: [],
        directSuperVisorList: []
    });

    const setFilterData = (data:any) => {
        console.log('in set data')
        setFilterDataVal(data);
    }

    const [showNumbers, setShowNumbers] = React.useState(true);
    const [showCharts, setShowCharts] = React.useState(false);

    const data = {  filterData, 
                    setFilterData, 
                    showNumbers, 
                    setShowNumbers,
                    showCharts,
                    setShowCharts 
                };

    return(
        <FilterDataContext.Provider value={data} >{ children }</FilterDataContext.Provider>
    );
};

export default FilterDataContextProvider;