import  React from 'react';

interface IEmployeeSearchContextType {
    selectedEmployeeId: number;
    setSelectedEmployeeId: (employeeId:number) => void;
}

const defaultSearchData = {
    selectedEmployeeId: 0,
    setSelectedEmployeeId: function(employeeId: number){ console.log(employeeId)}
}

const EmployeeSearchContext = React.createContext<IEmployeeSearchContextType>(defaultSearchData);

export default EmployeeSearchContext;